import { ApolloQueryResult, FetchResult, gql, useQuery } from "@apollo/client";
import { EightteenMpTwoTone } from "@mui/icons-material";
import { Button, TextareaAutosize } from "@mui/material";
import { EEXIST } from "constants";
import Papa from "papaparse";
import { listenerCount } from "process";
import React, { useCallback, useContext, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Api from "../../Api";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import int from "../../int";
import IntUtils from "../../IntUtils";
import BaseModel from "../../models/BaseModel";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateOf from "../../states/StateOf";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Utils from "../../Utils";
import Center from "../Center";
import IconFa from "../IconFa";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import CustomerManagementPage from "./CustomerManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import FaIconsListPage from "./FaIconsListPage";
import UserManagementPage from "./UserManagementPage";

type GraphQLFetchResult = FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
type CheckResult = {
    loading: boolean,
    data?: {
        result: boolean,
        reason?: string
    }
}

type CategoryProto = {
    name: string
    iconName: string
}
type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const CSVCt = createStateContext<CategoryProto[] | null>()
const CSVErrorCt = createStateContext<StateOf<Papa.ParseError[] | string | null>>()

const UpdatedStateContext = createStateContext<UpdateState>()

const parsedToEquipmentProtos = (parsed: string[][]): CategoryProto[] => {
    var list: CategoryProto[] = []
    for (const e of parsed) {
        const p = {
            name: e[0],
            iconName: e[1]
        } as CategoryProto
        list.push(p)
    }

    return list

}

const _FormImpl: React.FC<{}> = (props) => {
    // const loginContext = useContext(LoginStateContext);
    // const loginState = loginContext.state.instance;
    // const user = loginState.user;

    // const placesCt = useContext(PlacesCt)
    // const categoriesCt = useContext(CategoriesCt)
    const headerList = "名称,アイコン"
    const csvCt = useContext(CSVCt)
    const csvErrorCt = useContext(CSVErrorCt)
    const csvData: CategoryProto[] | null = csvCt.state

    const updatedContext = useContext(UpdatedStateContext)
    const nav = useNavigate()

    var temporaryCSV: string;

    return (
        <React.Fragment>
            <TextareaAutosize
                minRows={3}
                placeholder="ここにCSVファイルの中身を貼り付けてください"
                onChange={(e) => {
                    temporaryCSV = e.target.value
                }}
                style={{ width: 300, height: 200 }}
            />
            <div>
                ※ CSVの形式は、{headerList} です。
            </div>
            <div>
                ※ <Link to={Router.getRouteOfPage(FaIconsListPage)} >アイコン一覧</Link>
            </div>
            <SizedBox height={5} />
            <div onClick={() => {
                const parsed = Papa.parse<string[]>(temporaryCSV)
                if (parsed.errors.length > 0) {
                    csvCt.setState(null)
                    csvErrorCt.setState(new StateOf(parsed.errors))
                }

                const protos = parsedToEquipmentProtos(parsed.data)

                csvErrorCt.setState(new StateOf(null))
                csvCt.setState(protos)

                // console.log(parsed)
            }}>
                <Button variant="contained">
                    確認
                </Button>
            </div>

            {
                (csvErrorCt.state.instance !== null)
                    ? (
                        <React.Fragment>
                            <div>
                                CSVにエラーがあります
                            </div>

                            <div>
                                {JSON.stringify(csvErrorCt.state.instance)}
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length > 0)
                    ? (
                        <React.Fragment>
                            <div>
                                <table>
                                    <tr>
                                        {
                                            headerList.split(",").map((e) =>
                                                <th>{e}</th>
                                            )
                                        }
                                        {/* <th>(確認)</th> */}
                                    </tr>
                                    {
                                        // categoryName: string,
                                        // placeName: string,
                                        // numbering: int | undefined,
                                        // note: string,
                                        // accessoriesInfo: string,
                                        // bihinSticker: string
                                        csvData.map((e) =>
                                            <tr>
                                                <td>{e.name}</td>
                                                <td><IconFa icon={e.iconName} /> {e.iconName}</td>
                                                {/* <td> */}
                                                {/* <UUIDCheckResultWidget uuid={e.uuid} /> */}
                                                {/* <NumberingCheckResultWidget uuid={e.uuid} name={e.name} category={e.categoryId} numbering={e.numbering} /> */}
                                                {/* </td> */}
                                            </tr>
                                        )
                                    }
                                </table>
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length === 0)
                    ? <div>有効な行はありませんでした。CSVが正しいかを確認してください。</div>
                    : <React.Fragment />
            }
            <SizedBox height={5} />
            {
                (csvData !== null && csvData.length > 0)
                    ?
                    <div onClick={async () => {
                        // if (uuidCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        // if (numberingCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        const queryStr = `
                          mutation($name: String!, $iconName: String) {
                            createEquipmentPlace(input: {name: $name, iconName: $iconName}) {
                              equipmentPlace {
                                id
                                name
                              }
                              clientMutationId
                            }
                          }
                        `

                        const query = gql(queryStr)

                        var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
                        var vars = {}

                        var allOK = true;

                        var n = 0;

                        for (const e of csvCt.state!) {
                            ++n;

                            try {
                                result = await Api.graphQLClient?.mutate({
                                    mutation: query,
                                    variables: vars = {
                                        'name': e.name,
                                        'iconName': e.iconName === "" ? undefined : e.iconName
                                        // 'uuid': e.uuid,
                                        // 'numbering': e.numbering,
                                        // 'name': e.name,
                                        // 'note': (e.note === "")
                                        //     ? undefined
                                        //     : e.note,
                                        // 'accessoriesInfo': (e.accessoriesInfo === "")
                                        //     ? undefined
                                        //     : e.accessoriesInfo,
                                        // 'bihinSticker': (e.bihinSticker === "")
                                        //     ? undefined
                                        //     : e.bihinSticker,
                                        // 'category': (e.categoryId === "")
                                        //     ? undefined
                                        //     : e.categoryId,
                                        // 'place': (e.placeId === "")
                                        //     ? undefined
                                        //     : e.placeId,
                                        // 'aliveStatus': EquipmentAliveStatus.Alive.toString()
                                    }
                                })
                            } catch (e) {
                                Api.handleGraphQLException(e, nav)
                            }

                            console.log(queryStr)
                            console.log(JSON.stringify(vars))

                            if (result?.data != undefined &&
                                result?.data?.["createEquipmentPlace"] != undefined &&
                                result?.data?.["createEquipmentPlace"]?.["equipmentPlace"] !=
                                undefined) {

                                // OK. go next

                            } else {
                                updatedContext.setState(UpdateState.Failed)
                                console.error(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                alert(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                console.error(result);

                                allOK = false
                                break
                            }
                        }

                        if (allOK) {
                            updatedContext.setState(UpdateState.Succeeeded)

                            csvCt.setState(null);
                            csvErrorCt.setState(new StateOf(null));
                            Api.graphQLResetCache()

                            Router.popAndPushNamed(Router.getRouteOfPage(EquipmentPlaceManagementPage)!, nav)
                        }
                    }}>
                        <Button variant="contained">
                            インポートを実行
                        </Button>
                    </div>
                    : <Button disabled variant="contained">
                        インポートを実行
                    </Button>
            }
        </React.Fragment>
    );
}

const _FormWrap: React.FC = (props) => {
    return (
        <React.Fragment>
            <_FormImpl />
        </React.Fragment>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()


    if (true) {
        return (
            <StateProvider context={CSVCt} defaultValue={null}>
                <StateProvider context={CSVErrorCt} defaultValue={new StateOf(null)}>
                    <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                        <_FormWrap />
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>エラーが発生しました</Center>
}

const CSVImportCategoriesPage: React.FC = (props) => {
    return (
        <_Form />
    )
}

export default CSVImportCategoriesPage;