

import React from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import IconFaData from "../IconFaData";
import IconUtils from "../IconUtils";

const EmptyIcon: React.FC = (props) => {
    return (
        <div style={{ width: 24, height: 24 }}></div>
    )
}

const IconFa: React.FC<{ icon?: string }> = (props) => {
    return (
        <div className="FaIconContainer">
            {
                IconUtils.IsIconFaNameValid(props.icon ?? "")
                    ? <IconPickerItem icon={IconUtils.getIconFaFromName(props.icon!)!} size={24} color="#000" />
                    : <EmptyIcon />
            }
        </div>
    );
}

export default IconFa;