import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import IconFa from "../IconFa";
import DeleteButton from "../DeleteButton";
import RentalStatus from "../../models/RentalStatus";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalStatusWidget from "../RentalStatusWidget";
import DateFormatter from "../../DateFormatter";
import Utils from "../../Utils";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import RentalListPage from "./RentalListPage";
import RentalDetailPageEquipmentsView from "../RentalDetailPageEquipmentsView";
import StateOf from "../../states/StateOf";
import Equipment from "../../models/Equipment";
import RentalReturnLog from "../../models/RentalReturnLog";
import CustomerManagementPage from "./CustomerManagementPage";

type StateOfArrayEquipment = StateOf<Array<Equipment>>

const UpdatedStateContext = createStateContext<UpdateState>();
const EquipmentListStateContext = createStateContext<StateOfArrayEquipment>();

const RentalStatusStateContext = createStateContext<string>();

const TitleStateContext = createStateContext<string>();
const StartDateStateContext = createStateContext<string>();
const EndDateStateContext = createStateContext<string>();
const CustomerGroupStateContext = createStateContext<string>();
const CustomerPersonStateContext = createStateContext<string>();
const UserStateContext = createStateContext<string>();

const _CustemorPersonDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const customerPersonContext = useContext(CustomerPersonStateContext)

    return (
        <SelectValidator
            className="Required"
            value={customerPersonContext.state === null ? "" : customerPersonContext.state}
            label={Const.rentalCustomerPersonLabelText}
            name="customer-group"
            onChange={(e: any) => { customerPersonContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={""}>{""}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.familyName} {e.firstName}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CustemorPersonDropDown: React.FC = (props) => {
    const nav = useNavigate()

    var customerGroupId = useContext(CustomerGroupStateContext).state
    customerGroupId = ((customerGroupId === "" || customerGroupId === "_") ? "" : customerGroupId)

    const query = gql`
    query($customerGroup: String){
        customerPeople(customerGroup: $customerGroup) {
            edges {
            node {
              id
              _id
              firstName
              familyName
              email
              customerGroup {
                id
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {
            'customerGroup': customerGroupId
        }
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        if (customerGroupId === "") {
            const items: Array<any> = (result.data?.["customerPeople"]?.["edges"] ?? [])
                .filter((e: any) => e.node.customerGroup === null)
                .map((e: any) => e["node"]);

            return <_CustemorPersonDropDownImpl items={items} />
        } else {
            const items: Array<any> = (result.data?.["customerPeople"]?.["edges"] ?? [])
                .map((e: any) => e["node"]);

            return <_CustemorPersonDropDownImpl items={items} />
        }
    } else {
        return <_CustemorPersonDropDownImpl items={[]} />
    }
}

const _UserDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const userContext = useContext(UserStateContext)

    return (
        <SelectValidator
            className="Required"
            value={userContext.state === null ? "" : userContext.state}
            label={"TEMDEC側 担当者"}
            name="customer-group"
            onChange={(e: any) => { userContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={""}>{""}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.familyName} {e.firstName}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _UserDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        users {
          edges {
            node {
              id
              _id
              firstName
              familyName
              email
            }
          }
        }
      } 
    `
    const result = useQuery(query, {
        variables: {}
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = result.data!["users"]["edges"]
            .map((e: any) => e["node"]);

        return <_UserDropDownImpl items={items} />
    } else {
        return <_UserDropDownImpl items={[]} />
    }
}

const _CustomerGroupDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const groupStateContext = useContext(CustomerGroupStateContext)

    return (
        <SelectValidator
            className="Required"
            value={groupStateContext.state === null ? "_" : groupStateContext.state}
            label={Const.rentalCustomerGroupLabelText}
            name="customer-group"
            onChange={(e: any) => { groupStateContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={"_"}>{Const.customerPersonNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CustomerGroupDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        customerGroups{
          edges {
            node {
              id
              _id
              name
              persons {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {}
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = result.data!["customerGroups"]["edges"]
            .map((e: any) => e["node"]);

        return <_CustomerGroupDropDownImpl items={items} />
    } else {
        return <_CustomerGroupDropDownImpl items={[]} />
    }
}

const _StatusDropDownImpl: React.FC<{ items: Array<RentalStatus> }> = (props) => {

    const rentalStatusStateContext = useContext(RentalStatusStateContext)

    return (
        <SelectValidator
            className="Required"
            value={rentalStatusStateContext.state}
            label="状態"
            name="rental_status"
            onChange={(e: any) => { rentalStatusStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            {
                props.items
                    .filter((e) => e !== RentalStatus.Completed)
                    .map((e) => {
                        const value = RentalStatusExtension.toShortString(e)
                        return (
                            <MenuItem className="MenuItemWithIcon" key={value} value={value}>
                                <RentalStatusWidget status={e} />
                            </MenuItem>
                        )
                    })
            }
        </SelectValidator>
    )
}

const _StatusDropDown: React.FC = (props) => {
    const nav = useNavigate()

    const items = [RentalStatus.Ongoing, RentalStatus.Reserved, RentalStatus.Completed]
    return <_StatusDropDownImpl items={items} />
}

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const titleContext = useContext(TitleStateContext);
    const startDateContext = useContext(StartDateStateContext);
    const endDateContext = useContext(EndDateStateContext);
    const customerGroupContext = useContext(CustomerGroupStateContext);
    const customerPersonContext = useContext(CustomerPersonStateContext);
    const userContext = useContext(UserStateContext);

    const updatedContext = useContext(UpdatedStateContext);
    const rentalStatusContext = useContext(RentalStatusStateContext);
    const equipmentListStateContext = useContext(EquipmentListStateContext)

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                const title = titleContext.state;
                const startDate = startDateContext.state;
                const endDate = endDateContext.state;
                const customerGroup = customerGroupContext.state;
                const customerPerson = customerPersonContext.state;
                const user = userContext.state;
                const rentalStatus = rentalStatusContext.state;
                const equipmentItems = equipmentListStateContext.state.instance;

                const queryStr = `
                  mutation($title: String!, $status: String!, $customerPerson: String!, $userInCharge: String!, $startDate: String!, $endDate: String, $equipmentItems: [String]) {
                    createRental(input: {title: $title, status: $status, customerPerson: $customerPerson, userInCharge: $userInCharge, startDate: $startDate, endDate: $endDate, equipmentItems: $equipmentItems}) {
                      rental {
                        id
                        title
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'title': title !== "" ? title : undefined,
                            'startDate': startDate !== "" ? startDate : undefined,
                            'endDate': endDate !== "" ? endDate : null,
                            'customerPerson': customerPerson !== "" ? customerPerson : undefined,
                            'userInCharge': user !== "" ? user : undefined,
                            'equipmentItems': equipmentItems
                                .map((e) => e.id),
                            'status': rentalStatus
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["createRental"] != undefined &&
                    result?.data?.["createRental"]?.["rental"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    // await refetch();
                    titleContext.setState("");
                    startDateContext.setState("");
                    endDateContext.setState("");
                    customerGroupContext.setState("");
                    customerPersonContext.setState("");
                    userContext.setState("");
                    rentalStatusContext.setState(RentalStatus.Reserved.toString());
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(RentalListPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >

            <RentalDetailPageEquipmentsView
                equipmentItemsContext={equipmentListStateContext}
                rentalReturnLogs={[]}
                rentalStatus={undefined}
            />

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={Const.rentalTitleLabelText}
                onChange={(e: any) => titleContext.setState(e.target.value)}
                name="title"
                value={titleContext.state}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <_StatusDropDown />

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className="RequiredDatePickerContainer">
                        <DatePicker
                            className="Required"
                            label={Const.rentalStartDateLabelText}
                            value={startDateContext.state}
                            onChange={(v: any) => {
                                if (v !== null) {
                                    const s = Utils.getFormatDateFromString(v)
                                    startDateContext.setState(s)
                                }
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <DatePicker
                        className="Required"
                        label={Const.rentalEndDateLabelText}
                        value={endDateContext.state !== "" ? endDateContext.state : null}
                        onChange={(v: any) => {
                            if (v !== null) {
                                const s = Utils.getFormatDateFromString(v)
                                endDateContext.setState(s)
                            } else {
                                endDateContext.setState("")
                            }
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <_CustomerGroupDropDown />
                </Grid>
                <Grid item xs={6}>
                    <_CustemorPersonDropDown />
                </Grid>
            </Grid>

            <SizedBox height={3} />
            <Link to={Router.getRouteOfPage(CustomerManagementPage)!}>
                {"貸出先の管理"}
            </Link>

            <SizedBox height={10} />

            <_UserDropDown />

            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.addNewButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    // return Center(child: Flexible(child: Text("$result")));
    // const data = result.data;
    // const item = data["rental"]

    const title = ""
    const startDate = ""
    const endDate = ""
    const customerGroup = ""
    const customerPerson = ""
    const user = ""
    const rentalStatus = RentalStatus.Reserved.toString();

    return (
        <StateProvider context={TitleStateContext} defaultValue={title}>
            <StateProvider context={StartDateStateContext} defaultValue={startDate}>
                <StateProvider context={EndDateStateContext} defaultValue={endDate}>
                    <StateProvider context={CustomerGroupStateContext} defaultValue={customerGroup}>
                        <StateProvider context={CustomerPersonStateContext} defaultValue={customerPerson}>
                            <StateProvider context={UserStateContext} defaultValue={user}>
                                <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                    <StateProvider context={RentalStatusStateContext} defaultValue={rentalStatus}>
                                        <StateProvider context={EquipmentListStateContext} defaultValue={new StateOf([])}>
                                            <_FormImpl />
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )
}

const RentalAndReservationPage: React.FC = (props) => {
    return <_Form />
}

export default RentalAndReservationPage;