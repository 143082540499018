import { ApolloQueryResult, FetchResult, gql, useQuery } from "@apollo/client";
import { Button, TextareaAutosize } from "@mui/material";
import { EEXIST } from "constants";
import Papa from "papaparse";
import { listenerCount } from "process";
import React, { useCallback, useContext, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import Api from "../../Api";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import int from "../../int";
import IntUtils from "../../IntUtils";
import BaseModel from "../../models/BaseModel";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateOf from "../../states/StateOf";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Utils from "../../Utils";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import EquipmentManagementPage from "./EquipmentManagementPage";

type GraphQLFetchResult = FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
type CheckResult = {
    loading: boolean,
    data?: {
        result: boolean,
        reason?: string
    }
}

type EquipmentProtoProto = {
    uuid: int | undefined,
    name: string,
    categoryName: string,
    placeName: string,
    numbering: int | undefined,
    note: string,
    accessoriesInfo: string,
    bihinSticker: string
}
type EquipmentProto = {
    uuid: int,
    name: string,
    categoryId: string | undefined,
    placeId: string | undefined,
    numbering: int,
    note: string,
    accessoriesInfo: string,
    bihinSticker: string
}
type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const CSVCt = createStateContext<EquipmentProto[] | null>()
const CSVErrorCt = createStateContext<StateOf<Papa.ParseError[] | string | null>>()
const PlacesCt = createStateContext<Map<string, string> | null>()
const CategoriesCt = createStateContext<Map<string, string> | null>()
const PlacesRevCt = createStateContext<Map<string, string> | null>()
const CategoriesRevCt = createStateContext<Map<string, string> | null>()

const UUIDCheckCt = createStateContext<StateOf<Map<int, CheckResult>>>()
const NumberingCheckCt = createStateContext<StateOf<Map<int, CheckResult>>>()

const UpdatedStateContext = createStateContext<UpdateState>()

const CategoryListLoader: React.FC = (props) => {
    const categoriesCt = useContext(CategoriesCt);
    const categoriesRevCt = useContext(CategoriesRevCt);
    const nav = useNavigate()
    const query = gql`
      {
        equipmentCategories {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    useEffect(() => {
        if (result.loading) {
            return
        }

        if (result.data && result.data?.["equipmentCategories"]?.["edges"] !== undefined) {
            const items: Array<any> = result.data!["equipmentCategories"]["edges"]
                .map((e: any) => e.node)

            var map = new Map<string, string>()
            items.forEach((e) => {
                map.set(e.name, e.id)
            })
            categoriesCt.setState(map)

            var mapRev = new Map<string, string>()
            items.forEach((e) => {
                mapRev.set(e.id, e.name)
            })
            categoriesRevCt.setState(mapRev)
        } else {
            categoriesCt.setState(new Map())
            categoriesRevCt.setState(new Map())
        }
    }, [result.data])

    return <React.Fragment />
}

const PlaceListLoader: React.FC = (props) => {
    const placesCt = useContext(PlacesCt);
    const placesRevCt = useContext(PlacesRevCt);
    const nav = useNavigate()
    const query = gql`
      {
        equipmentPlaces {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    useEffect(() => {
        if (result.loading) {
            return
        }

        if (result.data && result.data?.["equipmentPlaces"]?.["edges"] !== undefined) {
            const items: Array<any> = result.data!["equipmentPlaces"]["edges"]
                .map((e: any) => e.node)

            var map = new Map<string, string>()
            items.forEach((e) => {
                map.set(e.name, e.id)
            })
            placesCt.setState(map)

            var mapRev = new Map<string, string>()
            items.forEach((e) => {
                mapRev.set(e.id, e.name)
            })
            placesRevCt.setState(mapRev)
        } else {
            placesCt.setState(new Map())
            placesRevCt.setState(new Map())

        }
    }, [result.data])

    return <React.Fragment />
}


const UUIDCheck = async (uuid: int | undefined, nav: NavigateFunction): Promise<CheckResult> => {
    if (uuid === undefined) {
        return {
            loading: false,
            data: {
                result: false,
                reason: "機材IDを指定してください"
            }
        }
    }
    // const uuid_list: int[] = [parseInt(`${uuid}`)]

    // console.log(uuid_list)
    // const uuid_list = [1]

    var result: GraphQLFetchResult;
    try {
        result = await Api.graphQLQuery({
            query: gql`
              query($uuid_list: [Int!]!){
                equipmentItems(uuid_list: $uuid_list) {
                  edges {
                    node {
                      uuid
                    }
                  }
                }
              }
            `,
            variables: { 'uuid_list': [uuid] },
            fetchPolicy: 'no-cache'
        })
    } catch (e) {
        Api.handleGraphQLException(e, nav);
    }

    // console.log(result?.data)

    if (result?.data !== undefined &&
        result.data?.['equipmentItems']?.['edges'] !== undefined) {
        if (result.data?.['equipmentItems']['edges'].length > 0) {
            const uuids: int[] = result.data?.['equipmentItems']['edges']
                .map((e: any) => e['node']['uuid'] as int)
                .sort();
            const res: string = uuids.map((e) => `#${e}`).join(", ");

            // console.log(res)

            return {
                loading: false,
                data: {
                    result: false,
                    reason: `${res} は既に存在します`
                }
            }
        }
    }

    // console.log("OK")

    return {
        loading: false,
        data: {
            result: true,
        }
    }
}

const NumberingCheck = async (name: string, categoryId: string | undefined, numbering: int | undefined, nav: NavigateFunction): Promise<CheckResult> => {
    if (numbering === undefined) {
        return {
            loading: false,
            data: {
                result: false,
                reason: "通し番号を指定してください"
            }
        }
    }

    if (name === "") {
        return {
            loading: false,
            data: {
                result: false,
                reason: "機材名を指定してください"
            }
        }
    }

    const categoryIdNum: int | undefined = categoryId !== undefined ? BaseModel.getIdNumFromString(categoryId) : undefined
    // const uuid_list: int[] = [parseInt(`${uuid}`)]

    // console.log(uuid_list)
    // const uuid_list = [1]

    var result: GraphQLFetchResult;
    try {
        result = await Api.graphQLQuery({
            query: gql`
              query($name: String!, $category_id: Int, $numbering_list: [Int!]!){
                equipmentItems(name: $name, category_id: $category_id, numbering_list: $numbering_list) {
                  edges {
                    node {
                      numbering
                    }
                  }
                }
              }
            `,
            variables: {
                'numbering_list': [numbering],
                'name': name,
                'category_id': categoryIdNum,
            },
            fetchPolicy: 'no-cache'
        })
    } catch (e) {
        Api.handleGraphQLException(e, nav);
    }

    // console.log(result?.data)

    if (result?.data !== undefined &&
        result.data?.['equipmentItems']?.['edges'] !== undefined) {
        if (result.data?.['equipmentItems']['edges'].length > 0) {
            const uuids: int[] = result.data?.['equipmentItems']['edges']
                .map((e: any) => e['node']['numbering'] as int)
                .sort();
            const res: string = uuids.map((e) => `No.${e}`).join(", ");

            // console.log(res)

            return {
                loading: false,
                data: {
                    result: false,
                    reason: `${name} ${res} は既に存在します`
                }
            }
        }
    }

    // console.log("OK")

    return {
        loading: false,
        data: {
            result: true,
        }
    }
}

const UUIDCheckResultWidget: React.FC<{ uuid: int }> = (props) => {

    const nav = useNavigate()
    const uuidCheckCt = useContext(UUIDCheckCt);

    useEffect(() => {
        (async () => {
            const v = await UUIDCheck(props.uuid, nav)
            uuidCheckCt.state.instance.set(props.uuid, v)
            uuidCheckCt.setState(new StateOf(uuidCheckCt.state.instance))
        })()
    }, [props.uuid])

    if (uuidCheckCt.state.instance.get(props.uuid)?.loading) {
        return <div className="CheckResult Loading">ID: 確認中...</div>
    }

    if (uuidCheckCt.state.instance.get(props.uuid)?.data?.result === false) {
        return <div className="CheckResult Error">{uuidCheckCt.state.instance.get(props.uuid)?.data?.reason}</div>
    }

    return <div className="CheckResult OK">ID: OK</div>
}

const NumberingCheckResultWidget: React.FC<{ uuid: int, name: string, category: string | undefined, numbering: int }> = (props) => {

    const nav = useNavigate()
    const numberingCheckCt = useContext(NumberingCheckCt);

    useEffect(() => {
        (async () => {
            const v = await NumberingCheck(props.name, props.category, props.numbering, nav)
            numberingCheckCt.state.instance.set(props.uuid, v)
            numberingCheckCt.setState(new StateOf(numberingCheckCt.state.instance))
        })()
    }, [props.name, props.category, props.numbering])

    if (numberingCheckCt.state.instance.get(props.uuid)?.loading) {
        return <div className="CheckResult Loading">番号: 確認中...</div>
    }

    if (numberingCheckCt.state.instance.get(props.uuid)?.data?.result === false) {
        return <div className="CheckResult Error">{numberingCheckCt.state.instance.get(props.uuid)?.data?.reason}</div>
    }

    return <div className="CheckResult OK">番号: OK</div>
}

const parsedToEquipmentProtos = (parsed: string[][], placeMap: Map<string, string>, categoryMap: Map<string, string>): EquipmentProto[] => {
    var list: EquipmentProtoProto[] = []
    for (const e of parsed) {
        const p = {
            uuid: IntUtils.tryParse(e[0]),
            name: e[1],
            categoryName: e[2],
            placeName: e[3],
            numbering: IntUtils.tryParse(e[4]),
            note: e[5],
            accessoriesInfo: e[6],
            bihinSticker: e[7]
        } as EquipmentProtoProto
        list.push(p)
    }

    // console.log(list)

    const filtered = list.filter((e) =>
        e.uuid !== undefined
        && e.numbering !== undefined
        && placeMap.get(e.placeName) !== undefined || e.placeName === ""
        && categoryMap.get(e.categoryName) !== undefined || e.categoryName === ""
    )

    return filtered.map((e) => (
        {
            uuid: e.uuid!,
            name: e.name,
            categoryId: categoryMap.get(e.categoryName),
            placeId: placeMap.get(e.placeName),
            numbering: e.numbering!,
            note: e.note,
            accessoriesInfo: e.accessoriesInfo,
            bihinSticker: e.bihinSticker
        } as EquipmentProto
    ))

}

const _FormImpl: React.FC<{}> = (props) => {
    // const loginContext = useContext(LoginStateContext);
    // const loginState = loginContext.state.instance;
    // const user = loginState.user;

    const placesCt = useContext(PlacesCt)
    const categoriesCt = useContext(CategoriesCt)
    const placesRevCt = useContext(PlacesRevCt)
    const categoriesRevCt = useContext(CategoriesRevCt)

    const headerList = "機材ID,機材名,カテゴリID,保管場所ID,通し番号,メモ,付属品,備品シール"
    const csvCt = useContext(CSVCt)
    const csvErrorCt = useContext(CSVErrorCt)
    const csvData: EquipmentProto[] | null = csvCt.state

    const uuidCheckCt = useContext(UUIDCheckCt);
    const numberingCheckCt = useContext(NumberingCheckCt);
    const uuidAlllOK = Array.from(uuidCheckCt.state.instance.keys()).filter((k) => {
        const e = uuidCheckCt.state.instance.get(k)!
        return e.data?.result !== true
    }).length === 0
    const numberingAllOK = Array.from(numberingCheckCt.state.instance.keys()).filter((k) => {
        const e = numberingCheckCt.state.instance.get(k)!
        return e.data?.result !== true
    }).length === 0

    const updatedContext = useContext(UpdatedStateContext)
    const nav = useNavigate()

    var temporaryCSV: string;

    return (
        <React.Fragment>
            <TextareaAutosize
                minRows={3}
                placeholder="ここにCSVファイルの中身を貼り付けてください"
                onChange={(e) => {
                    temporaryCSV = e.target.value
                }}
                style={{ width: 300, height: 200 }}
            />
            <div>
                ※ CSVの形式は、{headerList} です。
            </div>
            <SizedBox height={5} />
            <div onClick={() => {
                const parsed = Papa.parse<string[]>(temporaryCSV)
                if (parsed.errors.length > 0) {
                    csvCt.setState(null)
                    csvErrorCt.setState(new StateOf(parsed.errors))
                }

                const protos = parsedToEquipmentProtos(parsed.data, placesCt.state!, categoriesCt.state!)

                csvErrorCt.setState(new StateOf(null))
                csvCt.setState(protos)

                uuidCheckCt.setState(new StateOf(new Map()))
                numberingCheckCt.setState(new StateOf(new Map()))

                // console.log(parsed)
            }}>
                <Button variant="contained">
                    確認
                </Button>
            </div>

            {
                (csvErrorCt.state.instance !== null)
                    ? (
                        <React.Fragment>
                            <div>
                                CSVにエラーがあります
                            </div>

                            <div>
                                {JSON.stringify(csvErrorCt.state.instance)}
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length > 0)
                    ? (
                        <React.Fragment>
                            <div>
                                <table>
                                    <tr>
                                        {
                                            headerList.split(",").map((e) =>
                                                <th>{e}</th>
                                            )
                                        }
                                        <th>(確認)</th>
                                    </tr>
                                    {
                                        // categoryName: string,
                                        // placeName: string,
                                        // numbering: int | undefined,
                                        // note: string,
                                        // accessoriesInfo: string,
                                        // bihinSticker: string
                                        csvData.map((e) =>
                                            <tr>
                                                <td>{e.uuid}</td>
                                                <td>{e.name}</td>
                                                <td>{e.categoryId !== undefined ? categoriesRevCt.state?.get(e.categoryId) : "未分類"}</td>
                                                <td>{e.placeId !== undefined ? placesRevCt.state?.get(e.placeId) : "未指定"}</td>
                                                <td>{e.numbering}</td>
                                                <td>{e.note}</td>
                                                <td>{e.accessoriesInfo}</td>
                                                <td>{e.bihinSticker}</td>
                                                <td>
                                                    <UUIDCheckResultWidget uuid={e.uuid} />
                                                    <NumberingCheckResultWidget uuid={e.uuid} name={e.name} category={e.categoryId} numbering={e.numbering} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </table>
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length === 0)
                    ? <div>有効な行はありませんでした。数値等が正しいか、カテゴリや保管場所が事前に登録してあるかを確認してください。</div>
                    : <React.Fragment />
            }
            <SizedBox height={5} />
            {
                (csvData !== null && csvData.length > 0 && uuidAlllOK && numberingAllOK)
                    ?
                    <div onClick={async () => {
                        // if (uuidCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        // if (numberingCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        const queryStr = `
                          mutation($uuid: Int!, $numbering: Int!, $name: String!, $note: String, $accessoriesInfo: String, $bihinSticker: String, $category: String, $place: String, $aliveStatus: String! ) {
                            createEquipmentItem(input: {uuid: $uuid, numbering: $numbering, name: $name, note: $note, accessoriesInfo: $accessoriesInfo, bihinSticker: $bihinSticker, category: $category, place: $place, aliveStatus: $aliveStatus}) {
                              equipmentItem {
                                id
                                name
                              }
                              clientMutationId
                            }
                          }
                        `

                        const query = gql(queryStr)

                        var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
                        var vars = {}

                        var allOK = true;

                        var n = 0;

                        for (const e of csvCt.state!) {
                            ++n;

                            try {
                                result = await Api.graphQLClient?.mutate({
                                    mutation: query,
                                    variables: vars = {
                                        'uuid': e.uuid,
                                        'numbering': e.numbering,
                                        'name': e.name,
                                        'note': (e.note === "")
                                            ? undefined
                                            : e.note,
                                        'accessoriesInfo': (e.accessoriesInfo === "")
                                            ? undefined
                                            : e.accessoriesInfo,
                                        'bihinSticker': (e.bihinSticker === "")
                                            ? undefined
                                            : e.bihinSticker,
                                        'category': (e.categoryId === "")
                                            ? undefined
                                            : e.categoryId,
                                        'place': (e.placeId === "")
                                            ? undefined
                                            : e.placeId,
                                        'aliveStatus': EquipmentAliveStatus.Alive.toString()
                                    }
                                })
                            } catch (e) {
                                Api.handleGraphQLException(e, nav)
                            }

                            // console.log(queryStr)
                            // console.log(JSON.stringify(vars))

                            if (result?.data != undefined &&
                                result?.data?.["createEquipmentItem"] != undefined &&
                                result?.data?.["createEquipmentItem"]?.["equipmentItem"] !=
                                undefined) {

                                // OK. go next

                            } else {
                                updatedContext.setState(UpdateState.Failed)
                                console.error(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                alert(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                console.error(result);

                                allOK = false
                                break
                            }
                        }

                        if (allOK) {
                            updatedContext.setState(UpdateState.Succeeeded)

                            csvCt.setState(null);
                            csvErrorCt.setState(new StateOf(null));
                            Api.graphQLResetCache()

                            Router.popAndPushNamed(Router.getRouteOfPage(EquipmentManagementPage)!, nav)
                        }
                    }}>
                        <Button variant="contained">
                            インポートを実行
                        </Button>
                    </div>
                    : <Button disabled variant="contained">
                        インポートを実行
                    </Button>
            }
        </React.Fragment>
    );
}

const _FormWrap: React.FC = (props) => {
    const placesCt = useContext(PlacesCt)
    const categoriesCt = useContext(CategoriesCt)
    return (
        <React.Fragment>
            <PlaceListLoader />
            <CategoryListLoader />
            {
                (placesCt.state !== null && categoriesCt.state !== null)
                    ? <_FormImpl />
                    : <React.Fragment />
            }
        </React.Fragment>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()


    if (true) {
        return (
            <StateProvider context={CSVCt} defaultValue={null}>
                <StateProvider context={CSVErrorCt} defaultValue={new StateOf(null)}>
                    <StateProvider context={PlacesCt} defaultValue={null}>
                        <StateProvider context={CategoriesCt} defaultValue={null}>
                            <StateProvider context={PlacesRevCt} defaultValue={null}>
                                <StateProvider context={CategoriesRevCt} defaultValue={null}>
                                    <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                        <StateProvider context={UUIDCheckCt} defaultValue={new StateOf(new Map())}>
                                            <StateProvider context={NumberingCheckCt} defaultValue={new StateOf(new Map())}>
                                                <_FormWrap />
                                            </StateProvider>
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>エラーが発生しました</Center>
}

const CSVImportEquipmentsPage: React.FC = (props) => {
    return (
        <_Form />
    )
}

export default CSVImportEquipmentsPage;