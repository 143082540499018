import React from "react";
import RentalStatus from "../models/RentalStatus";
import RentalStatusExtension from "../models/RentalStatusExtension";

const RentalStatusWidget: React.FC<{ status: RentalStatus | string }> = (props) => {
    const status = typeof props.status === 'string' ? RentalStatusExtension.fromString(props.status) : props.status
    const value = RentalStatusExtension.toShortString(status)
    const name = RentalStatusExtension.toStringJp(status)
    const color = RentalStatusExtension.getColor(status)

    return (
        <span className="RentalStatus" style={{ backgroundColor: color }}>
            {name}
        </span>
    );
}

export default RentalStatusWidget;