import { TabPanel, TabContext, TabList } from "@mui/lab";
import { Box, Button, Divider, List, ListItemButton, ListItemIcon, ListItemText, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Api from "../../Api";
import BusinessYear from "../../BusinessYear";
import Const from "../../Const";
import { LoginStateContext, MenuItemsContext, YearRangeStateContext } from "../../contexts";
import Router from "../../Router";
import { Spacer } from "../../Spacer";
import createStateContext from "../../states/createStateContext";
import StateOf from "../../states/StateOf";
import StateProvider from "../../states/StateProvider";
import Utils from "../../Utils";
import VStack from "../../VStack";
import Loading from "../Loading";

// const settingsList = MenuItems.settingslist

type JsonData = any

const LoadingStateContext = createStateContext<boolean>()
const JsonDataContext = createStateContext<StateOf<JsonData>>()
const TabValueContext = createStateContext<string>()
const StartDateContext = createStateContext<string>()
const EndDateContext = createStateContext<string>()

const CustomerRentalTotalContext = createStateContext<string | null>()
const CustomerRentalPerCategoryContext = createStateContext<string | null>()
const RentalPerEquipmentContext = createStateContext<string | null>()
const EquipmentCountContext = createStateContext<string | null>()
const RentalPerUserContext = createStateContext<string | null>()

const downloadTextAsFile = (content: string, filename_as: string) => {

    const blob = new Blob([content], { type: 'text/plain' });
    const e = document.createElement("a");
    e.href = URL.createObjectURL(blob);
    e.download = filename_as;
    document.body.appendChild(e);
    e.click();
}

const jsonToCsv = (json: any, delimiter: string = ",") => {
    if (!json) return ""
    if (json.length < 1) return ""
    var header = Object.keys(json[0]).join(delimiter) + "\n";
    var body = json.map((d: any) => {
        return Object.keys(d).map(function (key) {
            return d[key];
        }).join(delimiter);
    }).join("\n");
    return header + body;
}

const TableView: React.FC<{ csv: string }> = (props) => {
    const csvData = props.csv
    const headers = csvData == null ? [] : csvData.split("\n")[0].split(",")
    const rows = csvData == null ? [] : csvData.split("\n").slice(1).map((row) => row.split(","))
    const rows_count = rows.length
    const row_thresh = 100
    const rows_filtered = (rows_count > row_thresh) ? rows.slice(0, row_thresh) : rows

    return (
        <React.Fragment>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow key="header">
                            {headers.map((header) => (
                                <TableCell>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows_filtered.map((row, index) => (
                            <TableRow key={index.toString()}>
                                {row.map((cell) => (
                                    <TableCell component="th" scope="row">
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {(rows_count > row_thresh) && <p>※ 表示は全{rows_count}行中の最初の{row_thresh}行 (完全版はダウンロード)</p>}
        </React.Fragment>
    )
}

const RentalPerUser = () => {
    const rentalPerUserContext = useContext(RentalPerUserContext)
    const csvData: string | null = rentalPerUserContext.state

    const startDateContext = useContext(StartDateContext)
    const endDateContext = useContext(EndDateContext)

    useEffect(() => {
        (async () => {
            Api.postJson("rental_per_user", {
                "start_date": startDateContext.state,
                "end_date": endDateContext.state
            })
                .then((res) => {
                    const jsonData = res?.data
                    const csvData = jsonToCsv(jsonData)
                    rentalPerUserContext.setState(csvData)
                })
        })()
    }, [startDateContext.state, endDateContext.state])

    const startDateHyphen = startDateContext.state.replaceAll("/", "-")
    const endDateHyphen = endDateContext.state.replaceAll("/", "-")

    return (
        csvData == null ? <Loading /> :
            <div>
                <Button variant="contained" onClick={() => {
                    downloadTextAsFile(csvData, `貸出回数（ユーザ別）｜${startDateHyphen}〜${endDateHyphen}.csv`)
                }}>
                    CSVをダウンロード
                </Button>

                <TableView csv={csvData} />
            </div>
    )
}

const EquipmentCount = () => {
    const equipmentCountContext = useContext(EquipmentCountContext)
    const csvData: string | null = equipmentCountContext.state

    const startDateContext = useContext(StartDateContext)
    const endDateContext = useContext(EndDateContext)

    useEffect(() => {
        (async () => {
            Api.postJson("equipment_count", {
                "start_date": startDateContext.state,
                "end_date": endDateContext.state
            })
                .then((res) => {
                    const jsonData = res?.data
                    const csvData = jsonToCsv(jsonData)
                    equipmentCountContext.setState(csvData)
                })
        })()
    }, [startDateContext.state, endDateContext.state])

    const startDateHyphen = startDateContext.state.replaceAll("/", "-")
    const endDateHyphen = endDateContext.state.replaceAll("/", "-")

    return (
        csvData == null ? <Loading /> :
            <div>
                <Button variant="contained" onClick={() => {
                    downloadTextAsFile(csvData, `機材数（カテゴリ別）｜${startDateHyphen}〜${endDateHyphen}.csv`)
                }}>
                    CSVをダウンロード
                </Button>

                <TableView csv={csvData} />
            </div>
    )
}

const RentalPerEquipment = () => {
    const rentalPerEquipmentContext = useContext(RentalPerEquipmentContext)
    const csvData: string | null = rentalPerEquipmentContext.state

    const startDateContext = useContext(StartDateContext)
    const endDateContext = useContext(EndDateContext)

    useEffect(() => {
        (async () => {
            Api.postJson("rental_per_equipment", {
                "start_date": startDateContext.state,
                "end_date": endDateContext.state
            })
                .then((res) => {
                    const jsonData = res?.data
                    const csvData = jsonToCsv(jsonData)
                    rentalPerEquipmentContext.setState(csvData)
                })
        })()
    }, [startDateContext.state, endDateContext.state])

    const startDateHyphen = startDateContext.state.replaceAll("/", "-")
    const endDateHyphen = endDateContext.state.replaceAll("/", "-")

    return (
        csvData == null ? <Loading /> :
            <div>
                <Button variant="contained" onClick={() => {
                    downloadTextAsFile(csvData, `貸出回数（機材別）｜${startDateHyphen}〜${endDateHyphen}.csv`)
                }}>
                    CSVをダウンロード
                </Button>

                <TableView csv={csvData} />
            </div>
    )
}

const CustomerRentalPerCategory = () => {
    const customerRentalPerCategoryContext = useContext(CustomerRentalPerCategoryContext)
    const csvData: string | null = customerRentalPerCategoryContext.state

    const startDateContext = useContext(StartDateContext)
    const endDateContext = useContext(EndDateContext)

    useEffect(() => {
        (async () => {
            Api.postJson("customer_rental_per_category", {
                "start_date": startDateContext.state,
                "end_date": endDateContext.state
            })
                .then((res) => {
                    const jsonData = res?.data
                    const csvData = jsonToCsv(jsonData)
                    customerRentalPerCategoryContext.setState(csvData)
                })
        })()
    }, [startDateContext.state, endDateContext.state])

    const startDateHyphen = startDateContext.state.replaceAll("/", "-")
    const endDateHyphen = endDateContext.state.replaceAll("/", "-")

    return (
        csvData == null ? <Loading /> :
            <div>
                <Button variant="contained" onClick={() => {
                    downloadTextAsFile(csvData, `貸出機材数（貸出先別、カテゴリ別）｜${startDateHyphen}〜${endDateHyphen}.csv`)
                }}>
                    CSVをダウンロード
                </Button>

                <TableView csv={csvData} />
            </div>
    )
}

const CustomerRentalTotal = () => {
    const customerRentalTotalContext = useContext(CustomerRentalTotalContext)
    const csvData: string | null = customerRentalTotalContext.state

    const startDateContext = useContext(StartDateContext)
    const endDateContext = useContext(EndDateContext)

    useEffect(() => {
        (async () => {
            Api.postJson("customer_rental_total", {
                "start_date": startDateContext.state,
                "end_date": endDateContext.state
            })
                .then((res) => {
                    const jsonData = res?.data
                    const csvData = jsonToCsv(jsonData)
                    customerRentalTotalContext.setState(csvData)
                })
        })()
    }, [startDateContext.state, endDateContext.state])

    const startDateHyphen = startDateContext.state.replaceAll("/", "-")
    const endDateHyphen = endDateContext.state.replaceAll("/", "-")

    return (
        csvData == null ? <Loading /> :
            <div>
                <Button variant="contained" onClick={() => {
                    downloadTextAsFile(csvData, `貸出回数（貸出先別）｜${startDateHyphen}〜${endDateHyphen}.csv`)
                }}>
                    CSVをダウンロード
                </Button>

                <TableView csv={csvData} />
            </div>
    )
}

const StaticsPageImplImpl: React.FC = () => {
    const tabValueContext = useContext(TabValueContext)
    const startDateContext = useContext(StartDateContext)
    const endDateContext = useContext(EndDateContext)
    // const csvText = jsonToCsv(jsonData)

    return (
        <div>
            <VStack>
                {/* 統計期間: */}
                <DatePicker
                    className="Required"
                    label="統計開始日"
                    value={startDateContext.state}
                    onChange={(v: any) => {
                        if (v !== null) {
                            const s = Utils.getFormatDateFromString(v)
                            startDateContext.setState(s)
                        }
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                />
                {/* ～ */}
                <Spacer size={14} />
                <DatePicker
                    className="Required"
                    label="統計終了日"
                    value={endDateContext.state}
                    onChange={(v: any) => {
                        if (v !== null) {
                            const s = Utils.getFormatDateFromString(v)
                            endDateContext.setState(s)
                        }
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                />
            </VStack>

            {
                // if selected years are valid format
                Utils.isValidDate(startDateContext.state) && Utils.isValidDate(endDateContext.state) ?
                    <TabContext value={tabValueContext.state}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e: React.SyntheticEvent, value: string) => {
                                tabValueContext.setState(value)
                            }} aria-label="tabs" variant="scrollable" >
                                <Tab label="貸出回数（貸出先別）" value="1" />
                                <Tab label="貸出機材数（貸出先別、カテゴリ別）" value="2" />
                                <Tab label="貸出回数（機材別）" value="3" />
                                <Tab label="貸出回数（ユーザ別）" value="5" />
                                <Tab label="機材数（カテゴリ別）" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <StateProvider context={CustomerRentalTotalContext} defaultValue={null}>
                                <CustomerRentalTotal />
                            </StateProvider>
                        </TabPanel>
                        <TabPanel value="2">
                            <StateProvider context={CustomerRentalPerCategoryContext} defaultValue={null}>
                                <CustomerRentalPerCategory />
                            </StateProvider>
                        </TabPanel>
                        <TabPanel value="3">
                            <StateProvider context={RentalPerEquipmentContext} defaultValue={null}>
                                <RentalPerEquipment />
                            </StateProvider>
                        </TabPanel>
                        <TabPanel value="5">
                            <StateProvider context={RentalPerUserContext} defaultValue={null}>
                                <RentalPerUser />
                            </StateProvider>
                        </TabPanel>
                        <TabPanel value="4">
                            <StateProvider context={EquipmentCountContext} defaultValue={null}>
                                <EquipmentCount />
                            </StateProvider>
                        </TabPanel>
                    </TabContext>
                    : <Loading />
            }


        </div>
    );
}

const StaticsPageImpl: React.FC = () => {
    const nav = useNavigate()

    const loadingContext = useContext(LoadingStateContext);
    const isLoading = loadingContext.state;

    const jsonDataContext = useContext(JsonDataContext);

    useEffect(() => {
        (async () => {
            const res = await Api.getJson("statics");
            // max_uuid = res?.data?.max_uuid !== undefined ? parseInt(res?.data?.max_uuid) : 0;

            // // const res = await Api.getJson("max_equipment_uuid");
            // // max_uuid = res?.data?.max_uuid !== undefined ? parseInt(res?.data?.max_uuid) : 0;

            // // console.log(res?.data?.max_uuid)

            jsonDataContext.setState(new StateOf(res?.data));
            loadingContext.setState(false)
        })()
    }, [])

    if (isLoading) {
        return <Loading />
    }

    return (
        <StaticsPageImplImpl />
    );
}

const StaticsPage: React.FC = () => {
    const yearRangeStateContext = useContext(YearRangeStateContext)
    const yearStart = yearRangeStateContext.state.instance.startYear
    const yearEnd = yearRangeStateContext.state.instance.endYear

    return (
        <StateProvider context={JsonDataContext} defaultValue={new StateOf({})}>
            <StateProvider context={LoadingStateContext} defaultValue={true}>
                <StateProvider context={TabValueContext} defaultValue={"1"}>
                    <StateProvider context={StartDateContext} defaultValue={`${yearStart}/04/01`}>
                        <StateProvider context={EndDateContext} defaultValue={`${yearEnd}/03/31`}>
                            <StaticsPageImpl />
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    );
}

export default StaticsPage;