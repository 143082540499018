import { ApolloQueryResult, gql } from "@apollo/client";
import { NavigateFunction } from "react-router";
import Api from "../Api";
import int from "../int";
import Equipment from "../models/Equipment";

class CartController {
    private _list: Equipment[] = []
    updatedFlag: boolean = false;

    getLength(): int {
        return this._list.length;
    }

    addToCart(equipment: Equipment): boolean {
        if (!this.includes(equipment)) {
            this._list.push(equipment);
            return true;
        } else {
            return false;
        }
    }

    async addToCartById(id: int, nav: NavigateFunction): Promise<boolean> {
        var result: ApolloQueryResult<any> | undefined
        try {
            result = await Api.graphQLQuery({
                query: gql`
                query($id_list: [Int]){
                    equipmentItems(id_list: $id_list) {
                            edges {
                                node {
                                id
                                _id
                                uuid
                                name
                                numbering
                                category {
                                    id
                                    name
                                    iconName
                                }
                            }
                        }
                    }
                }
                    `,
                variables: {
                    'id_list': [id]
                },
                fetchPolicy: 'no-cache',
            })
        } catch (e) {
            Api.handleGraphQLException(e, nav);
        }

        if (result?.data != null &&
            result?.data?.['equipmentItems']?.['edges'] !== undefined &&
            result.data?.['equipmentItems']['edges'].length > 0
        ) {
            for (var item of result.data?.['equipmentItems']['edges']) {
                const e = new Equipment(item?.["node"]);
                return this.addToCart(e);
            }

            // console.log("Card referch succeeded");
            return true;
        } else {
            console.error("Cart add FAILED");
            console.error(result);
            return false;
        }

        // const eq = new Equipment({ id: id });
        // return this.addToCart(eq);
    }

    removeFromCart(equipment: Equipment): boolean {
        if (this.includes(equipment)) {
            this._remove(equipment);
            return true;
        } else {
            return false;
        }
    }

    getIds(): string[] {
        return this._list.map((e) => e.id);
    }

    getEquipmentItems(): Equipment[] {
        return this._list;
    }

    includes(needle: Equipment): boolean {
        for (var other of this._list) {
            if (other.id == needle.id) {
                return true;
            }
        }

        return false;
    }

    includesId(id: number | string): boolean {
        if (typeof id === "number") {
            id = `/equipment_items/${id}`;
        }
        for (var other of this._list) {
            if (other.id == id) {
                return true;
            }
        }

        return false;
    }

    private _remove(equipment: Equipment) {
        this._list = this._list.filter((e) => e.id !== equipment.id);
    }

    clear() {
        this._list = []
    }

    async refetch(nav: NavigateFunction): Promise<boolean> {

        var result: ApolloQueryResult<any> | undefined
        try {
            result = await Api.graphQLQuery({
                query: gql`
            query($id_list: [Int]){
                equipmentItems(id_list: $id_list) {
                        edges {
                            node {
                            id
                            _id
                            uuid
                            name
                            numbering
                              category {
                                id
                                name
                                iconName
                            }
                        }
                    }
                }
            }
                    `,
                variables: {
                    'id_list': this.getEquipmentItems().map((e) => e.getIdNum())
                },
                fetchPolicy: 'no-cache',
            })
        } catch (e) {
            Api.handleGraphQLException(e, nav);
        }

        if (result?.data != null &&
            result?.data?.['equipmentItems']?.['edges'] !== undefined &&
            result.data?.['equipmentItems']['edges'].length > 0
        ) {
            this.clear();

            for (var item of result.data?.['equipmentItems']['edges']) {
                const e = new Equipment(item?.["node"]);
                this.addToCart(e);
            }

            // console.log("Card referch succeeded");
            return true;
        } else {
            console.error("Card referch FAILED");
            console.error(result);
            return false;
        }

        // _itemListChangeNotifier.updatedInfo();
    }

    addItems(equipments: Equipment[]) {
        for (const item of equipments) {
            this._list.push(item);
        }
    }
}

export default CartController;
