class Const {
    static appTitle = 'TEMDEC機材管理';
    static loginErrorText = 'IDまたはパスワードが誤っています';
    static loginSessionErrorText = '一定時間が経過したためログアウトしました。再度ログインしてください。';
    static loginLoggedOutByUserErrorText = 'ログアウトしました。再度ログインしてください。';
    static adminPageErrorText = 'このページを表示する権限がありません';
    static connectionErrorText = '通信に失敗しました。しばらく時間をあけるか、管理者にお問い合わせください。';
    static groupNameLabelText = '名称';
    static familyNameLabelText = '姓';
    static firstNameLabelText = '名';
    static emailLabelText = 'メールアドレス';
    static telLabelText = '電話番号';
    static passwordLabelText = 'パスワード';
    static loginButtonLabelText = 'ログイン';
    static customerGroupLabelText = "グループ (登録者数)";
    static customerPeopleLabelText = "担当者";
    static customerPersonNullMenuItemText = "(分類なし)";
    static customerGroupDropDownMenuLabelText = "グループ";
    static customerPersonDropDownMenuLabelText = "担当者";
    static userGroupLabelText = "グループ (登録者数)";
    static userPeopleLabelText = "ユーザ";
    static userGroupDropDownMenuLabelText = "グループ";
    static userNewPasswordLabelText = "新規パスワード (変更する場合に入力)";
    static rentalTitleLabelText = '貸出タイトル';
    static rentalStartDateLabelText = '貸出開始日';
    static rentalEndDateLabelText = '貸出終了日';
    static rentalReturnDateLabelText = '返却日';
    static rentalUserInChargeLabelText = 'TEMDEC側 担当者';
    static rentalStatusLabelText = '貸出ステータス';
    static rentalCustomerGroupLabelText = '貸出先 グループ';
    static rentalCustomerPersonLabelText = '貸出先 担当者';
    static rentalReturnNoteLabelText = '返却メモ';
    static rentalReturnUserInChargeLabelText = '受取人 (TEMDEC側)';
    static rentalReturnedText = '返却済み';
    // static rentalStatusLabelText = "予約";
    static equipmentCountLabelText = "追加個数";
    static equipmentNameLabelText = '機材名';
    static equipmentUuidLabelText = "機材ID";
    static equipmentNumberingLabelText = "通し番号";
    static equipmentNoteLabelText = 'メモ';
    static equipmentAccessoriesInfoLabelText = '付属品';
    static equipmentBihinStickerLabelText = '備品シール';
    static equipmentCategoryNullMenuItemText = "未分類";
    static equipmentCategoryDropDownMenuLabelText = "カテゴリ";
    static equipmentCategoryManagementLabelText = "カテゴリの管理";
    static equipmentCategoryIconLabelText = "アイコン";
    static iconPickerTitleText = "アイコンを選択";
    static iconPickerSearchLabelText = "検索 (英語)";
    static iconPickerCancelText = "キャンセル";
    static iconPickerClearText = "アイコンなしにする";
    static equipmentPlaceNullMenuItemText = "未定";
    static equipmentPlaceDropDownMenuLabelText = "保管場所";
    static equipmentPlaceManagementLabelText = "保管場所の管理";
    static equipmentPlaceIconLabelText = "アイコン";
    static editButtonText = "編集";
    static addNewButtonText = "新規登録";
    static backButtonText = "戻る";
    static cancelButtonText = "キャンセル";
    static okButtonText = "OK";
    static deleteButtonText = "削除";
    static deleteComfirmDialogTitleText = "確認";
    static deleteComfirmDialogBodyText = "元に戻せませんが、本当に削除してもよろしいですか？";
    static loadingText = "読み込み中";
    static rentalReturnButtonText = "返却";
    static updateAndSaveButtonText = "更新";
    static updatedAndSavedLabelText = "更新されました";
    static updateFailedLabelText = "更新に失敗しました。時間をあけるか、管理者にお問い合わせください。";
    static requiredErrorLabelText = "必須項目です";
    static filterWidgetTitleText = "フィルタ";
    static sortMenuLabelText = "並び順";
    static expandMoreText = "さらに表示...";
}

export default Const;