import React, { useContext } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { LoginStateContext } from "../contexts";
import LoginArguments from "../LoginArguments";
import Router from "../Router";
import MainNav from "./MainNav";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";

const RootNav: React.FC = () => {
    const loginContext = useContext(LoginStateContext);
    const loginInfo = loginContext.state.instance;

    const location = useLocation();
    Router.route = location.pathname;

    if (loginInfo.isLoggedIn()) {
        if (Router.route === Router.getRouteOfPage(LoginPage)) {
            // print("login page and redirect (root_nav)");

            // SchedulerBinding.instance!.addPostFrameCallback((_) {
            var redirectTo = Router.getRouteOfPage(HomePage);
            if ('redirectTo' in Router.arguments) {
                var args = Router.arguments as LoginArguments;
                redirectTo = args.redirectTo;
                if (redirectTo === Router.getRouteOfPage(LoginPage) ||
                    redirectTo === Router.getRouteOfPage(LogoutPage)) {
                    redirectTo = Router.getRouteOfPage(HomePage);
                }
            }

            // print(
            //     "Redirecting to $redirectTo (from $route, by root_nav)");
            //   Navigator.of(context).pushNamed(redirectTo);
            return <Navigate to={redirectTo} />

            // return <React.Fragment>Redirecting...</React.Fragment>
        } else {
            return <MainNav />
        }
    } else {
        if (Router.route === Router.getRouteOfPage(LoginPage)) {
            // print("log in page (root_nav)");
            return <LoginPage />;
        } else {
            console.log("redirecting to login page (root_nav)");

            // TODO: add LoginArguments
            // arguments: LoginArguments(redirectTo: route));

            return <Navigate to={Router.getRouteOfPage(LoginPage)} />
        }
        // return <React.Fragment>root nav</React.Fragment>
    }
}

export default RootNav;