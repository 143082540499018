import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import CustomerManagementPage from "./CustomerManagementPage";

const UpdatedStateContext = createStateContext<UpdateState>();
const GroupStateContext = createStateContext<string | null>();
const EmailStateContext = createStateContext<string>();
const FirstNameStateContext = createStateContext<string>();
const FamilyNameStateContext = createStateContext<string>();
const TelStateContext = createStateContext<string>();

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const _CustomerGroupDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const groupStateContext = useContext(GroupStateContext)

    return (
        <SelectValidator
            className="Required"
            value={groupStateContext.state === null ? "_" : groupStateContext.state}
            label={Const.customerGroupDropDownMenuLabelText}
            name="customer-group"
            onChange={(e: any) => { groupStateContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={"_"}>{Const.customerPersonNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CustomerGroupDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        customerGroups{
          edges {
            node {
              id
              _id
              name
              persons {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {}
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = result.data!["customerGroups"]["edges"]
            .map((e: any) => e["node"]);

        return <_CustomerGroupDropDownImpl items={items} />
    } else {
        return <_CustomerGroupDropDownImpl items={[]} />
    }
}

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const emailContext = useContext(EmailStateContext);
    const firstNameContext = useContext(FirstNameStateContext);
    const familyNameContext = useContext(FamilyNameStateContext);
    const telContext = useContext(TelStateContext);
    const updatedContext = useContext(UpdatedStateContext);
    const groupContext = useContext(GroupStateContext)

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                var email = emailContext.state;
                var firstName = firstNameContext.state;
                var familyName = familyNameContext.state;
                var tel = telContext.state;
                var customerGroupId = groupContext.state;

                const queryStr = `
                  mutation($familyName: String!, $firstName: String!, $email: String, $tel: String, $customerGroup: String) {
                    createCustomerPerson(input: {familyName: $familyName, firstName: $firstName, email: $email, tel: $tel, customerGroup: $customerGroup}) {
                      customerPerson {
                        id
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'familyName': familyName,
                            'firstName': firstName,
                            'email': email == ""
                                ? undefined
                                : email,
                            'tel': tel == ""
                                ? undefined
                                : tel,
                            'customerGroup': customerGroupId,
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["createCustomerPerson"] != undefined &&
                    result?.data?.["createCustomerPerson"]?.["customerPerson"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    emailContext.setState("");
                    firstNameContext.setState("");
                    familyNameContext.setState("");
                    telContext.setState("");
                    groupContext.setState(null);

                    Router.popAndPushNamed(Router.getRouteOfPage(CustomerManagementPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >

            <_CustomerGroupDropDown />

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.familyNameLabelText}
                        onChange={(e: any) => familyNameContext.setState(e.target.value)}
                        name="familyName"
                        value={familyNameContext.state}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.firstNameLabelText}
                        onChange={(e: any) => firstNameContext.setState(e.target.value)}
                        name="firstName"
                        value={firstNameContext.state}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <TextValidator
                // className="Required"
                label="メールアドレス"
                onChange={(e: any) => emailContext.setState(e.target.value)}
                name="email"
                value={emailContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icons.Email />
                        </InputAdornment>
                    ),
                }}
                validators={['isEmail']}
                errorMessages={[ValidatorMessages.emailNotValid]}
            />

            <SizedBox height={10} />

            <TextValidator
                label={Const.telLabelText}
                onChange={(e: any) => telContext.setState(e.target.value)}
                name="tel"
                value={telContext.state}
            />
            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm>
    )
}

const _Form: React.FC = (props) => {
    const params = useParams();
    const group_id_num = params.id! !== "0" ? parseInt(params.id!) : 0;
    const group_id = group_id_num !== 0 ? `/customer_groups/${group_id_num}` : null;

    const nav = useNavigate()

    return (
        <StateProvider context={FamilyNameStateContext} defaultValue={""}>
            <StateProvider context={FirstNameStateContext} defaultValue={""}>
                <StateProvider context={EmailStateContext} defaultValue={""}>
                    <StateProvider context={TelStateContext} defaultValue={""}>
                        <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                            <StateProvider context={GroupStateContext} defaultValue={group_id}>
                                <_FormImpl />
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )

}

const CustomerPersonAddPage: React.FC = (props) => {
    return <_Form />
}

export default CustomerPersonAddPage;