import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import FilterController from "../FilterController";

class RentalListFilterController implements FilterController {
    constructor() {
        // sortCt.text = "createdAt:desc";
    }

    titleCt = createStateContext<string>()
    userInChargeCt = createStateContext<string>()
    sortCt = createStateContext<string>()
    showCompletedCt = createStateContext<boolean>()
    filterCompletedByYearCt = createStateContext<boolean>()
    showReservedCt = createStateContext<boolean>()
    showVacantCt = createStateContext<boolean>()
    showOngoingCt = createStateContext<boolean>()

    StateInitializer: React.FC<{}> = (props) => {
        return (
            <StateProvider context={this.titleCt} defaultValue={""} >
                <StateProvider context={this.userInChargeCt} defaultValue={""} >
                    <StateProvider context={this.sortCt} defaultValue={"createdAt:desc"} >
                        <StateProvider context={this.showCompletedCt} defaultValue={false} >
                            <StateProvider context={this.filterCompletedByYearCt} defaultValue={true} >
                                <StateProvider context={this.showReservedCt} defaultValue={true} >
                                    <StateProvider context={this.showVacantCt} defaultValue={true} >
                                        <StateProvider context={this.showOngoingCt} defaultValue={true} >
                                            {props.children}
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    sortMap: Map<string, string> = new Map(Object.entries({
        "作成日時 (昇順 ↑ )": "createdAt:asc",
        "作成日時 (降順 ↓ )": "createdAt:desc",
        "更新日時 (昇順 ↑ )": "updatedAt:asc",
        "更新日時 (降順 ↓ )": "updatedAt:desc",
        "貸出開始日時 (昇順 ↑ )": "startDate:asc",
        "貸出開始日時 (降順 ↓ )": "startDate:desc",
        "貸出終了日時 (昇順 ↑ )": "endDate:asc",
        "貸出終了日時 (降順 ↓ )": "endDate:desc",
        "名前 (昇順 ↑ )": "title:asc",
        "名前 (降順 ↓ )": "title:desc",
    }));

    ordersMap: Map<string, Array<any>> = new Map(Object.entries({
        "updatedAt:asc": [
            { "updatedAt": "asc" }
        ],
        "updatedAt:desc": [
            { "updatedAt": "desc" }
        ],
        "createdAt:asc": [
            { "createdAt": "asc" }
        ],
        "createdAt:desc": [
            { "createdAt": "desc" }
        ],
        "startDate:asc": [
            { "startDate": "asc" }
        ],
        "startDate:desc": [
            { "startDate": "desc" }
        ],
        "endDate:asc": [
            { "endDate": "asc" }
        ],
        "endDate:desc": [
            { "endDate": "desc" }
        ],
        "title:asc": [
            { "title": "asc" }
        ],
        "title:desc": [
            { "title": "desc" }
        ],
        // "name:asc": [
        //   {"familyName": "asc"},
        //   {"firstName": "asc"}
        // ],
        // "name:desc": [
        //   {"familyName": "desc"},
        //   {"firstName": "desc"}
        // ],
    }));
}

export default RentalListFilterController;