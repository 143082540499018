import React from 'react';
import '../resources/App.css';
import RootNav from './RootNav';
import StateProvider from '../states/StateProvider';
import { LoginStateContext, MenuItemsContext, TitleStateContext, CartContext, SnackBarStateContext, EndDrawerControllerStateContext } from '../contexts';
import StateOf from '../states/StateOf';
import LoginState from '../states/LoginState';
import { BrowserRouter } from 'react-router-dom';
import Router from '../Router';
import MenuItems from '../MenuItems';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import jaLocale from 'date-fns/locale/ja';
import CartController from '../controllers/CartController';
import SnackBarState from '../states/SnackBarState';
import EndDrawerController from '../controllers/EndDrawerController';

const App: React.FC = () => {
  // const menuList = MenuItems.list
  // console.log(menuList)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      <BrowserRouter>
        <StateProvider context={TitleStateContext} defaultValue={Router.getMaybeTitleFromRoute()}>
          <StateProvider context={LoginStateContext} defaultValue={new StateOf(new LoginState())}>
            <StateProvider context={MenuItemsContext} defaultValue={new StateOf(new MenuItems())}>
              <StateProvider context={CartContext} defaultValue={new StateOf(new CartController())}>
                <StateProvider context={SnackBarStateContext} defaultValue={new StateOf(new SnackBarState())}>
                  <StateProvider context={EndDrawerControllerStateContext} defaultValue={new StateOf(new EndDrawerController())} >
                    <RootNav></RootNav>
                  </StateProvider>
                </StateProvider>
              </StateProvider>
            </StateProvider>
          </StateProvider>
        </StateProvider>
      </BrowserRouter>
    </LocalizationProvider>
  )
}

export default App;
