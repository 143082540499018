import IconData from "../IconData";
import IconFaData from "../IconFaData";
import IconUtils from "../IconUtils";
import BaseModel from "./BaseModel";

class EquipmentCategory extends BaseModel {
    constructor(props: {
        id: string,
        name: string
        iconName: string,
    }) {
        super({ id: props.id });
        this.name = props.name;
        this.iconName = props.iconName;
    }

    name: string
    iconName: string

    equals(e: EquipmentCategory): boolean {
        return this.id == e.id;
    }

    getIconData(): IconFaData | undefined {
        return IconUtils.getIconFaFromName(this.iconName);
    }
}

export default EquipmentCategory;