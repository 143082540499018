import int from "./int";

class IntUtils {
    private static _isInDesiredForm(str: string) {
        return /^\+?\d+$/.test(str);
    }
    static tryParse(s: string): int | undefined {
        if (IntUtils._isInDesiredForm(s)) {
            return parseInt(s)
        } else {
            return undefined
        }
    }
}

export default IntUtils;