import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import gql from 'graphql-tag';
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { ApolloProvider } from "@apollo/client";
import Api from '../Api';

var graphQLClient: ApolloClient<NormalizedCacheObject> | undefined;

const GraphQLProvider: React.FC = (props) => {
    if (graphQLClient === undefined) {
        graphQLClient = Api.graphQLClient

        if (graphQLClient === undefined) {
            Api.updateGraphQLClient()
            graphQLClient = Api.graphQLClient
        }
    }
    return <ApolloProvider client={graphQLClient!}>{props.children}</ApolloProvider>
};

export default GraphQLProvider;