import React, { useContext, useEffect } from 'react';
import Equipment from '../models/Equipment';
import RentalReturnLog from '../models/RentalReturnLog';
import * as Icons from "@mui/icons-material";
import { Button, List, ListItem } from '@mui/material';
import SizedBox from './SizedBox';
import StateOf from '../states/StateOf';
import StateContextProp from '../states/StateContextProp';
import { CartContext } from '../contexts';
import { Link } from 'react-router-dom';
import Router from '../Router';
import EquipmentDetailPage from './pages/EquipmentDetailPage';
import { Box } from '@mui/system';
import Const from '../Const';
import IconFa from './IconFa';

type StateOfArrayEquipment = StateOf<Array<Equipment>>
type ContextOfArrayEquipment = StateContextProp<StateOfArrayEquipment>

const _ItemsList: React.FC<{ equipmentItems: Equipment[], equipmentItemsContext: ContextOfArrayEquipment }> = (props) => {

    const equipmentItems = props.equipmentItems
    const equipmentItemsContext = props.equipmentItemsContext

    return (
        <List className="CartItemsList">
            {
                equipmentItems.map((e) => {
                    return (
                        <ListItem key={e.id}>
                            {
                                (
                                    <div onClick={() => {
                                        const newItems = equipmentItems.filter((_e) => _e.id !== e.id)
                                        equipmentItemsContext.setState(new StateOf(newItems))
                                    }}>
                                        <Button>
                                            <Icons.Clear /> (削除)
                                        </Button>
                                    </div>
                                )
                            }
                            <Link className="EquipmentViewReadableName" to={
                                Router.getRouteOfPage(EquipmentDetailPage)
                                    .replace(":id", Equipment.getIdNumFromString(e.id!)!.toString())
                            }
                            >
                                <IconFa icon={e?.category?.iconName} />
                                <SizedBox inline width={3} />
                                {e.getReadableName()}
                            </Link >
                        </ListItem>
                    )
                })
            }
            {
                (equipmentItems.length === 0)
                    ? <ListItem key={"_"}>
                        なし
                    </ListItem>
                    : <React.Fragment></React.Fragment>
            }
        </List >
    )
}

const BatchEditPageEquipmentsViewImpl: React.FC<{ equipmentItems: Equipment[], equipmentItemsContext: ContextOfArrayEquipment }> = (props) => {

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const items = props.equipmentItems
    const equipmentItemsContext = props.equipmentItemsContext

    return (
        <div className="EquipmentsView">
            <div className="EquipmentsViewHeader">
                <Icons.Inventory2Outlined />
                <SizedBox inline width={3} />
                <span>{`機材品目 (${items.length})`}</span>
                <div className="EquipmentsViewHeaderCartButton" onClick={() => {
                    const newItems: Equipment[] =
                        [...cartController.getEquipmentItems(), ...items];
                    equipmentItemsContext.setState(new StateOf(newItems))

                    cartController.clear();
                    cartContext.setState(new StateOf(cartController))
                }}>
                    <Button variant="contained" disabled={cartController.getLength() === 0}>
                        <Icons.ShoppingCartRounded />
                        <SizedBox inline width={3} />
                        {`カート内の機材 (${cartController.getLength()}) をここに追加`}
                    </Button>
                </div>
            </div>
            <div className="EquipmentsViewContent">
                <_ItemsList equipmentItems={items} equipmentItemsContext={equipmentItemsContext} />
            </div>
        </div>
    )
}

const BatchEditPageEquipmentsView: React.FC<{ equipmentItemsContext: ContextOfArrayEquipment }> = (props) => {
    const equipmentItems: Equipment[] = props.equipmentItemsContext.state.instance;

    return (
        <BatchEditPageEquipmentsViewImpl
            equipmentItems={equipmentItems}
            equipmentItemsContext={props.equipmentItemsContext}
        />
    );
}

export default BatchEditPageEquipmentsView;