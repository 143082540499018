import int from "../int";
import IntUtils from "../IntUtils";
import BaseModel from "./BaseModel";

class User {
    constructor(props: {
        email: string,
        firstName: string,
        familyName: string,
        roles?: Array<string>,
        id?: string,
        tel?: string,
    }) {
        this.email = props.email;
        this.firstName = props.firstName;
        this.familyName = props.familyName;
        this.roles = props.roles;
        this.id = props.id;
        this.tel = props.tel;
    }

    email: string
    firstName: string
    familyName: string
    roles?: Array<string>
    id?: string
    tel?: string

    getName(): string {
        return `${this.familyName} ${this.firstName}`;
    }

    isAdmin(): boolean {
        return this.roles!.includes("ROLE_ADMIN") == true;
    }

    isRentalRoleUser(): boolean {
        return this.roles!.includes("ROLE_RENTAL") == true;
    }

    getIdNum(): int | undefined {
        return this.id !== undefined ? BaseModel.getIdNumFromString(this.id) : undefined;
    }

    static getIdNumFromString(id: string): int | undefined {
        return BaseModel.getIdNumFromString(id);
    }
}

export default User;