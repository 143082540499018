import moment from "moment";

class BusinessYear {
    static getCurrent(): number {
        return moment().subtract(3, 'months').year();
    }

    static getFromDate(date: Date): number {
        return moment(date).subtract(3, 'months').year();
    }
}

export default BusinessYear;