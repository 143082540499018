import DateFormatter from "./DateFormatter";
import DateFormatterReadable from "./DateFormatterReadable";
import DateTime from "./DateTime";
import int from "./int";
import Equipment from "./models/Equipment";
import moment from "moment";

class Utils {
    static range(begin: int, end: int): int[] {
        return ([...Array(end - begin)].map((_, i) => (begin + i)))
    }

    static isValidDate(dateString: string | null | undefined): boolean {
        if (dateString === null || dateString === undefined) {
            return false;
        }
        if (dateString === "") {
            return false;
        }

        return moment(dateString).isValid()
    }

    static getEquipmentItemsFromRentalReturnLogs(
        rentalReturnLogs: any
    ): Equipment[] {
        // console.log(rentalReturnLogs);
        const _returnLogs: any[] = rentalReturnLogs?.["edges"];
        var _equipmentItemSet: Equipment[] = [];

        for (const _returnLogRaw of _returnLogs) {
            const _returnLog = _returnLogRaw["node"];
            if (_returnLog?.["equipmentItems"]?.["edges"] !== undefined &&
                _returnLog?.["equipmentItems"]?.["edges"].length > 0) {
                const _equipments: any[] = _returnLog?.["equipmentItems"]?.["edges"];
                // print("returned: $_equipments");

                for (const item of _equipments) {
                    const id: string = item["node"]["id"];
                    const uuid: int = item["node"]["uuid"];
                    const eq = new Equipment({ id: id, uuid: uuid })

                    var okFlag: boolean = true;
                    for (const e of _equipmentItemSet) {
                        if (e.equals(eq)) {
                            okFlag = false
                            break
                        }
                    }

                    if (okFlag) {
                        _equipmentItemSet.push(eq);
                    }
                }
            }
        }

        return _equipmentItemSet;
    }

    static getEquipmentItemsFromRentalReturnLog(
        rentalReturnLog: any
    ): Equipment[] {
        var _equipmentItemSet: Equipment[] = [];

        if (rentalReturnLog?.["edges"] !== undefined &&
            rentalReturnLog?.["edges"].length > 0) {
            const _equipments: any[] = rentalReturnLog?.["edges"];
            // print("returned: $_equipments");

            for (const item of _equipments) {
                const id: string = item["node"]["id"];
                const uuid: int = item["node"]["uuid"];
                const eq = new Equipment({ id: id, uuid: uuid })

                var okFlag: boolean = true;
                for (const e of _equipmentItemSet) {
                    if (e.equals(eq)) {
                        okFlag = false
                        break
                    }
                }

                if (okFlag) {
                    _equipmentItemSet.push(eq);
                }
            }
        }

        return _equipmentItemSet;
    }

    static getFormatDateFromString(dateString: string | undefined): string {
        return dateString !== undefined
            ? DateFormatter.format(new Date(dateString))
            : "";
    }

    static getReadableDateFromString(dateString: string | undefined): string {
        return dateString !== undefined
            ? DateFormatterReadable.format(new Date(dateString))
            : "";
    }

    static getReadableDate(date: DateTime | undefined): string {
        return date !== undefined ? DateFormatterReadable.format(date) : "";
    }
}

export default Utils;