import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import FilterController from "../FilterController";

class EquipmentManagementFilterController implements FilterController {
    constructor() {
        // sortCt.text = "uuid:desc";
    }

    nameCt = createStateContext<string>();
    categoryIdCt = createStateContext<string>();
    sortCt = createStateContext<string>();
    uuidCt = createStateContext<string>();
    numberingCt = createStateContext<string>();
    showAliveCt = createStateContext<boolean>();
    showNotfoundCt = createStateContext<boolean>();
    showRemovedCt = createStateContext<boolean>();
    showReparingCt = createStateContext<boolean>();

    sortMap: Map<string, string> = new Map(Object.entries({
        "機材ID (昇順 ↑ )": "uuid:asc",
        "機材ID (降順 ↓ )": "uuid:desc",
        "機材名 (昇順 ↑ )": "name:asc",
        "機材名 (降順 ↓ )": "name:desc",
        "作成日時 (昇順 ↑ )": "createdAt:asc",
        "作成日時 (降順 ↓ )": "createdAt:desc",
        "更新日時 (昇順 ↑ )": "updatedAt:asc",
        "更新日時 (降順 ↓ )": "updatedAt:desc",
    }));
    ordersMap: Map<string, Array<any>> = new Map(Object.entries({
        "uuid:asc": [
            { "uuid": "asc" }
        ],
        "uuid:desc": [
            { "uuid": "desc" }
        ],
        "name:asc": [
            { "name": "asc" },
        ],
        "name:desc": [
            { "name": "desc" },
        ],
        "updatedAt:asc": [
            { "updatedAt": "asc" }
        ],
        "updatedAt:desc": [
            { "updatedAt": "desc" }
        ],
        "createdAt:asc": [
            { "createdAt": "asc" }
        ],
        "createdAt:desc": [
            { "createdAt": "desc" }
        ],
    }));

    StateInitializer: React.FC<{}> = (props) => {
        return (
            <StateProvider context={this.nameCt} defaultValue={""} >
                <StateProvider context={this.categoryIdCt} defaultValue={""} >
                    <StateProvider context={this.sortCt} defaultValue={"uuid:desc"} >
                        <StateProvider context={this.uuidCt} defaultValue={""} >
                            <StateProvider context={this.numberingCt} defaultValue={""} >
                                <StateProvider context={this.showAliveCt} defaultValue={true} >
                                    <StateProvider context={this.showNotfoundCt} defaultValue={true} >
                                        <StateProvider context={this.showReparingCt} defaultValue={true} >
                                            <StateProvider context={this.showRemovedCt} defaultValue={false} >
                                                {props.children}
                                            </StateProvider>
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }
}

export default EquipmentManagementFilterController;