import { CircularProgress } from "@mui/material";
import React from "react";
import Const from "../Const";

const Loading: React.FC = () => {
    return (
        <div className="Loading">
            <div className="LoadingProgress">
                <CircularProgress />
            </div>
            <div className="LoadingText">
                {Const.loadingText}
            </div>
        </div>);
}

export default Loading;