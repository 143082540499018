import { Box, Button, Divider, Grid, List, ListItemButton, Radio, RadioGroup } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { icons } from "react-icons";
import { useNavigate } from "react-router";
import Api from "../../Api";
import Const from "../../Const";
import { EndDrawerControllerStateContext, LoginStateContext } from "../../contexts";
import int from "../../int";
import User from "../../models/User";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import GroupTab from "../GroupTab";
import Loading from "../Loading";
import UserAddPage from "./UserAddPage";
import UserDetailPage from "./UserDetailPage";
import * as Icons from "@mui/icons-material";
import EquipmentAddPage from "./EquipmentAddPage";
import RentalAndReservationPage from "./RentalAndReservationPage";
import RentalDetailPage from "./RentalDetailPage";
import Rental from "../../models/Rental";
import RentalStatusWidget from "../RentalStatusWidget";
import SizedBox from "../SizedBox";
import RentalStatus from "../../models/RentalStatus";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalReturnButton from "../RentalReturnButton";
import BaseModel from "../../models/BaseModel";
import Utils from "../../Utils";

type UserGroupMap = { "type": string, "name": string, "count": int };

const GroupStateContext = createStateContext<string>();

const EquipmentListView: React.FC<{ items: Array<any> }> = (props) => {
    const nav = useNavigate()
    const groupContext = useContext(GroupStateContext);
    const goEdit = (item: any) => {
        return () => {
            Router.pushNamed(
                Router.getRouteOfPage(RentalDetailPage)!
                    .replace(":id", Rental.getIdNumFromString(item.id!)!.toString()), nav)
        }
    }

    return (
        <div className="EquipmentListContainerForHome">
            <div className="RadioGroupListContainerForHome">
                <div className="RadioGroupListForHome">
                    <RadioGroup
                        aria-label="group"
                        name="group"
                        value={groupContext.state}
                        onChange={(e, value) => { groupContext.setState(value) }}
                    >
                        <List>
                            {
                                props.items.map((item) => {
                                    const rentalStatusStr: string = item.status;
                                    const rentalStatusVal: RentalStatus =
                                        RentalStatusExtension.fromString(rentalStatusStr);

                                    const startDateStr: string = Utils.getReadableDateFromString(item.startDate);
                                    const endDateStr: string = item.endDate ? Utils.getReadableDateFromString(item.endDate) : "";
                                    const endDateIsOver: boolean = item.endDate ? ((new Date()).getTime() - (new Date(item.endDate)).getTime() > 0) : false;
                                    const isOver: boolean = (rentalStatusVal === RentalStatus.Ongoing) && endDateIsOver

                                    return (
                                        <Box className="ListItem" sx={{ display: 'flex' }} key={item.id} onClick={goEdit(item)}>
                                            <ListItemButton>
                                                <RentalStatusWidget status={item.status} />
                                                <SizedBox inline width={5} />
                                                <span>{`${item.title}`}</span>
                                                <SizedBox inline width={5} />
                                                <span style={{ color: "#c2c2c2" }}>{startDateStr} 〜 <span style={isOver ? { color: "red" } : { color: "#c2c2c2" }}>{endDateStr}</span></span>
                                                {
                                                    (isOver)
                                                        ? <Icons.Warning style={{ color: "red", marginRight: "3px" }} />
                                                        : <React.Fragment />

                                                }
                                            </ListItemButton>

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {
                                                    (rentalStatusVal == RentalStatus.Ongoing)
                                                        ? (
                                                            <React.Fragment>
                                                                <RentalReturnButton rentalIdNumber={Rental.getIdNumFromString(item.id!)!} />
                                                                <SizedBox inline width={5} />
                                                            </React.Fragment>
                                                        )
                                                        : <React.Fragment />
                                                }
                                                <Button variant="contained">{Const.editButtonText}</Button>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                            {
                                props.items.length == 0
                                    ? <Box sx={{ color: "gray" }}>( 0件 )</Box>
                                    : <React.Fragment />
                            }
                        </List>
                    </RadioGroup>
                </div>
            </div>
        </div>
    )
}

const HomePageImpl: React.FC = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext)
    const userType = groupContext.state;

    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;

    const query = gql`
      query($userInCharge_id: Int, $status_list: [String] $order: [RentalFilter_order]){
        rentals(userInCharge_id: $userInCharge_id, status_list: $status_list, order: $order) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              _id
              title
              startDate
              endDate
              customerPerson {
                id
                firstName
                familyName
              }
              userInCharge {
                id
                firstName
                familyName
                email
              }
              status
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {
            'userInCharge_id': loginState.user?.getIdNum(),
            'status_list': ["ongoing", "reserved"],
            'order': []
        },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["rentals"]?.["edges"] !== undefined) {
        const items = result.data!["rentals"]["edges"]
            .map(
                (e: any) => e["node"]
            )

        // print(items);
        return <EquipmentListView items={items} />;
    } else {
        return <EquipmentListView items={[]} />;
    }
}

const HomePage: React.FC = (props) => {
    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;
    const user = loginState.user;

    return (
        <React.Fragment>
            <div className="AlignMiddle"><Icons.Person /> {`${user?.familyName} ${user?.firstName} さん`}</div>
            <SizedBox height={5} />
            <Divider />
            <SizedBox height={10} />
            <div className="AlignMiddle">未返却、予約中の貸出 (担当分)</div>
            <SizedBox height={5} />
            <HomePageImpl />
        </React.Fragment>
    );
}

export default HomePage;