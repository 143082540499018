import int from "../int";
import IntUtils from "../IntUtils";
import BaseModel from "./BaseModel";

class CustomerPerson {
    constructor(props: {
        email?: string,
        firstName: string,
        familyName: string,
        id?: string,
        tel?: string,
        groupId?: string,
        groupName?: string,
    }) {
        this.email = props.email;
        this.firstName = props.firstName;
        this.familyName = props.familyName;
        this.id = props.id;
        this.tel = props.tel;
        this.groupId = props.groupId
        this.groupName = props.groupName
    }

    email?: string
    firstName: string
    familyName: string
    roles?: Array<string>
    id?: string
    tel?: string
    groupId?: string
    groupName?: string

    getName(): string {
        return `${this.familyName} ${this.firstName}`;
    }

    getGroupNameReadable(): string {
        return this.groupName !== undefined ? this.groupName! : "個人";
    }

    getIdNum(): int | undefined {
        return this.id !== undefined ? BaseModel.getIdNumFromString(this.id) : undefined;
    }

    static getIdNumFromString(id: string): int | undefined {
        return BaseModel.getIdNumFromString(id);
    }
}

export default CustomerPerson;