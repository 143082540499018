import int from "../int";
import IntUtils from "../IntUtils";

class BaseModel {
    constructor(props: {
        id: string,
    }) {
        this.id = props.id;
    }

    id: string

    getIdNum(): int | undefined {
        if (this.id === undefined) {
            return undefined;
        }
        const _idNumregExp = /^.*\/(\d+)$/;
        const match = this.id!.match(_idNumregExp);
        const id_str: string | undefined = match?.[1];
        return id_str === undefined ? undefined : IntUtils.tryParse(id_str);
    }

    static getIdNumFromString(id: string): int | undefined {
        if (id === undefined) { return undefined }
        const _idNumregExp = /^.*\/(\d+)$/;
        const match = id!.match(_idNumregExp);
        const id_str: string | undefined = match?.[1];
        return id_str === undefined ? undefined : IntUtils.tryParse(id_str);
    }
}

export default BaseModel;