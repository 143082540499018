import BusinessYear from "../BusinessYear";
import int from "../int";

class YearRangeState {
    startYear: int
    endYear: int

    constructor(startYear: int, endYear: int) {
        this.startYear = startYear;
        this.endYear = endYear;
    }

    isInRange(date_or_year: Date | int): boolean {
        if (date_or_year instanceof Date) {
            return this.isInRange(BusinessYear.getFromDate(date_or_year));
        } else {
            const year = date_or_year;
            return year >= this.startYear && year <= this.endYear;
        }
    }
}

export default YearRangeState;