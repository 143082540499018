import { Box, Button, Grid, List, ListItemButton, Radio, RadioGroup } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { icons } from "react-icons";
import { useNavigate } from "react-router";
import Api from "../../Api";
import Const from "../../Const";
import { EndDrawerControllerStateContext, LoginStateContext, YearRangeStateContext } from "../../contexts";
import int from "../../int";
import User from "../../models/User";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import GroupTab from "../GroupTab";
import Loading from "../Loading";
import UserAddPage from "./UserAddPage";
import UserDetailPage from "./UserDetailPage";
import * as Icons from "@mui/icons-material";
import EquipmentAddPage from "./EquipmentAddPage";
import RentalAndReservationPage from "./RentalAndReservationPage";
import RentalDetailPage from "./RentalDetailPage";
import Rental from "../../models/Rental";
import RentalStatusWidget from "../RentalStatusWidget";
import SizedBox from "../SizedBox";
import RentalStatus from "../../models/RentalStatus";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalReturnButton from "../RentalReturnButton";
import BaseModel from "../../models/BaseModel";
import Utils from "../../Utils";
import RentalListFilterController from "../../controllers/filterControllers/RentalListFilterController";

type UserGroupMap = { "type": string, "name": string, "count": int };

const GroupStateContext = createStateContext<string>();

const EquipmentListView: React.FC<{ items: Array<any>, fetchMore: (v: any) => any, totalCount: int, pageInfo: any | undefined }> = (props) => {
    const nav = useNavigate()
    const groupContext = useContext(GroupStateContext);
    const goEdit = (item: any) => {
        return () => {
            Router.pushNamed(
                Router.getRouteOfPage(RentalDetailPage)!
                    .replace(":id", Rental.getIdNumFromString(item.id!)!.toString()), nav)
        }
    }

    const hasNextPage: boolean = props.pageInfo?.hasNextPage

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as RentalListFilterController;
    const showCompletedCt = useContext(filterController.showCompletedCt)
    const filterCompletedByYearCt = useContext(filterController.filterCompletedByYearCt)

    const yearRangeState = useContext(YearRangeStateContext)

    const filteredItems = props.items.filter((item) => {
        if (showCompletedCt.state && filterCompletedByYearCt.state) {
            if (RentalStatusExtension.fromString(item.status) === RentalStatus.Completed) {
                const startDate = item.startDate ? new Date(item.startDate) : undefined
                const endDate = item.endDate ? new Date(item.endDate) : undefined

                if (startDate) {
                    if (yearRangeState.state.instance.isInRange(startDate)) {
                        return true
                    }
                }

                if (endDate) {
                    if (yearRangeState.state.instance.isInRange(endDate)) {
                        return true
                    }
                }

                return false
            } else {
                return true
            }
        } else {
            return true
        }
    })

    return (
        <div className="EquipmentListContainer">
            <div className="RadioGroupListContainer">
                <div className="RadioGroupListBeforeAdd">
                    <RadioGroup
                        aria-label="group"
                        name="group"
                        value={groupContext.state}
                        onChange={(e, value) => { groupContext.setState(value) }}
                    >
                        <List>
                            {
                                filteredItems.map((item) => {
                                    const rentalStatusStr: string = item.status;
                                    const rentalStatusVal: RentalStatus =
                                        RentalStatusExtension.fromString(rentalStatusStr);

                                    const startDateStr: string = Utils.getReadableDateFromString(item.startDate);
                                    const endDateStr: string = item.endDate ? Utils.getReadableDateFromString(item.endDate) : "";
                                    const endDateIsOver: boolean = item.endDate ? ((new Date()).getTime() - (new Date(item.endDate)).getTime() > 0) : false;
                                    const isOver: boolean = (rentalStatusVal === RentalStatus.Ongoing) && endDateIsOver

                                    return (
                                        <Box className="ListItem" sx={{ display: 'flex' }} key={item.id} onClick={goEdit(item)}>
                                            <ListItemButton>
                                                <RentalStatusWidget status={item.status} />
                                                <SizedBox inline width={5} />
                                                <span>{`${item.title}`}</span>
                                                <SizedBox inline width={5} />
                                                <span style={{ color: "#c2c2c2" }}>{startDateStr} 〜 <span style={isOver ? { color: "red" } : { color: "#c2c2c2" }}>{endDateStr}</span></span>
                                                {
                                                    (isOver)
                                                        ? <Icons.Warning style={{ color: "red", marginRight: "3px" }} />
                                                        : <React.Fragment />

                                                }
                                            </ListItemButton>

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {
                                                    (rentalStatusVal == RentalStatus.Ongoing)
                                                        ? (
                                                            <React.Fragment>
                                                                <RentalReturnButton rentalIdNumber={Rental.getIdNumFromString(item.id!)!} />
                                                                <SizedBox inline width={5} />
                                                            </React.Fragment>
                                                        )
                                                        : <React.Fragment />
                                                }
                                                <Button variant="contained">{Const.editButtonText}</Button>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                            {
                                filteredItems.length == 0
                                    ? <Box sx={{ color: "gray" }}>( 0件 )</Box>
                                    : (
                                        hasNextPage
                                            ? (
                                                <Box
                                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    onClick={async () => {
                                                        await props.fetchMore({
                                                            variables: {
                                                                'cursor': props.pageInfo?.endCursor
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Button>さらに表示</Button>
                                                </Box>
                                            )
                                            :
                                            <React.Fragment></React.Fragment>
                                    )
                            }
                        </List>
                    </RadioGroup>
                </div>
                <div className="RadioGroupAddContainer">
                    <div className="RadioGroupAdd">
                        <div onClick={() => {
                            var s = Router.getRouteOfPage(RentalAndReservationPage)!;
                            Router.pushNamed(s, nav);
                        }}>
                            <Button variant="contained">
                                <Icons.Add />
                                <span>貸出を追加</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RentalListPage: React.FC = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext)
    const userType = groupContext.state;

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as RentalListFilterController;
    const titleCt = useContext(filterController.titleCt);
    const userInChargeCt = useContext(filterController.userInChargeCt);
    const sortCt = useContext(filterController.sortCt);

    const showCompletedCt = useContext(filterController.showCompletedCt)
    const filterCompletedByYearCt = useContext(filterController.filterCompletedByYearCt)
    const showOngoingCt = useContext(filterController.showOngoingCt)
    const showReservedCt = useContext(filterController.showReservedCt)
    const showVacantCt = useContext(filterController.showVacantCt)

    const sortStr = sortCt.state;
    const orders: Array<any> | undefined = filterController.ordersMap.get(sortStr);
    var _status_list: RentalStatus[] = []
    if (showCompletedCt.state) {
        _status_list.push(RentalStatus.Completed)
    }
    if (showOngoingCt.state) {
        _status_list.push(RentalStatus.Ongoing)
    }
    if (showReservedCt.state) {
        _status_list.push(RentalStatus.Reserved)
    }
    if (showVacantCt.state) {
        _status_list.push(RentalStatus.Vacant)
    }

    const status_list = _status_list.map((e) => e.toString())

    const query = gql`
      query($cursor: String, $title: String, $userInCharge_id: Int, $status_list: [String], $order: [RentalFilter_order]){
        rentals(first: 50, after: $cursor, title: $title, userInCharge_id: $userInCharge_id, status_list: $status_list, order: $order) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              _id
              title
              startDate
              endDate
              customerPerson {
                id
              }
              userInCharge {
                id
              }
              status
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {
            'title': titleCt.state !== "" ? titleCt.state : undefined,
            'userInCharge_id': userInChargeCt.state !== "" ? BaseModel.getIdNumFromString(userInChargeCt.state) : undefined,
            'order': orders,
            'status_list': status_list
        },
        // fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["rentals"]?.["edges"] !== undefined) {
        const totalCount: int = result.data?.["rentals"]?.["totalCount"] ?? 0;
        const pageInfo: any | undefined =
            result.data?.["rentals"]?.["pageInfo"];

        const items = result.data!["rentals"]["edges"]
            .map(
                (e: any) => e["node"]
            )

        // print(items);
        return <EquipmentListView items={items} fetchMore={result.fetchMore} totalCount={totalCount} pageInfo={pageInfo} />;
    } else {
        return <EquipmentListView items={[]} fetchMore={result.fetchMore} totalCount={0} pageInfo={undefined} />;
    }
}

export default RentalListPage;