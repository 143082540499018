import { Box, Button, Grid, List, ListItemButton, Radio, RadioGroup } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { icons } from "react-icons";
import { useNavigate } from "react-router";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import User from "../../models/User";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import GroupTab from "../GroupTab";
import Loading from "../Loading";
import UserAddPage from "./UserAddPage";
import UserDetailPage from "./UserDetailPage";
import * as Icons from "@mui/icons-material";
import EquipmentAddPage from "./EquipmentAddPage";
import EquipmentDetailPage from "./EquipmentDetailPage";
import Equipment from "../../models/Equipment";
import EquipmentCategoryAddPage from "./EquipmentCategoryAddPage";
import BaseModel from "../../models/BaseModel";
import EquipmentCategoryDetailPage from "./EquipmentCategoryDetailPage";
import { IconPickerItem } from "react-fa-icon-picker";
import IconUtils from "../../IconUtils";
import IconFa from "../IconFa";
import SizedBox from "../SizedBox";

type UserGroupMap = { "type": string, "name": string, "count": int };

const GroupStateContext = createStateContext<string>();

const EquipmentListView: React.FC<{ items: Array<any> }> = (props) => {
    const nav = useNavigate()
    const groupContext = useContext(GroupStateContext);
    const goEdit = (item: any) => {
        return () => {
            Router.pushNamed(
                Router.getRouteOfPage(EquipmentCategoryDetailPage)
                    .replace(":id", BaseModel.getIdNumFromString(item.id!)!.toString()), nav)
        }
    }

    return (
        <div className="EquipmentListContainer">
            <div className="RadioGroupListContainer">
                <div className="RadioGroupListBeforeAdd">
                    <RadioGroup
                        aria-label="group"
                        name="group"
                        value={groupContext.state}
                        onChange={(e, value) => { groupContext.setState(value) }}
                    >
                        <List>
                            {
                                props.items.map((item) => {
                                    const count = item?.equipmentItems?.totalCount ?? 0;
                                    return (
                                        <Box className="ListItem" sx={{ display: 'flex' }} key={item.id} onClick={goEdit(item)}>
                                            <ListItemButton>
                                                <IconFa icon={item.iconName} />
                                                <SizedBox width={5} />
                                                {`${item.name} (${count})`}
                                            </ListItemButton>

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Button variant="contained">{Const.editButtonText}</Button>
                                            </Box>
                                        </Box>
                                    )
                                }
                                )
                            }
                            {
                                props.items.length == 0
                                    ? <Box sx={{ color: "gray" }}>( 0件 )</Box>
                                    : <React.Fragment />
                            }
                        </List>
                    </RadioGroup>
                </div>
                <div className="RadioGroupAddContainer">
                    <div className="RadioGroupAdd">
                        <div onClick={() => {
                            var s = Router.getRouteOfPage(EquipmentCategoryAddPage);
                            Router.pushNamed(s, nav);
                        }}>
                            <Button variant="contained">
                                <Icons.Add />
                                <span>カテゴリを追加</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EquipmentCategoryManagementPage: React.FC = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext)
    const userType = groupContext.state;

    const query = gql`
      {
        equipmentCategories{
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentCategories"]?.["edges"] !== undefined) {
        const items = result.data!["equipmentCategories"]["edges"]
            .map(
                (e: any) => e["node"]
            )

        // print(items);
        return <EquipmentListView items={items} />;
    } else {
        return <EquipmentListView items={[]} />;
    }
}

export default EquipmentCategoryManagementPage;