import React, { useContext, useEffect } from 'react';
import Equipment from '../models/Equipment';
import RentalReturnLog from '../models/RentalReturnLog';
import * as Icons from "@mui/icons-material";
import { Button, Checkbox, List, ListItem } from '@mui/material';
import SizedBox from './SizedBox';
import StateOf from '../states/StateOf';
import StateContextProp from '../states/StateContextProp';
import { CartContext } from '../contexts';
import { Link } from 'react-router-dom';
import Router from '../Router';
import EquipmentDetailPage from './pages/EquipmentDetailPage';
import { Box } from '@mui/system';
import Const from '../Const';
import IconFa from './IconFa';
import int from '../int';

type StateOfArrayEquipment = StateOf<Array<Equipment>>
type ContextOfArrayEquipment = StateContextProp<StateOfArrayEquipment>

const _ItemsList: React.FC<{ equipmentItems: Equipment[], rentalEquipmentItems: Equipment[], rentalEquipmentItemsContext: ContextOfArrayEquipment, rentalReturnLogs: RentalReturnLog[] }> = (props) => {

    const equipmentItems = props.equipmentItems
    const rentalEquipmentItems = props.rentalEquipmentItems
    const rentalEquipmentItemsContext = props.rentalEquipmentItemsContext
    const rentalReturnLogs = props.rentalReturnLogs

    return (
        <List className="CartItemsList">
            {
                equipmentItems.map((e) => {
                    // console.log(rentalReturnLogs)

                    const isReturned: boolean = e.isReturedInLogs(rentalReturnLogs);
                    const log: RentalReturnLog | undefined = e.getRentalReturnLog(rentalReturnLogs);

                    console.log(rentalEquipmentItems)

                    return (
                        <ListItem key={e.id}>
                            {
                                (isReturned) ?
                                    <div style={{ display: "flex", justifyContent: "center", width: "42px" }}>
                                        <Checkbox disabled />
                                    </div>
                                    : (
                                        <div onClick={() => {
                                            const newRentalItems = (rentalEquipmentItems.includes(e))
                                                ? rentalEquipmentItems.filter((_e) => _e.id !== e.id)
                                                : [...rentalEquipmentItems, e]
                                            rentalEquipmentItemsContext.setState(new StateOf(newRentalItems))
                                        }}>
                                            <Checkbox checked={
                                                rentalEquipmentItems.includes(e)
                                            } />
                                        </div>
                                    )
                            }
                            <Link className="EquipmentViewReadableName" to={
                                Router.getRouteOfPage(EquipmentDetailPage)
                                    .replace(":id", Equipment.getIdNumFromString(e.id!)!.toString())
                            }
                            >
                                <IconFa icon={e?.category?.iconName} />
                                <SizedBox inline width={3} />
                                {e.getReadableName()}
                            </Link >
                            {
                                (isReturned)
                                    ? (
                                        <React.Fragment>
                                            <SizedBox inline width={5} />
                                            <Box className="EquipmentsViewReturned">
                                                <Icons.DoneOutline />
                                                <SizedBox inline width={3} />
                                                <span className="EquipmentsViewReturnedContent">{`${Const.rentalReturnedText} ( ${log?.getReturnedDateReadable()}, 受取人: ${log?.getUserName()} )`}</span>
                                                {
                                                    log && log.returnNote !== null && log.returnNote !== "" ?
                                                        <span>&nbsp;(メモ: { `${log.returnNote}` })</span>
                                                        : <React.Fragment />
                                                }
                                            </Box>
                                        </React.Fragment>
                                    )
                                    : <React.Fragment></React.Fragment>
                            }
                        </ListItem>
                    )
                })
            }
            {
                (equipmentItems.length === 0)
                    ? <ListItem key={"_"}>
                        なし
                    </ListItem>
                    : <React.Fragment></React.Fragment>
            }
        </List >
    )
}

const RentalReturnPageEquipmentsViewImpl: React.FC<{ equipmentItems: Equipment[], rentalEquipmentItems: Equipment[], rentalEquipmentItemsContext: ContextOfArrayEquipment, rentalReturnLogs: RentalReturnLog[] }> = (props) => {

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const equipmentItems = props.equipmentItems
    const rentalEquipmentItemsContext = props.rentalEquipmentItemsContext
    const rentalEquipmentItems = props.rentalEquipmentItems

    const totalCount: int = equipmentItems.length -
        RentalReturnLog.getReturnedCount(props.rentalReturnLogs);

    return (
        <div className="EquipmentsView">
            <div className="RentalEquipmentsViewHeader">
                <Icons.CallMissedOutgoing />
                <SizedBox inline width={3} />
                <span>{`返却したい機材を選択してください (${rentalEquipmentItems.length}/${totalCount})`}</span>
            </div>
            <div className="EquipmentsViewContent">
                <_ItemsList equipmentItems={equipmentItems} rentalEquipmentItems={rentalEquipmentItems} rentalEquipmentItemsContext={rentalEquipmentItemsContext} rentalReturnLogs={props.rentalReturnLogs} />
            </div>
        </div>
    )
}

const RentalReturnPageEquipmentsView: React.FC<{ rentalEquipmentItemsContext: ContextOfArrayEquipment, equipmentItems: Equipment[], rentalReturnLogs: RentalReturnLog[] }> = (props) => {
    const equipmentItems = props.equipmentItems;
    const rentalEquipmentItems = props.rentalEquipmentItemsContext.state.instance

    return (
        <RentalReturnPageEquipmentsViewImpl
            equipmentItems={equipmentItems}
            rentalEquipmentItems={rentalEquipmentItems}
            rentalEquipmentItemsContext={props.rentalEquipmentItemsContext}
            rentalReturnLogs={props.rentalReturnLogs}
        />
    );
}

export default RentalReturnPageEquipmentsView;