import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import IconFa from "../IconFa";
import DeleteButton from "../DeleteButton";
import RentalStatus from "../../models/RentalStatus";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalStatusWidget from "../RentalStatusWidget";
import DateFormatter from "../../DateFormatter";
import Utils from "../../Utils";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import RentalListPage from "./RentalListPage";
import RentalDetailPageEquipmentsView from "../RentalDetailPageEquipmentsView";
import RentalReturnLog from "../../models/RentalReturnLog";
import Equipment from "../../models/Equipment";
import EquipmentCategory from "../../models/EquipmentCategory";
import StateOf from "../../states/StateOf";
import RentalReturnButton from "../RentalReturnButton";
import CustomerManagementPage from "./CustomerManagementPage";

type StateOfArrayEquipment = StateOf<Array<Equipment>>

const UpdatedStateContext = createStateContext<UpdateState>();

const RentalStatusStateContext = createStateContext<string>();
const EquipmentListStateContext = createStateContext<StateOfArrayEquipment>();

const TitleStateContext = createStateContext<string>();
const StartDateStateContext = createStateContext<string>();
const EndDateStateContext = createStateContext<string>();
const CustomerGroupStateContext = createStateContext<string>();
const CustomerPersonStateContext = createStateContext<string>();
const UserStateContext = createStateContext<string>();

var id_num: int | undefined;
var id: string | undefined;

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;
type UserGroupMap = { "type": string, "name": string, "count": int };

const _CustemorPersonDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const customerPersonContext = useContext(CustomerPersonStateContext)

    return (
        <SelectValidator
            className="Required"
            value={customerPersonContext.state === null ? "" : customerPersonContext.state}
            label={Const.rentalCustomerPersonLabelText}
            name="customer-group"
            onChange={(e: any) => { customerPersonContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={""}>{""}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.familyName} {e.firstName}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CustemorPersonDropDown: React.FC = (props) => {
    const nav = useNavigate()

    const customerGroupId = useContext(CustomerGroupStateContext).state

    const query = gql`
      query($id: ID!){
        customerGroup(id: $id) {
          persons {
            edges {
              node {
                id
                _id
                firstName
                familyName
                email
              }
            }
          }
        }
      } 
    `
    const result = useQuery(query, {
        variables: {
            'id': customerGroupId
        }
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = (result.data?.["customerGroup"]?.["persons"]?.["edges"] ?? [])
            .map((e: any) => e["node"]);

        return <_CustemorPersonDropDownImpl items={items} />
    } else {
        return <_CustemorPersonDropDownImpl items={[]} />
    }
}

const _UserDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const userContext = useContext(UserStateContext)

    return (
        <SelectValidator
            className="Required"
            value={userContext.state === null ? "" : userContext.state}
            label={"TEMDEC側 担当者"}
            name="customer-group"
            onChange={(e: any) => { userContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={""}>{""}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.familyName} {e.firstName}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _UserDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        users {
          edges {
            node {
              id
              _id
              firstName
              familyName
              email
            }
          }
        }
      } 
    `
    const result = useQuery(query, {
        variables: {}
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = result.data!["users"]["edges"]
            .map((e: any) => e["node"]);

        return <_UserDropDownImpl items={items} />
    } else {
        return <_UserDropDownImpl items={[]} />
    }
}

const _CustomerGroupDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const groupStateContext = useContext(CustomerGroupStateContext)

    return (
        <SelectValidator
            className="Required"
            value={groupStateContext.state === null ? "_" : groupStateContext.state}
            label={Const.rentalCustomerGroupLabelText}
            name="customer-group"
            onChange={(e: any) => { groupStateContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={"_"}>{Const.customerPersonNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CustomerGroupDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        customerGroups{
          edges {
            node {
              id
              _id
              name
              persons {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {}
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = result.data!["customerGroups"]["edges"]
            .map((e: any) => e["node"]);

        return <_CustomerGroupDropDownImpl items={items} />
    } else {
        return <_CustomerGroupDropDownImpl items={[]} />
    }
}

const _StatusDropDownImpl: React.FC<{ items: Array<RentalStatus> }> = (props) => {

    const rentalStatusStateContext = useContext(RentalStatusStateContext)

    return (
        <SelectValidator
            className="Required"
            value={rentalStatusStateContext.state}
            label={"状態"}
            name="rental_status"
            onChange={(e: any) => { rentalStatusStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            {
                props.items
                    .filter((e) => {
                        if (rentalStatusStateContext.state === RentalStatusExtension.toShortString((RentalStatus.Completed))) {
                            return e === RentalStatus.Completed
                        } else {
                            return e !== RentalStatus.Completed
                        }
                    })
                    .map((e) => {
                        const value = RentalStatusExtension.toShortString(e)
                        return (
                            <MenuItem className="MenuItemWithIcon" key={value} value={value}>
                                <RentalStatusWidget status={e} />
                            </MenuItem>
                        )
                    })
            }
        </SelectValidator>
    )
}

const _StatusDropDown: React.FC = (props) => {
    const nav = useNavigate()

    const items = [RentalStatus.Ongoing, RentalStatus.Reserved, RentalStatus.Completed]
    return <_StatusDropDownImpl items={items} />
}

const _FormImpl: React.FC<{ refetch: RefetchType, equipmentItems: Equipment[], rentalReturnLogs: RentalReturnLog[], rentalStatusVal: RentalStatus | undefined }> = (props) => {
    const refetch = props.refetch;
    const nav = useNavigate()

    const formRef = useRef(null);

    const titleContext = useContext(TitleStateContext);
    const startDateContext = useContext(StartDateStateContext);
    const endDateContext = useContext(EndDateStateContext);
    const customerGroupContext = useContext(CustomerGroupStateContext);
    const customerPersonContext = useContext(CustomerPersonStateContext);
    const userContext = useContext(UserStateContext);

    const updatedContext = useContext(UpdatedStateContext);
    const rentalStatusContext = useContext(RentalStatusStateContext);

    const equipmentItems = props.equipmentItems;
    const rentalReturnLogs = props.rentalReturnLogs;
    const rentalStatusVal = props.rentalStatusVal;

    const equipmentListStateContext = useContext(EquipmentListStateContext);

    return (

        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                const title = titleContext.state;
                const startDate = startDateContext.state;
                const endDate = endDateContext.state;
                const customerGroup = customerGroupContext.state;
                const customerPerson = customerPersonContext.state;
                const user = userContext.state;
                const rentalStatus = rentalStatusContext.state;
                const equipmentItems = equipmentListStateContext.state.instance;

                const queryStr = `
                  mutation($id: ID!, $title: String!, $status: String!, $customerPerson: String!, $userInCharge: String!, $startDate: String!, $endDate: String, $equipmentItems: [String]) {
                    updateRental(input: {id: $id, title: $title, status: $status, customerPerson: $customerPerson, userInCharge: $userInCharge, startDate: $startDate, endDate: $endDate, equipmentItems: $equipmentItems}) {
                      rental {
                        id
                        title
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'id': id,
                            'title': title !== "" ? title : undefined,
                            'startDate': startDate !== "" ? startDate : undefined,
                            'endDate': endDate !== "" ? endDate : null,
                            'customerPerson': customerPerson !== "" ? customerPerson : undefined,
                            'userInCharge': user !== "" ? user : undefined,
                            'equipmentItems': equipmentItems
                                .map((e) => e.id),
                            'status': rentalStatus
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["updateRental"] != undefined &&
                    result?.data?.["updateRental"]?.["rental"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    await refetch();
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(RentalListPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >
            <RentalDetailPageEquipmentsView
                equipmentItemsContext={equipmentListStateContext}
                rentalReturnLogs={rentalReturnLogs}
                rentalStatus={rentalStatusVal}
            />

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={Const.rentalTitleLabelText}
                onChange={(e: any) => titleContext.setState(e.target.value)}
                name="title"
                value={titleContext.state}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <_StatusDropDown />

            <SizedBox height={10} />

            {
                (rentalStatusVal == RentalStatus.Ongoing &&
                    id_num !== undefined)
                    ? <React.Fragment>
                        <RentalReturnButton longVersion rentalIdNumber={id_num} />
                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className="RequiredDatePickerContainer">
                        <DatePicker
                            className="Required"
                            label={Const.rentalStartDateLabelText}
                            value={startDateContext.state}
                            onChange={(v: any) => {
                                if (v !== null) {
                                    const s = Utils.getFormatDateFromString(v)
                                    startDateContext.setState(s)
                                }
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <DatePicker
                        className="Required"
                        label={Const.rentalEndDateLabelText}
                        value={endDateContext.state !== "" ? endDateContext.state : null}
                        onChange={(v: any) => {
                            if (v !== null) {
                                const s = Utils.getFormatDateFromString(v)
                                endDateContext.setState(s)
                            } else {
                                endDateContext.setState("")
                            }
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <_CustomerGroupDropDown />
                </Grid>
                <Grid item xs={6}>
                    <_CustemorPersonDropDown />
                </Grid>
            </Grid>

            <SizedBox height={3} />
            <Link to={Router.getRouteOfPage(CustomerManagementPage)!}>
                {"貸出先の管理"}
            </Link>

            <SizedBox height={10} />

            <_UserDropDown />

            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
                <SizedBox inline width={5} />
                {/* <DeleteButton deletionCallback={async () => {
                    const queryStr = `
                      mutation($id: ID!) {
                        deleteRental(input: {id: $id}) {
                          rental {
                            id
                          }
                          clientMutationId
                        }
                      }
                    `

                    const query = gql(queryStr)

                    var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                    try {
                        result = await Api.graphQLClient?.mutate({
                            mutation: query,
                            variables: {
                                'id': id
                            }
                        })
                    } catch (e) {
                        Api.handleGraphQLException(e, nav)
                    }

                    if (result?.data != undefined &&
                        result?.data?.["deleteRental"] != undefined &&
                        result?.data?.["deleteRental"]?.["rental"] !=
                        undefined) {

                        updatedContext.setState(UpdateState.Succeeeded)

                        await refetch();
                        Api.graphQLResetCache()

                        Router.popAndPushNamed(Router.getRouteOfPage(RentalListPage)!, nav)
                    } else {
                        updatedContext.setState(UpdateState.Failed)
                        console.error("Update failed");
                        console.error(result);
                    }
                }} /> */}
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const query = gql`
      query($id: ID!){
        rental(id: $id) {
          id
          _id
          title
          startDate
          endDate
          customerPerson {
            id
            customerGroup {
              id
            }
          }
          userInCharge {
            id
          }
          rentalReturnLogs {
            edges {
              node {
                id
                returnedAt
                note
                userWhoReceived {
                  email
                  firstName
                  familyName
                }
                equipmentItems {
                  edges {
                    node {
                      id
                      uuid
                    }
                  }
                }
              }
            }
          }
          status
          equipmentItems {
            edges {
              node {
                id
                name
                uuid
                numbering
                category {
                  id
                  name
                  iconName
                }
              }
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: { "id": id },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.error) {
        console.log(result.error);
    }

    if (result.loading) {
        return <Loading />
    }

    // print(result);

    if (result.data !== undefined && result.data?.["rental"] != undefined) {
        // print(result.data?["user"]);
        // return Center(child: Flexible(child: Text("$result")));
        const data = result.data;
        const item = data["rental"]

        const title = item["title"];
        const startDate = item["startDate"] !== null ? Utils.getFormatDateFromString(item["startDate"]) : "";
        const endDate = item["endDate"] !== null ? Utils.getFormatDateFromString(item["endDate"]) : "";
        const customerGroup = item?.customerPerson?.customerGroup?.id ?? "";
        const customerPerson = item?.customerPerson?.id ?? "";
        const user = item?.userInCharge?.id;

        const rentalStatus = item["status"];
        const rentalStatusVal = rentalStatus !== undefined
            ? RentalStatusExtension.fromString(rentalStatus!) : undefined;

        const rental = item;

        var rentalReturnLogs: RentalReturnLog[] = [];
        var equipmentItems: Equipment[] = [];

        // console.log(rental?.["rentalReturnLogs"]?.["edges"])

        if (rental?.["rentalReturnLogs"]?.["edges"] !== undefined &&
            rental?.["rentalReturnLogs"]?.["edges"].length > 0) {

            rentalReturnLogs = RentalReturnLog.createListFromGraphQLResult(
                rental?.["rentalReturnLogs"]);
        }

        if (rental?.["equipmentItems"]?.["edges"] !== undefined &&
            rental?.["equipmentItems"]?.["edges"].length > 0) {
            const _equipments = rental?.["equipmentItems"]?.["edges"];
            // print(_equipments);

            equipmentItems = [];

            for (const item of _equipments) {
                const id: string = item["node"]["id"];
                const uuid: int = item["node"]["uuid"];
                const name: string = item["node"]["name"];
                const numbering: int = item["node"]["numbering"];

                var category_id: string | undefined;
                var category_name: string | undefined;
                var category_icon_name: string | undefined;

                if (item?.["node"]?.["category"] !== undefined) {
                    const category = item["node"]["category"]
                    category_id = category?.["id"];
                    category_name = category?.["name"];
                    category_icon_name = category?.["iconName"];
                }

                const equipment = new Equipment({
                    id: id,
                    name: name,
                    uuid: uuid,
                    numbering: numbering.toString(),
                    category: category_id !== undefined
                        ? new EquipmentCategory({
                            id: category_id,
                            name: category_name!,
                            iconName: category_icon_name!
                        })
                        : undefined
                });

                equipmentItems.push(equipment);
            }
        }

        return (
            <StateProvider context={TitleStateContext} defaultValue={title}>
                <StateProvider context={StartDateStateContext} defaultValue={startDate}>
                    <StateProvider context={EndDateStateContext} defaultValue={endDate}>
                        <StateProvider context={CustomerGroupStateContext} defaultValue={customerGroup}>
                            <StateProvider context={CustomerPersonStateContext} defaultValue={customerPerson}>
                                <StateProvider context={UserStateContext} defaultValue={user}>
                                    <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                        <StateProvider context={RentalStatusStateContext} defaultValue={rentalStatus}>
                                            <StateProvider context={EquipmentListStateContext} defaultValue={new StateOf(equipmentItems)}>
                                                <_FormImpl refetch={result.refetch} equipmentItems={equipmentItems} rentalReturnLogs={rentalReturnLogs} rentalStatusVal={rentalStatusVal} />
                                            </StateProvider>
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>指定された貸出が見つかりませんでした</Center>
}

const RentalDetailPage: React.FC = (props) => {
    const params = useParams();
    id_num = parseInt(params.id!)
    id = `/rentals/${id_num}`;
    return <_Form />
}

export default RentalDetailPage;