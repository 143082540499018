import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import DeleteButton from "../DeleteButton";

const UpdatedStateContext = createStateContext<UpdateState>();
const GroupStateContext = createStateContext<string>();
const EmailStateContext = createStateContext<string>();
const PasswordStateContext = createStateContext<string>();
const FirstNameStateContext = createStateContext<string>();
const FamilyNameStateContext = createStateContext<string>();
const TelStateContext = createStateContext<string>();

var id_num: int | undefined;
var id: string | undefined;

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;
type UserGroupMap = { "type": string, "name": string, "count": int };

const _UserGroupDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const groupStateContext = useContext(GroupStateContext)

    return (
        <SelectValidator
            className="Required"
            value={groupStateContext.state}
            label={Const.userGroupDropDownMenuLabelText}
            name="user-group"
            onChange={(e: any) => { groupStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.type} value={e.type}>{e.name}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _UserGroupDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        users {
          edges {
            node {
              id
              _id
              roles
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["users"]?.["edges"] !== undefined) {
        const users: Array<any> = result.data!["users"]["edges"];

        const adminUsersCount: int = users
            .filter((e) => e["node"]["roles"].includes("ROLE_ADMIN"))
            .length;
        const normalUsersCount: int = users
            .filter((e) => !e["node"]["roles"].includes("ROLE_ADMIN"))
            .filter((e) => e["node"]["roles"].includes("ROLE_RENTAL"))
            .length;
        const guestUsersCount: int = users
            .filter((e) => !e["node"]["roles"].includes("ROLE_ADMIN"))
            .filter((e) => !e["node"]["roles"].includes("ROLE_RENTAL"))
            .length;

        const items: Array<UserGroupMap> = [
            { "type": "ADMIN", "name": "管理者", "count": adminUsersCount },
            { "type": "USER", "name": "一般ユーザ", "count": normalUsersCount },
            { "type": "GUEST", "name": "ゲストユーザ", "count": guestUsersCount },
        ];

        return <_UserGroupDropDownImpl items={items} />
    } else {
        return <_UserGroupDropDownImpl items={[]} />
    }
}

const _FormImpl: React.FC<{ refetch: RefetchType }> = (props) => {
    const refetch = props.refetch;
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const emailContext = useContext(EmailStateContext);
    const passwordContext = useContext(PasswordStateContext);
    const firstNameContext = useContext(FirstNameStateContext);
    const familyNameContext = useContext(FamilyNameStateContext);
    const telContext = useContext(TelStateContext);
    const updatedContext = useContext(UpdatedStateContext);
    const groupContext = useContext(GroupStateContext)

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                var email = emailContext.state;
                var password = passwordContext.state;
                var firstName = firstNameContext.state;
                var familyName = familyNameContext.state;
                var userType = groupContext.state;
                var tel = telContext.state;

                const res = await Api.postJson("encode_password", {
                    "password": password
                })

                const encode_password = res?.data !== undefined ? res?.data?.encoded_password : undefined

                const queryStr = `
                mutation($id: ID!, $familyName: String!, $firstName: String!, $email: String!, $tel: String, $roles: Iterable! <<PASSWORD_PARAM1>>) {
                    updateUser(input: {id: $id, familyName: $familyName, firstName: $firstName, email: $email, tel: $tel, roles: $roles <<PASSWORD_PARAM2>>}) {
                      user {
                        id
                      }
                      clientMutationId
                    }
                  }
                `
                    .replaceAll(
                        "<<PASSWORD_PARAM1>>",
                        password !== ""
                            ? "\$password: String"
                            : "",
                    )
                    .replaceAll(
                        "<<PASSWORD_PARAM2>>",
                        password !== ""
                            ? "password: \$password"
                            : "",
                    );

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'id': id,
                            'familyName': familyName,
                            'firstName': firstName,
                            'email': email == ""
                                ? undefined
                                : email,
                            'tel': tel == ""
                                ? undefined
                                : tel,
                            'roles': userType === "ADMIN"
                                ? ["ROLE_ADMIN", "ROLE_USER", "ROLE_RENTAL"]
                                : userType === "USER"
                                    ? ["ROLE_USER", "ROLE_RENTAL"]
                                    : ["ROLE_USER"],
                            'password': encode_password
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["updateUser"] != undefined &&
                    result?.data?.["updateUser"]?.["user"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    await refetch();
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(UserManagementPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >

            <_UserGroupDropDown />

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.familyNameLabelText}
                        onChange={(e: any) => familyNameContext.setState(e.target.value)}
                        name="familyName"
                        value={familyNameContext.state}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.firstNameLabelText}
                        onChange={(e: any) => firstNameContext.setState(e.target.value)}
                        name="firstName"
                        value={firstNameContext.state}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label="ID"
                onChange={(e: any) => emailContext.setState(e.target.value)}
                name="email"
                value={emailContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icons.PermIdentity />
                        </InputAdornment>
                    ),
                }}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <TextValidator
                // className="Required"
                label={Const.userNewPasswordLabelText}
                onChange={(e: any) => passwordContext.setState(e.target.value)}
                type="password"
                name="password"
                value={passwordContext.state}
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">
                //             <Icons.VpnKey />
                //         </InputAdornment>
                //     ),
                // }}
                validators={[]}
                errorMessages={[]}
            />
            <SizedBox height={10} />
            <TextValidator
                label={Const.telLabelText}
                onChange={(e: any) => telContext.setState(e.target.value)}
                name="tel"
                value={telContext.state}
            />
            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
                <SizedBox inline width={5} />
                <DeleteButton deletionCallback={async () => {
                    const queryStr = `
                      mutation($id: ID!) {
                        deleteUser(input: {id: $id}) {
                          user {
                            id
                          }
                          clientMutationId
                        }
                      }
                    `

                    const query = gql(queryStr)

                    var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                    try {
                        result = await Api.graphQLClient?.mutate({
                            mutation: query,
                            variables: {
                                'id': id
                            }
                        })
                    } catch (e) {
                        Api.handleGraphQLException(e, nav)
                    }

                    if (result?.data != undefined &&
                        result?.data?.["deleteUser"] != undefined &&
                        result?.data?.["deleteUser"]?.["user"] !=
                        undefined) {

                        updatedContext.setState(UpdateState.Succeeeded)

                        await refetch();
                        Api.graphQLResetCache()

                        Router.popAndPushNamed(Router.getRouteOfPage(UserManagementPage)!, nav)
                    } else {
                        updatedContext.setState(UpdateState.Failed)
                        console.error("Update failed");
                        console.error(result);
                    }
                }} />
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const query = gql`
      query($id: ID!){
        user(id: $id) {
          familyName
          firstName
          email
          tel
          roles
        }
      }
    `

    const result = useQuery(query, {
        variables: { "id": id },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.error) {
        console.log(result.error);
    }

    if (result.loading) {
        return <Loading />
    }

    // print(result);

    if (result.data !== undefined && result.data?.["user"] != undefined) {
        // print(result.data?["user"]);
        // return Center(child: Flexible(child: Text("$result")));
        const data = result.data;

        const familyName = data["user"]["familyName"];
        const firstName = data["user"]["firstName"];
        const email = data["user"]["email"] ?? "";
        const tel = data["user"]["tel"] ?? "";
        const group = data["user"]["roles"].includes("ROLE_ADMIN")
            ? "ADMIN"
            : data["user"]["roles"].includes("ROLE_RENTAL")
                ? "USER"
                : "GUEST";

        return (
            <StateProvider context={FamilyNameStateContext} defaultValue={familyName}>
                <StateProvider context={FirstNameStateContext} defaultValue={firstName}>
                    <StateProvider context={EmailStateContext} defaultValue={email}>
                        <StateProvider context={TelStateContext} defaultValue={tel}>
                            <StateProvider context={PasswordStateContext} defaultValue={""}>
                                <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                    <StateProvider context={GroupStateContext} defaultValue={group}>
                                        <_FormImpl refetch={result.refetch} />
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>指定されたユーザが見つかりませんでした</Center>
}

const UserDetailPage: React.FC = (props) => {
    const params = useParams();
    id_num = parseInt(params.id!)
    id = `/users/${id_num}`;
    return <_Form />
}

export default UserDetailPage;