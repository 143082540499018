import React from "react";
import UpdateState from "../states/UpdateState";
import * as Icons from "@mui/icons-material";
import Const from "../Const";

type ReasonHandler = (reason?: any) => string;

const UpdatedLabel: React.FC<{ state: UpdateState, failureMessage?: string, reason?: string, reasonHandler?: ReasonHandler }> = (props) => {
    if (props.state == UpdateState.Succeeeded) {
        return <div className="UpdatedLabel UpdatedLabelSucceeded">
            <Icons.CheckCircle style={{ color: "green" }} />
            <span className="UpdatedLabelText" style={{ color: "green" }}>
                {Const.updatedAndSavedLabelText}
            </span>
        </div>;
    } else if (props.state == UpdateState.Failed) {
        if (props.failureMessage != null || props.reasonHandler != null) {
            const failureMessage =
                props.failureMessage ?? props.reasonHandler!(props.reason);
            return <div className="UpdatedLabel UpdatedLabelFailed">
                <Icons.HighlightOff style={{ color: "red" }} />
                <span className="UpdatedLabelText" style={{ color: "red" }}>
                    {failureMessage}
                </span>
            </div>;
        } else {
            return <div className="UpdatedLabel UpdatedLabelFailed">
                <Icons.HighlightOff style={{ color: "red" }} />
                <span className="UpdatedLabelText" style={{ color: "red" }}>
                    {Const.updateFailedLabelText}
                </span>
            </div>;
        }
    } else {
        return <React.Fragment></React.Fragment>;
    }
}

export default UpdatedLabel;