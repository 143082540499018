import { Box, Button, Grid, List, ListItemButton, Radio, RadioGroup } from "@mui/material";
import { ApolloQueryResult, gql } from "@apollo/client";
import React, { useContext } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { icons } from "react-icons";
import { useNavigate } from "react-router";
import Api from "../../Api";
import Const from "../../Const";
import { CartContext, EndDrawerControllerStateContext, LoginStateContext } from "../../contexts";
import int from "../../int";
import User from "../../models/User";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import GroupTab from "../GroupTab";
import Loading from "../Loading";
import UserAddPage from "./UserAddPage";
import UserDetailPage from "./UserDetailPage";
import * as Icons from "@mui/icons-material";
import EquipmentAddPage from "./EquipmentAddPage";
import EquipmentDetailPage from "./EquipmentDetailPage";
import Equipment from "../../models/Equipment";
import IconFa from "../IconFa";
import SizedBox from "../SizedBox";
import AddToCartButton from "../AddToCartButton";
import EquipmentCategory from "../../models/EquipmentCategory";
import Rental from "../../models/Rental";
import RentalStatus from "../../models/RentalStatus";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalStatusWidget from "../RentalStatusWidget";
import BaseModel from "../../models/BaseModel";
import IntUtils from "../../IntUtils";
import StateOf from "../../states/StateOf";
import EquipmentManagementFilterController from "../../controllers/filterControllers/EquipmentManagementFilterController";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import EquipmentAliveStatusWidget from "../EquipmentAliveStatusWidget";
import EquipmentAliveStatusExtension from "../../models/EquipmentAliveStatusExtension";

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;
type FetchMoreType = (v: any) => any

const GroupStateContext = createStateContext<string>();

const EquipmentListView: React.FC<{ items: Array<any>, fetchMore: FetchMoreType, refetch: RefetchType, totalCount: int, pageInfo: any | undefined }> = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext);

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const pageInfo = props.pageInfo
    const hasNextPage: boolean = pageInfo?.hasNextPage
    const endCursor: string | undefined = pageInfo?.endCursor;

    const goEdit = (item: any) => {
        return () => {
            Router.pushNamed(
                Router.getRouteOfPage(EquipmentDetailPage)!
                    .replace(":id", Equipment.getIdNumFromString(item.id!)!.toString()), nav)
        }
    }

    return (
        <div className="EquipmentListContainer">
            <div className="RadioGroupListContainer">
                <div className="RadioGroupListBeforeAdd">
                    <RadioGroup
                        aria-label="group"
                        name="group"
                        value={groupContext.state}
                        onChange={(e, value) => { groupContext.setState(value) }}
                    >
                        <List>
                            {
                                props.items.map((item) => {
                                    const iconName = item?.category?.iconName ?? "";

                                    const aliveStatus = item?.aliveStatus !== undefined ? EquipmentAliveStatusExtension.fromString(item?.aliveStatus) : undefined;

                                    const rentals: Rental[] =
                                        Rental.createListFromGraphQLResultCached(
                                            item.uuid, item.rentals);

                                    const rentalStatusSum: RentalStatus =
                                        RentalStatusExtension.sumFromRentalsCached(
                                            item.uuid, rentals);

                                    var category_id: string | undefined;
                                    var category_name: string | undefined;
                                    var category_icon_name: string | undefined;

                                    if (item?.category !== undefined) {
                                        const category = item.category
                                        category_id = category?.id;
                                        category_name = category?.name;
                                        category_icon_name =
                                            category?.iconName;
                                    }

                                    const equipment = new Equipment({
                                        id: item.id,
                                        name: item.name,
                                        uuid: item.uuid,
                                        numbering: item.numbering,
                                        category: category_id !== undefined
                                            ? new EquipmentCategory({
                                                id: category_id,
                                                name: category_name!,
                                                iconName: category_icon_name!
                                            })
                                            : undefined
                                    });

                                    const isCartContainsMe =
                                        cartController.includes(equipment);

                                    return (
                                        <Box className="ListItem" sx={{ display: 'flex' }} key={item.id} onClick={goEdit(item)}>

                                            <ListItemButton>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <RentalStatusWidget status={rentalStatusSum} />
                                                </Box>
                                                <SizedBox width={5} />
                                                <IconFa icon={iconName} />
                                                <SizedBox width={5} />
                                                {`#${item.uuid} ${item.name} No.${item.numbering}`}
                                                {
                                                    (aliveStatus !== undefined && aliveStatus !== EquipmentAliveStatus.Alive)
                                                        ? <React.Fragment>
                                                            <SizedBox width={5} />
                                                            <EquipmentAliveStatusWidget aliveStatus={aliveStatus} />
                                                        </React.Fragment>
                                                        : <React.Fragment />

                                                }
                                            </ListItemButton>

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {
                                                    (!isCartContainsMe && rentalStatusSum !== RentalStatus.Ongoing)
                                                        ? (
                                                            <React.Fragment>
                                                                <AddToCartButton equipmentItem={equipment} iconOnly />
                                                                <SizedBox width={5} />
                                                            </React.Fragment>
                                                        )
                                                        : <React.Fragment />
                                                }
                                                <Button variant="contained">{Const.editButtonText}</Button>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                            {
                                props.items.length == 0
                                    ? <Box sx={{ color: "gray" }}>( 0件 )</Box>
                                    : (
                                        hasNextPage
                                            ? (
                                                <Box
                                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    onClick={async () => {
                                                        await props.fetchMore({
                                                            variables: {
                                                                'cursor': props.pageInfo?.endCursor
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Button>さらに表示</Button>
                                                </Box>
                                            )
                                            :
                                            <React.Fragment></React.Fragment>
                                    )
                            }
                        </List>
                    </RadioGroup>
                </div>
                <div className="RadioGroupAddContainer">
                    <div className="RadioGroupAdd">
                        <div onClick={() => {
                            var s = Router.getRouteOfPage(EquipmentAddPage)!;
                            Router.pushNamed(s, nav);
                        }}>
                            <Button variant="contained">
                                <Icons.Add />
                                <span>機材を追加</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const EquipmentManagementPageImpl: React.FC = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext)
    const userType = groupContext.state;

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as EquipmentManagementFilterController;
    const nameCt = useContext(filterController.nameCt);
    const categoryIdCt = useContext(filterController.categoryIdCt);
    const uuidCt = useContext(filterController.uuidCt);
    const numberingCt = useContext(filterController.numberingCt);
    const sortCt = useContext(filterController.sortCt);

    const showAliveCt = useContext(filterController.showAliveCt);
    const showNotfoundCt = useContext(filterController.showNotfoundCt);
    const showRemovedCt = useContext(filterController.showRemovedCt);
    const showReparingCt = useContext(filterController.showReparingCt);

    const sortStr = sortCt.state;
    const orders: Array<any> | undefined = filterController.ordersMap.get(sortStr);

    var _alive_status_list: EquipmentAliveStatus[] = []
    if (showAliveCt.state) {
        _alive_status_list.push(EquipmentAliveStatus.Alive)
    }
    if (showNotfoundCt.state) {
        _alive_status_list.push(EquipmentAliveStatus.Notfound)
    }
    if (showRemovedCt.state) {
        _alive_status_list.push(EquipmentAliveStatus.Removed)
    }
    if (showReparingCt.state) {
        _alive_status_list.push(EquipmentAliveStatus.Reparing)
    }

    const aliveStatus_list = _alive_status_list.map((e) => e.toString())

    const query = gql`
      query($exists: [EquipmentItemFilter_exists], $cursor: String, $name: String, $category_id: Int, $uuid: Int, $numbering: Int, $aliveStatus_list: [String], $order: [EquipmentItemFilter_order]){
        equipmentItems(exists: $exists, first: 50, after: $cursor, name: $name, uuid: $uuid, numbering: $numbering, category_id: $category_id, aliveStatus_list: $aliveStatus_list, order: $order){
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              _id
              uuid
              name
              numbering
              aliveStatus
              category {
                id
                name
                iconName
              }
              rentals {
                edges {
                  node {
                    id
                    status
                    startDate
                    endDate
                    rentalReturnLogs {
                      edges {
                        node {
                          id
                          returnedAt
                          note
                          userWhoReceived {
                            email
                            firstName
                            familyName
                          }
                          equipmentItems {
                            edges {
                              node {
                                id
                                uuid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {
            'name': nameCt.state !== "" ? nameCt.state : undefined,
            'category_id': (categoryIdCt.state !== "" && categoryIdCt.state !== "_") ? BaseModel.getIdNumFromString(categoryIdCt.state) : undefined,
            'uuid': uuidCt.state !== "" ? IntUtils.tryParse(uuidCt.state) : undefined,
            'numbering': numberingCt.state !== "" ? IntUtils.tryParse(numberingCt.state) : undefined,
            'order': orders,
            'aliveStatus_list': aliveStatus_list,
            'exists': categoryIdCt.state !== "_" ? [] : [{ "category": false }]
        },
        // fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentItems"]?.["edges"] !== undefined) {
        const totalCount: int = result.data?.["equipmentItems"]?.["totalCount"] ?? 0;
        const pageInfo: any | undefined =
            result.data?.["equipmentItems"]?.["pageInfo"];

        const items = result.data!["equipmentItems"]["edges"]
            .map(
                (e: any) => e["node"]
            )

        // print(items);
        return <EquipmentListView items={items} refetch={result.refetch} fetchMore={result.fetchMore} totalCount={totalCount} pageInfo={pageInfo} />;
    } else {
        return <EquipmentListView items={[]} refetch={result.refetch} fetchMore={result.fetchMore} totalCount={0} pageInfo={undefined} />;
    }
}

const EquipmentManagementPage: React.FC = (props) => {
    return (
        <EquipmentManagementPageImpl />
    )
}

export default EquipmentManagementPage;