import React from "react";
import EquipmentAliveStatus from "../EquipmentAliveStatus";
import EquipmentAliveStatusExtension from "../models/EquipmentAliveStatusExtension";
import SizedBox from "./SizedBox";
import * as Icons from "@mui/icons-material";

const EquipmentAliveStatusWidget: React.FC<{ aliveStatus: EquipmentAliveStatus }> = (props) => {
    const status = props.aliveStatus;
    const color = EquipmentAliveStatusExtension.getColor(status);
    const name = EquipmentAliveStatusExtension.toStringJp(status);
    const id = status.toString();

    return (
        <span className="EquipmentAliveStatus" style={{ display: 'flex', alignItems: 'center' }}>
            {
                (status === EquipmentAliveStatus.Alive) ?
                    <Icons.Circle style={{ color: color }} />
                    : <Icons.Warning style={{ color: color }} />
            }
            <SizedBox inline width={5} />
            <span style={{ color: color }}>{name}</span>
        </span>
    );
}

export default EquipmentAliveStatusWidget;