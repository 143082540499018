import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { LoginStateContext, MenuItemsContext } from "../../contexts";
import Router from "../../Router";
import AccountSettingsPage from "./AccountSettingsPage";

// const settingsList = MenuItems.settingslist

const SettingsPage: React.FC = () => {
    const nav = useNavigate()

    const menuItemsContext = useContext(MenuItemsContext);
    const menuItems = menuItemsContext.state.instance
    const settingsList = menuItems.settingslist

    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;

    return (
        <List>
            {
                settingsList.map((e) => {
                    if (e === undefined) {
                        return <Divider />
                    }
                    if (e.isRentalRoleMenu && loginState.user?.isRentalRoleUser() !== true) {
                        return <React.Fragment />
                    }
                    return (
                        <div key={e.routePath} onClick={() => {
                            Router.pushNamed(e.routePath, nav);
                        }}>
                            <ListItemButton>

                                {
                                    (e.icon)
                                        ? <ListItemIcon>
                                            {React.createElement(e.icon!)}
                                        </ListItemIcon>
                                        : <React.Fragment />
                                }
                                <ListItemText primary={e.title} />

                            </ListItemButton>
                        </div>
                    )
                })
            }
        </List>
    );
}

export default SettingsPage;