import { Box, Button, Grid, List, ListItemButton, Radio, RadioGroup } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { icons } from "react-icons";
import { useNavigate } from "react-router";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import User from "../../models/User";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import GroupTab from "../GroupTab";
import Loading from "../Loading";
import UserAddPage from "./UserAddPage";
import UserDetailPage from "./UserDetailPage";
import * as Icons from "@mui/icons-material";

type UserGroupMap = { "type": string, "name": string, "count": int };

const GroupStateContext = createStateContext<string>();

const UserPeopleListView: React.FC<{ items: Array<any> }> = (props) => {
    const nav = useNavigate()
    const groupContext = useContext(GroupStateContext);
    const goEdit = (item: any) => {
        return () => {
            Router.pushNamed(
                Router.getRouteOfPage(UserDetailPage)
                    .replace(":id", User.getIdNumFromString(item.id!)!.toString()), nav)
        }
    }

    return (
        <div className="RadioGroupListContainer">
            <div className="RadioGroupListBeforeAdd">
                <RadioGroup
                    aria-label="group"
                    name="group"
                    value={groupContext.state}
                    onChange={(e, value) => { groupContext.setState(value) }}
                >
                    <List>
                        {
                            props.items.map((item) =>
                                <Box className="ListItem" sx={{ display: 'flex' }} key={item.id} onClick={goEdit(item)}>
                                    <ListItemButton>
                                        {`${item.familyName} ${item.firstName} (${item.email})`}
                                    </ListItemButton>

                                    <Button variant="contained">{Const.editButtonText}</Button>
                                </Box>
                            )
                        }
                        {
                            props.items.length == 0
                                ? <Box sx={{ color: "gray" }}>( グループを選択してください )</Box>
                                : <React.Fragment />
                        }
                    </List>
                </RadioGroup>
            </div>
            <div className="RadioGroupAddContainer">
                <div className="RadioGroupAdd">
                    <div onClick={() => {
                        var s = Router.getRouteOfPage(UserAddPage);
                        Router.pushNamed(s, nav);
                    }}>
                        <Button variant="contained">
                            <Icons.Add />
                            <span>ユーザを追加</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const UserPeople: React.FC = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext)
    const userType = groupContext.state;

    const query = gql`
    {
        users {
          edges {
            node {
              id
              _id
              firstName
              familyName
              email
              roles
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["users"]?.["edges"] !== undefined) {
        const users: Array<any> = userType !== "" ? result.data!["users"]["edges"] : [];

        const items = users
            .filter((e) => userType === "ADMIN"
                ? e["node"]["roles"].includes("ROLE_ADMIN")
                : (userType === "USER"
                    ? !e["node"]["roles"].includes("ROLE_ADMIN") && e["node"]["roles"].includes("ROLE_RENTAL") == true
                    : !e["node"]["roles"].includes("ROLE_ADMIN") && e["node"]["roles"].includes("ROLE_RENTAL") == false))
            .map(
                (e) => e["node"]
            )

        // print(items);
        return <UserPeopleListView items={items} />;
    } else {
        return <UserPeopleListView items={[]} />;
    }
}

const UserGroupListView: React.FC<{ items: Array<UserGroupMap> }> = (props) => {
    const groupContext = useContext(GroupStateContext);
    return (
        <RadioGroup
            aria-label="group"
            name="group"
            value={groupContext.state}
            onChange={(e, value) => { groupContext.setState(value) }}
        >
            <List>
                {
                    props.items.map((item) =>
                        <div key={item.type} onClick={() => { groupContext.setState(item.type) }}>
                            <ListItemButton>
                                <Radio value={item.type} />
                                {`${item.name} (${item.count})`}
                            </ListItemButton>
                        </div>
                    )
                }
            </List>
        </RadioGroup>
    )
}

const UserGroup: React.FC = (props) => {
    const nav = useNavigate()

    const query = gql`
    {
        users {
          edges {
            node {
              id
              _id
              roles
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["users"]?.["edges"] !== undefined) {
        const users: Array<any> = result.data!["users"]["edges"];

        const adminUsersCount: int = users
            .filter((e) => e["node"]["roles"].includes("ROLE_ADMIN"))
            .length;
        const normalUsersCount: int = users
            .filter((e) => !e["node"]["roles"].includes("ROLE_ADMIN"))
            .filter((e) => e["node"]["roles"].includes("ROLE_RENTAL"))
            .length;
        const guestUsersCount: int = users
            .filter((e) => !e["node"]["roles"].includes("ROLE_ADMIN"))
            .filter((e) => !e["node"]["roles"].includes("ROLE_RENTAL"))
            .length;

        const items: Array<UserGroupMap> = [
            { "type": "ADMIN", "name": "管理者", "count": adminUsersCount },
            { "type": "USER", "name": "一般ユーザ", "count": normalUsersCount },
            { "type": "GUEST", "name": "ゲストユーザ", "count": guestUsersCount },
        ];

        // print(items);
        return <UserGroupListView items={items} />;
    } else {
        return <UserGroupListView items={[]} />;
    }
}

const UserManagementPageImpl: React.FC = (props) => {
    return (
        <StateProvider context={GroupStateContext} defaultValue={""} >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <GroupTab title={Const.userGroupLabelText}>
                        <UserGroup />
                    </GroupTab>
                </Grid>

                <Grid item xs={6}>
                    <GroupTab title={Const.userPeopleLabelText}>
                        <UserPeople />
                    </GroupTab>
                </Grid>
            </Grid >
        </StateProvider >
    )
}

const UserManagementPage: React.FC = (props) => {

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    return (
        <React.Fragment>
            {
                (() => {
                    if (loginState.user!.isAdmin() == false) {
                        return <React.Fragment>{Const.adminPageErrorText}</React.Fragment>
                    } else {
                        return <UserManagementPageImpl />
                    }
                })()
            }
        </React.Fragment>
    );
}

export default UserManagementPage;