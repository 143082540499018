import { TextFields } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import Const from "../Const";
import { EndDrawerControllerStateContext } from "../contexts";
import Router from "../Router";
import StateContextProp from "../states/StateContextProp";
import IconFa from "../widgets/IconFa";
import Loading from "../widgets/Loading";
import SizedBox from "../widgets/SizedBox";
import * as Icons from "@mui/icons-material";
import RentalListFilterController from "../controllers/filterControllers/RentalListFilterController";


const _UserDropDownImpl: React.FC<{ items: Array<any>, userCt: StateContextProp<string> }> = (props) => {

    return (
        <FormControl variant="outlined" sx={{ width: "100%" }}>
            <InputLabel className="InputLabel" id="user-filter-label">{"TEMDEC側 担当者"}</InputLabel>
            <Select
                value={props.userCt.state}
                labelId="user-filter-label"
                name="user"
                onChange={async (e: any) => {
                    props.userCt.setState(e.target.value)
                    Api.graphQLResetCache()
                }}
            >
                <MenuItem key={""} value={""}>{"-"}</MenuItem>
                {
                    props.items
                        .map((e) =>
                            <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                                <span>{`${e.familyName} ${e.firstName}`}</span>
                            </MenuItem>
                        )
                }
            </Select>
        </FormControl>
    )
}

const _UserDropDown: React.FC<{ userCt: StateContextProp<string> }> = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        users {
          edges {
            node {
              id
              _id
              firstName
              familyName
              email
            }
          }
        }
      } 
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["users"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["users"]["edges"]
            .map((e: any) => e.node)

        return <_UserDropDownImpl items={items} userCt={props.userCt} />
    } else {
        return <_UserDropDownImpl items={[]} userCt={props.userCt} />
    }
}

const _SortDropDown: React.FC<{ sortCt: StateContextProp<string>, sortMap: Map<string, string> }> = (props) => {
    return (
        <React.Fragment>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel className="InputLabel" id="sort-filter-label">{Const.sortMenuLabelText}</InputLabel>
                <Select
                    value={props.sortCt.state}
                    labelId="sort-filter-label"
                    name="sort"
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             <Icons.Sort />
                    //         </InputAdornment>
                    //     ),
                    // }}
                    onChange={async (e: any) => {
                        // console.log(e.target.value)
                        props.sortCt.setState(e.target.value)
                        Api.graphQLResetCache()
                    }}
                >
                    <MenuItem key={""} value={""}>
                        <Icons.Sort />
                        <SizedBox inline width={5} />
                    </MenuItem>
                    {
                        Array.from(props.sortMap.keys())
                            .map((key) => {
                                const val = props.sortMap.get(key)!
                                return (
                                    <MenuItem className="MenuItemWithIcon" key={val} value={val}>
                                        <Icons.Sort />
                                        <SizedBox inline width={5} />
                                        <span>{key}</span>
                                    </MenuItem>
                                )
                            })
                    }
                </Select>
            </FormControl>
        </React.Fragment>
    )
}

const RentalListPage_FilterBuilder: React.FC = () => {
    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as RentalListFilterController;
    const titleCt = useContext(filterController.titleCt);
    const userInChargeCt = useContext(filterController.userInChargeCt);
    const sortCt = useContext(filterController.sortCt);

    const showCompletedCt = useContext(filterController.showCompletedCt);
    const filterCompletedByYearCt = useContext(filterController.filterCompletedByYearCt);
    const showVacantCt = useContext(filterController.showVacantCt);
    const showReservedCt = useContext(filterController.showReservedCt);
    const showOngoingCt = useContext(filterController.showOngoingCt);

    // const context = useContext(endDrawerController)

    return (
        <div className="FilterForm">
            <_SortDropDown sortCt={sortCt} sortMap={filterController.sortMap} />
            <SizedBox height={10} />
            <TextField
                label={Const.rentalTitleLabelText}
                value={titleCt.state}
                onChange={async (e) => {
                    titleCt.setState(e.target.value)
                    Api.graphQLResetCache()
                }}
            />
            <SizedBox height={10} />
            <_UserDropDown userCt={userInChargeCt} />
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showCompletedCt.state} onChange={(e) => {
                    showCompletedCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="完了を表示" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={filterCompletedByYearCt.state} onChange={(e) => {
                    filterCompletedByYearCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="'完了'は年度で絞り込み" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showReservedCt.state} onChange={(e) => {
                    showReservedCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="予約中を表示" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showOngoingCt.state} onChange={(e) => {
                    showOngoingCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="貸出中を表示" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showVacantCt.state} onChange={(e) => {
                    showVacantCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="貸出可を表示" />
            </FormControl>
        </div>
    );
}

export default RentalListPage_FilterBuilder;