import PageType from './PageType';
import IconData from './IconData';
import PageInfo from './PageInfo';
import HomePage from './widgets/pages/HomePage';
import EquipmentManagementPage from './widgets/pages/EquipmentManagementPage';
import LogoutPage from './widgets/pages/LogoutPage';
import SettingsPage from './widgets/pages/SettingsPage';
import AdminSettingsPage from './widgets/pages/AdminSettingsPage';
import QRScanPage from './widgets/pages/QRScanPage';
import RentalListPage from './widgets/pages/RentalListPage';
import RentalAndReservationPage from './widgets/pages/RentalAndReservationPage';
import CustomerManagementPage from './widgets/pages/CustomerManagementPage';
import EquipmentAddPage from './widgets/pages/EquipmentAddPage';
import UserManagementPage from './widgets/pages/UserManagementPage';
import AccountSettingsPage from './widgets/pages/AccountSettingsPage';

class MenuItem {
    constructor(
        props: {
            icon?: IconData,
            title: string,
            routePath: string,
            pageType: PageType,
            isAdminMenu?: boolean,
            isRentalRoleMenu?: boolean
        }) {
        this.icon = props.icon
        this.title = props.title
        this.routePath = props.routePath
        this.pageType = props.pageType
        this.isAdminMenu = props.isAdminMenu ?? false
        this.isRentalRoleMenu = props.isRentalRoleMenu ?? false
    };

    icon?: IconData
    title: string
    routePath: string
    pageType: PageType
    isAdminMenu: boolean
    isRentalRoleMenu: boolean
}

export default MenuItem;