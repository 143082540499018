import int from "../int";
import Rental from "./Rental";
import RentalStatus from "./RentalStatus";

class RentalStatusExtension {
    static toShortString(status: RentalStatus): string {
        return status.toString()
    }

    static toStringJp(status: RentalStatus): string {
        switch (status) {
            case RentalStatus.Vacant:
                return "貸出可";
            case RentalStatus.Ongoing:
                return "貸出中";
            case RentalStatus.Reserved:
                return "予約中";
            case RentalStatus.Completed:
                return "完了";
        }
    }

    static getColor(status: RentalStatus): string {
        // console.error("getColor() should be rewritten!")
        switch (status) {
            case RentalStatus.Vacant:
                return "#dcfacd";
            case RentalStatus.Ongoing:
                return "#facdcd";
            case RentalStatus.Reserved:
                return "#faf4cd";
            case RentalStatus.Completed:
                return "#cdfaf8";
        }
    }

    static fromString(value: string): RentalStatus {
        return value as RentalStatus
    }

    static sum(rentalStatuses: RentalStatus[]): RentalStatus {
        var status: RentalStatus = RentalStatus.Vacant;
        for (const item of rentalStatuses) {
            if (item == RentalStatus.Ongoing) {
                return RentalStatus.Ongoing;
            } else if (item == RentalStatus.Reserved) {
                status = RentalStatus.Reserved;
            }
        }
        return status;
    }

    static sumFromRentals(rentals: Rental[]): RentalStatus {
        var status = RentalStatusExtension.sum(rentals.map((e) => {
            if (e.getStatus() === RentalStatus.Ongoing &&
                (e.rentalReturnLogs !== undefined && e.rentalReturnLogs!.length > 0)) {
                const logs = e.rentalReturnLogs!;
                // print("logs: ${logs.length}");
                for (const log of logs) {
                    var items = log.equipmentItems;
                    for (const item of items) {
                        if (item.id == e.id) {
                            return RentalStatus.Completed;
                        }
                    }
                }
                return e.getStatus();
            } else {
                return e.getStatus();
            }
        }))

        return status;
    }

    static _sumFromRentalsCache: Map<int, RentalStatus> = new Map();

    static sumFromRentalsCached(uuid: int, rentals: Rental[]): RentalStatus {
        if (this._sumFromRentalsCache.has(uuid)) {
            return this._sumFromRentalsCache.get(uuid)!;
        } else {
            return RentalStatusExtension.sumFromRentals(rentals);
        }
    }

    static sumFromRentalsCacheClear(uuid: int) {
        this._sumFromRentalsCache.delete(uuid);
    }
}

export default RentalStatusExtension;