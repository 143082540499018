// import { ApolloLink, FetchResult, from, HttpLink } from "@apollo/client";
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { ApolloError, ApolloQueryResult, QueryOptions } from "@apollo/client";
import axios, { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router";
import Token from "./models/Token";
import Result from "./Result";
import Router from "./Router";
import LoginState from "./states/LoginState";
import LoginPage from "./widgets/pages/LoginPage";

type QueryResult = ApolloQueryResult<any>

type HttpResponse = AxiosResponse<any, any>

class ApiState {
    _connectionFailed: boolean = false
    get connectionFailed() { return this._connectionFailed }

    _statusCode?: number
    get statusCode() { return this._statusCode }

    notifyListeners() {
        console.error("Not Implemented")
        // if (stateChangeNotifier != null) {
        //   stateChangeNotifier?.notify();
        // }
    }

    setConnectionFailed(r?: HttpResponse) {
        console.error("Connection Failed");

        if (r != null) {
            this._statusCode = r.status;
            console.log(r.status);
            console.log(r);
        }
        this._connectionFailed = true;

        this.notifyListeners();
    }
}

declare const __DEV__: boolean;

class _Api {
    private _jwtToken?: string;
    apiPath: string = "http://" + window.location.hostname + ":8087";
    apiPathGraphQL: string = "http://" + window.location.hostname + ":8087/graphql";
    apiState: ApiState = new ApiState()
    graphQLClient?: ApolloClient<NormalizedCacheObject>
    sessionExpiredFlag: boolean = false

    constructor() {
        let dev = __DEV__ ?? false;

        console.log("__DEV__: " + dev)

        if (dev) {
            this.apiPath = "http://temdec-kizai-dev.v01.jp";
            this.apiPathGraphQL = "http://temdec-kizai-dev.v01.jp/graphql";
        }
        // else {
        //     if (window.location.hostname != "localhost") {
        //         this.apiPath = "https://" + window.location.hostname + ":8089";
        //         this.apiPathGraphQL = "https://" + window.location.hostname + ":8089/graphql";
        //     }
        // }

        // only for development
        this.apiPath = window.location.protocol + "//temdec-kizai-dev.v01.jp";
        this.apiPathGraphQL = window.location.protocol + "//temdec-kizai-dev.v01.jp/graphql";
        console.log("build-note: 230302")

        console.log("apiPath: " + this.apiPath)
        console.log("apiPathGraphQL: " + this.apiPathGraphQL)
    }

    get jwtToken() {
        return this._jwtToken
    }

    set jwtToken(token: string | undefined) {
        const isChanged = (this._jwtToken !== token);
        this._jwtToken = token;
        if (isChanged) {
            this.updateGraphQLClient();
        }
    }

    async _tryRelogin(navigate: NavigateFunction) {
        // await loginState.setLoggedOut(isExpired: true);
        // AppBuilder.of(context)?.rebuild();
        // return true;

        Router.pushNamed(Router.getRouteOfPage(LoginPage), navigate)
    }

    async graphQLResetCache() {
        try {
            await Api.graphQLClient?.resetStore()
        } catch (e) { }
    }

    handleGraphQLException(error: any, navigate: NavigateFunction) {
        if (error === undefined) {
            return
        }

        if (error instanceof ApolloError
            && error.networkError !== null
            && error.networkError.name === "ServerError"
            && error.networkError.message.match("401")) {
            console.error("Detected session expired");

            this.sessionExpiredFlag = true

            const self = this

            requestAnimationFrame(function () {
                self._tryRelogin(navigate)
            })

        } else {
            // throw error // Re-throw error
            console.error(error)
        }

        // if (result !== undefined) {
        //     if (result.errors !== undefined) {
        //         for (const err of result.errors) {
        //             console.error(err.message)
        //         }
        //     }
        // }
    }

    updateGraphQLClient() {
        // console.log("_updateGraphQLClient")

        const httpLink = new HttpLink({ uri: this.apiPathGraphQL });

        const token = this.jwtToken;
        const authorization = token !== undefined ? `Bearer ${token}` : undefined;
        // console.log(authorization)

        const authLink = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    authorization: authorization,
                },
                // fetchOptions: {
                //     mode: 'no-cors'
                // }
            }));

            return forward(operation);
        })

        const cache = new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        equipmentItems: relayStylePagination(),
                        rentals: relayStylePagination(),
                    },
                },
            },
        });

        const client = new ApolloClient({
            // cache: new InMemoryCache(),
            cache: cache,
            link: from([
                authLink,
                httpLink,
            ]),
        });

        this.graphQLClient = client;
    }

    private _http(uri: string,
        { method, body, headers, encoding }: {
            method?: string,
            body?: any,
            headers?: any,
            encoding?: any
        }): Promise<HttpResponse> {
        const validateStatus = function (status: number): boolean {
            return status < 500;
        }
        var _method = method?.toUpperCase();
        if (_method === "GET") {
            return axios.get(uri, {
                headers: headers, data: body, validateStatus: validateStatus
            });
        } else if (_method === "POST") {
            // console.log(`headers: ${JSON.stringify(headers)}`)
            return axios.post(uri, body, {
                headers: headers, validateStatus: validateStatus
            });
        } else if (_method === "DELETE") {
            return axios.delete(uri, {
                headers: headers, data: body, validateStatus: validateStatus
            });
        } else if (_method === "PUT") {
            return axios.put(uri, body, { headers: headers, validateStatus: validateStatus });
        } else if (_method === "PATCH") {
            return axios.patch(uri, { headers: headers, body: body, encoding: encoding, validateStatus: validateStatus });
        } else if (_method === "HEAD") {
            return axios.head(uri, { headers: headers, validateStatus: validateStatus });
        } else {
            return axios.get(uri, { headers: headers, validateStatus: validateStatus });
        }
    }

    getJson(apiRoute: string): Promise<HttpResponse | undefined> {
        return this._apiRequest(apiRoute,
            {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                forlogin: false
            });
    }

    private async _apiRequest(apiRoute: string,
        { method, body, headers, encoding, forlogin = false }: {
            method?: string,
            body?: any,
            headers?: any,
            encoding?: any,
            forlogin: boolean
        }): Promise<HttpResponse | undefined> {
        var uri = `${this.apiPath}/${apiRoute}`;
        var res;
        try {
            if (forlogin !== true) {
                if (headers === null) {
                    headers = {};
                }
                headers["Authorization"] = `Bearer ${this.jwtToken}`;
            }

            res = await this._http(uri,
                { method: method, body: body, headers: headers, encoding: encoding });

            if (res.status === 403 || res.status === 404) {
                this.apiState.setConnectionFailed(res);
                return undefined;
            }

            if (res.status === 401 || res.status === 400) {
                if (forlogin === true) {
                    return res;
                } else {
                    this.apiState.setConnectionFailed(res);
                    return undefined;
                }
            }

            if (200 <= res.status && res.status <= 299) {
                return res;
            } else {
                this.apiState.setConnectionFailed(res);
                return undefined;
            }
        } catch (e) {
            // console.log(`res = ${res}`)
            // console.log(`e = ${e}`)
            // console.log(/401/.test(e as string))
            if ((/401/.test(e as string))) {
                res = { status: 401 } as AxiosResponse<any, any>;
                if (forlogin === true) {
                    return res;
                } else {
                    this.apiState.setConnectionFailed(res);
                    return undefined;
                }
            } else {
                console.error(e);
                this.apiState.setConnectionFailed(undefined);
                return undefined;
            }
        }
    }

    graphQLQuery(options: QueryOptions): Promise<QueryResult> {
        return this.graphQLClient!.query(options);
    }

    postJson(apiRoute: string, json: any): Promise<HttpResponse | undefined> {
        return this._apiRequest(apiRoute, {
            method: "POST",
            body: (json !== undefined) ? JSON.stringify(json) : JSON.stringify({}),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            forlogin: false
        });
    }

    postJsonForLogin(apiRoute: string, json: any): Promise<HttpResponse | undefined> {
        return this._apiRequest(apiRoute, {
            method: "POST",
            body: (json !== undefined) ? JSON.stringify(json) : JSON.stringify({}),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            forlogin: true
        });
    }

    async tryLogIn({ email, password }: { email: string, password: string }): Promise<Result<Token> | undefined> {
        const response = await this.postJsonForLogin(
            "auth", { 'email': email, 'password': password });

        if (response === undefined) {
            console.error("unknown http error");
            return undefined;
        }

        if (response.status === 200) {
            // console.log("200");
            // console.log(response.data)
            var result = new Token(response.data);
            if (result.token != null) {
                return new Result({ flag: true, result: result });
            } else {
                return new Result({ flag: false });
            }
        } else if (response.status === 401) {
            // print("login failed");
            return new Result({ flag: false });
        } else {
            // print("login failed unknown");
            console.log(response.status);
            console.log(response.data);
            this.apiState.setConnectionFailed(response);
            return undefined;
        }
    }
}

const Api = new _Api();

export default Api;