import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import { IconPicker } from 'react-fa-icon-picker'
import IconUtils from "../../IconUtils";

const UpdatedStateContext = createStateContext<UpdateState>();

const NameStateContext = createStateContext<string>();
const IconNameStateContext = createStateContext<string>();

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const nameContext = useContext(NameStateContext);
    const iconNameContext = useContext(IconNameStateContext);

    const updatedContext = useContext(UpdatedStateContext);

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                const name = nameContext.state;
                const iconName = iconNameContext.state;

                const queryStr = `
                  mutation($name: String!, $iconName: String) {
                    createEquipmentCategory(input: {name: $name, iconName: $iconName}) {
                      equipmentCategory {
                        id
                        name
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'name': (name == "")
                                ? undefined
                                : name,
                            'iconName': (iconName == "")
                                ? undefined
                                : iconName,
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["createEquipmentCategory"] != undefined &&
                    result?.data?.["createEquipmentCategory"]?.["equipmentCategory"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    // await refetch();
                    nameContext.setState("");
                    iconNameContext.setState("");
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(EquipmentCategoryManagementPage), nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >

            <TextValidator
                className="Required"
                label={"カテゴリ名"}
                onChange={(e: any) => nameContext.setState(e.target.value)}
                name="name"
                value={nameContext.state}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />


            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <div className="IconPickerContainer">
                        <IconPicker value={IconUtils.getIconFaFromName(iconNameContext.state)!} onChange={(v) => iconNameContext.setState(v)} />
                    </div>
                </Grid>

                <Grid item xs={9}>
                    <TextValidator
                        label={`${Const.equipmentCategoryIconLabelText} (左のセレクタをタップして選択)`}
                        onChange={(e: any) => iconNameContext.setState(e.target.value)}
                        name="iconName"
                        value={iconNameContext.state}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <Center>
                <Button variant="contained" type="submit">{Const.addNewButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

            <div style={{ height: "300px" }}></div>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const name = "";
    const iconName = "";

    return (
        <StateProvider context={NameStateContext} defaultValue={name}>
            <StateProvider context={IconNameStateContext} defaultValue={iconName}>
                <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                    <_FormImpl />
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )
}

const EquipmentCategoryAddPage: React.FC = (props) => {
    return <_Form />
}

export default EquipmentCategoryAddPage;