import { Alert, AppBar, Drawer, IconButton, Snackbar, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { DrawerStateContext, EndDrawerControllerStateContext, EndDrawerStateContext, LoginStateContext, SnackBarStateContext, TitleStateContext, YearRangeStateContext } from "../contexts";
import GraphQLProvider from "./GraphQLProvider";
import StateProvider from "../states/StateProvider";

import PageInfo from "../PageInfo";
import { Route, Routes } from "react-router";
import SideBar from "./SideBar";

import * as Icons from "@mui/icons-material";
import history from "../history";
import StateOf from "../states/StateOf";
import Router from "../Router";
import SizedBox from "./SizedBox";
import LoginState from "../states/LoginState";

import moment from "moment";
import YearRangeState from "../states/YearRangeState";
import BusinessYear from "../BusinessYear";

const DrawerConsumer: React.FC = () => {
    const { state, setState } = useContext(DrawerStateContext);

    return (
        <Drawer anchor="left"
            open={state}
            onClose={() => setState(false)}
        >
            <SideBar />
        </Drawer>
    )
}

const EndDrawerConsumer: React.FC = () => {
    const { state, setState } = useContext(EndDrawerStateContext);

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterBuilder = endDrawerController.getBuilderOf(Router.route)
    const hasFilterBuilder = (filterBuilder !== undefined)

    return (
        <Drawer anchor="right"
            open={state}
            onClose={() => setState(false)}
        >
            <div className="EndSideBar">
                <div className="EndDrawerHeaderContainer">
                    <div className="EndDrawerHeader">
                        <div className="EndDrawerHeaderContent">
                            <Icons.Search />
                            <SizedBox inline width={3} />
                            フィルタ
                        </div>
                    </div>
                </div>
                <div className="EndDrawerHeaderSpacer"></div>
                <div className="EndDrawerContentContainer">
                    {
                        hasFilterBuilder
                            ? (
                                <React.Fragment>
                                    {React.createElement(filterBuilder)}
                                </React.Fragment>
                            )
                            : <React.Fragment />
                    }
                </div>
            </div>
        </Drawer>
    )
}

const MyAppBar: React.FC = (props) => {
    const drawerState = useContext(DrawerStateContext);
    const endDrawerState = useContext(EndDrawerStateContext);
    const titleState = useContext(TitleStateContext);
    // const endDrawerButtonState = useContext(EndDrawerButtonStateContext);

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext)
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterBuilder = endDrawerController.getBuilderOf(Router.route)
    const hasFilterBuilder = (filterBuilder !== undefined)

    const s = titleState.state
    // const s = "test > hoge"
    const sp = s.split(" > ")
    const title = sp.length > 1 ? sp[1] : sp[0]
    const parentTitle = sp.length > 1 ? sp[0] : undefined
    const hasParent = (parentTitle !== undefined)

    return (
        <AppBar position="fixed">
            <Toolbar>
                {
                    hasParent ?
                        <React.Fragment>
                            <div onClick={() => drawerState.setState(true)} >
                                <IconButton>
                                    <Icons.Menu style={{ color: 'white' }} />
                                </IconButton>
                            </div>
                            <div onClick={() => history.back()} >
                                <IconButton>
                                    <Icons.ArrowBack style={{ color: 'white' }} />
                                </IconButton>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div onClick={() => drawerState.setState(true)} >
                                <IconButton>
                                    <Icons.Menu style={{ color: 'white' }} />
                                </IconButton>
                            </div>
                        </React.Fragment>
                }
                <span style={{ marginLeft: "10px" }}></span>
                {
                    hasParent ?
                        <Typography variant="h6" component="div">
                            {parentTitle}
                        </Typography>
                        : undefined
                }
                {
                    hasParent ?
                        <Icons.ChevronRight />
                        : undefined
                }
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                {
                    (hasFilterBuilder)
                        ? (
                            <div onClick={() => endDrawerState.setState(true)} >
                                <IconButton>
                                    <Icons.Search style={{ color: 'white' }} />
                                </IconButton>
                            </div>
                        )
                        : <React.Fragment />
                }
            </Toolbar>
        </AppBar >
    );
}

const PageContainer: React.FC = (props) => {
    return <div className="PageContainer">
        <div className="Page">{props.children}</div>
    </div>
}

const PageTitleSetter: React.FC<{ parentTitle?: string, title: string }> = (props) => {
    const titleState = useContext(TitleStateContext);

    useEffect(() => {
        const title = props.parentTitle !== undefined ? `${props.parentTitle} > ${props.title}` : props.title
        const titleOfPage = `${title} | TEMDEC機材管理`
        document.title = titleOfPage
        titleState.setState(title)
    }, [props.parentTitle, props.title])
    return <React.Fragment></React.Fragment>
}

const NotFoundPage: React.FC = () => {
    return <React.Fragment>お探しのページは見つかりませんでした</React.Fragment>
}

const PageRouters: React.FC = () => {
    const loginStateContext = useContext(LoginStateContext)
    const loginState = loginStateContext.state.instance

    return <Routes>
        {
            Array.from(PageInfo.map).map(([page, info]) => {
                if (info.isAdminPage) {
                    if (loginState.user === undefined || loginState.user.isAdmin() === false) {
                        return <Route key={info.path} path={info.path} element={
                            <React.Fragment>
                                <NotFoundPage />
                            </React.Fragment>
                        } />
                    }
                }

                if (info.isRentalRolePage) {
                    if (loginState.user === undefined || loginState.user.isRentalRoleUser() === false) {
                        return <Route key={info.path} path={info.path} element={
                            <React.Fragment>
                                <NotFoundPage />
                            </React.Fragment>
                        } />
                    }
                }

                return (
                    <Route key={info.path} path={info.path} element={
                        <React.Fragment>
                            <PageTitleSetter
                                parentTitle={info.parentPageType !== undefined ? PageInfo.getFromType(info.parentPageType)?.title : undefined}
                                title={info.title}
                            />
                            {
                                React.createElement(page)
                            }
                        </React.Fragment>
                    } />
                )
            })
        }
        {
            <Route element={
                <React.Fragment>
                    <NotFoundPage />
                </React.Fragment>
            } />
        }
    </Routes>
}

const MainNav: React.FC = () => {
    const snackBarContext = useContext(SnackBarStateContext);
    const snackBarState = snackBarContext.state.instance;

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance
    const EndDrawerControllerStateInilializer = endDrawerController.StateInilializer

    // get current year
    const currentYear = BusinessYear.getCurrent();

    return (
        <GraphQLProvider>
            <div className="App">
                <EndDrawerControllerStateInilializer>
                    <StateProvider context={YearRangeStateContext} defaultValue={new StateOf(new YearRangeState(currentYear - 1, currentYear + 1))}>
                        <StateProvider context={DrawerStateContext} defaultValue={false}>
                            <StateProvider context={EndDrawerStateContext} defaultValue={false}>
                                {/* <StateProvider context={EndDrawerButtonStateContext} defaultValue={false}> */}
                                {/* <StateProvider context={EquipmentListStateContext} defaultValue={new StateOf([])}> */}
                                <StateProvider context={TitleStateContext} defaultValue={"TEMDEC機材管理"}>
                                    EquipmentListStateContext
                                    <MyAppBar />
                                    <div className="AppBarSpacer" />
                                    <DrawerConsumer />
                                    <EndDrawerConsumer />
                                    <PageContainer>
                                        <PageRouters />
                                    </PageContainer>
                                    <Snackbar
                                        open={snackBarState.isOpen}
                                        autoHideDuration={snackBarState.autoHideDuration}
                                        onClose={() => {
                                            snackBarState.isOpen = false
                                            snackBarContext.setState(new StateOf(snackBarState));
                                        }}
                                    >
                                        <Alert variant="filled" severity={snackBarState.severity}>{snackBarState.message}</Alert>
                                    </Snackbar>
                                    {/* </StateProvider> */}
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                        {/* </StateProvider> */}
                    </StateProvider>
                </EndDrawerControllerStateInilializer>
            </div>
        </GraphQLProvider>
    );
}

export default MainNav;