import { width } from "@mui/system";
import React from "react";

const SizedBox: React.FC<{ width?: number, height?: number, inline?: boolean | undefined }> = (props) => {
    return (
        <div className="SizedBox"
            style={{
                width: props.width !== undefined ? `${props.width * 2}px` : undefined,
                height: props.height !== undefined ? `${props.height * 2}px` : undefined,
                display: props.inline ? "inline-block" : "block"
            }}
        ></div>
    )
}

export default SizedBox;