import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import Const from "../Const";
import createStateContext from "../states/createStateContext";
import StateProvider from "../states/StateProvider";

const DialogStateContext = createStateContext<boolean>()

const DeleteButtonImpl: React.FC<{ deletionCallback: () => void }> = (props) => {
    const dialogStateContext = useContext(DialogStateContext)
    const dialogState = dialogStateContext.state

    return (
        <React.Fragment>
            <div onClick={() => { dialogStateContext.setState(true) }}>
                <Button color="error" variant="contained">{Const.deleteButtonText}</Button>
            </div>
            <Dialog
                open={dialogState}
                onClose={() => { dialogStateContext.setState(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"本当に削除してもいいですか？"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        一度削除すると、元に戻すことはできません！
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { dialogStateContext.setState(false); props.deletionCallback() }}>本当に削除する</Button>
                    <Button onClick={() => { dialogStateContext.setState(false) }} autoFocus>
                        キャンセル
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const DeleteButton: React.FC<{ deletionCallback: () => void }> = (props) => {
    return (
        <StateProvider context={DialogStateContext} defaultValue={false}>
            <DeleteButtonImpl deletionCallback={props.deletionCallback} />
        </StateProvider>
    )
}

export default DeleteButton;