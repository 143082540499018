import { Box, Button, Snackbar } from "@mui/material";
import { useContext } from "react";
import { CartContext, SnackBarStateContext } from "../contexts";
import Equipment from "../models/Equipment";
import StateOf from "../states/StateOf";
import * as Icons from "@mui/icons-material";
import React from "react";
import SizedBox from "./SizedBox";

//     return Row(mainAxisAlignment: MainAxisAlignment.start, children: [
//         ElevatedButton(
//             style: ElevatedButton.styleFrom(
//                 primary: Colors.lightGreen,
//             ),
//             child: Row(
//                 children: [
//                 Icon(Icons.add_shopping_cart),
//           if (!widget.isIconOnly) SizedBox(width: 5),
//           if (!widget.isIconOnly) Text("カートに追加する")
//         ],
//       ),
//     onPressed: () {
//         CartController.addToCart(widget.equipmentItem);
//         final snackBar = SnackBar(
//             content: Text(
//                 "\"${widget.equipmentItem.getReadableName()}\" をカートを追加しました"),
//             backgroundColor: Colors.green,
//         );
//         ScaffoldMessenger.of(context).hideCurrentSnackBar();
//         ScaffoldMessenger.of(context).showSnackBar(snackBar);
//     })
// ]);

const AddToCartButtonImpl: React.FC<{ disabled?: boolean, equipmentItem: Equipment, iconOnly?: boolean }> = (props) => {
    const snackBarContext = useContext(SnackBarStateContext);
    const snackBarState = snackBarContext.state.instance;

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    return (
        <React.Fragment>
            <div onClick={(e) => {
                cartController.addToCart(props.equipmentItem)
                cartContext.setState(new StateOf(cartController))

                snackBarState.isOpen = true;
                snackBarState.message = `\"${props.equipmentItem.getReadableName()}\" をカートを追加しました`
                snackBarContext.setState(new StateOf(snackBarState));
                e.preventDefault()
                e.stopPropagation()
            }} style={{ display: "inline-block" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="contained" color="warning" disabled={props.disabled}>
                        <Icons.AddShoppingCart />
                        {
                            props.iconOnly
                                ? <React.Fragment></React.Fragment>
                                : (
                                    <React.Fragment>
                                        <SizedBox width={5} />
                                        <span>カートに追加</span>
                                    </React.Fragment>
                                )
                        }
                    </Button>
                </Box>
            </div>
        </React.Fragment>
    );
}

const AddToCartButton: React.FC<{ disabled?: boolean, equipmentItem: Equipment, iconOnly?: boolean }> = (props) => {
    return (
        <AddToCartButtonImpl disabled={props.disabled} equipmentItem={props.equipmentItem} iconOnly={props.iconOnly} />
    )
}

export default AddToCartButton;