import IconDataFa from "./IconFaData";
import IconFaList from "./IconFaList";

class IconUtils {
    static IsIconFaNameValid(iconName: string): boolean {
        return IconFaList.includes(iconName);
    }

    static getIconFaFromName(iconName: string): IconDataFa | undefined {
        return IconFaList.includes(iconName) ? iconName as IconDataFa : undefined;
    }
}

export default IconUtils;