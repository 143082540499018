class Token {
    constructor(props: {
        token?: string,
    }) {
        this.token = props.token;
    }

    token?: string
}

export default Token;