import { AppBar, Box, Button, InputAdornment, TextField, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import SizedBox from "../SizedBox";
import ValidatorMessages from "../../ValidatorMessages";

import HomePage from "./HomePage";
import LogoutPage from "./LogoutPage";

import * as Icons from "@mui/icons-material";
import { LoginStateContext, TitleStateContext } from "../../contexts";
import Router from "../../Router";
import LoginArguments from "../../LoginArguments";
import { useNavigate } from "react-router";
import StateOf from "../../states/StateOf";
import Const from "../../Const";

const LoginErrorWidget: React.FC = (props) => {
    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;

    useEffect(() => {
        if (loginState.hasLoggedOutByUser) {
            setTimeout(() => {
                loginState.hasLoggedOutByUser = false // WORKAROUND
            }, 5000);
        }
    }, [loginState.hasLoggedOutByUser])

    if (loginState.loginFailed == true) {
        return (
            <React.Fragment>
                <div className="LoginErrorMessage">
                    {Const.loginErrorText}
                </div>
                <SizedBox height={10} />
            </React.Fragment>
        )
    } else if (loginState.hasLoggedOutByUser) {
        return (
            <React.Fragment>
                <div className="LoginErrorMessage">
                    {Const.loginLoggedOutByUserErrorText}
                </div>
                <SizedBox height={10} />
            </React.Fragment>
        )
    } else if (loginState.hasJwtExpired) {
        return (
            <React.Fragment>
                <div className="LoginErrorMessage">
                    {Const.loginSessionErrorText}
                </div>
                <SizedBox height={10} />
            </React.Fragment>
        )
    } else {
        return <React.Fragment></React.Fragment>;
    }
}

const MyAppBar: React.FC = (props) => {
    return (
        <AppBar position="fixed">
            <Toolbar>
                <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                    TEMDEC機材管理
                </Typography>
                {/* <Spacer></Spacer> */}
                {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
        </AppBar>
    );
}

const PageTitleSetter: React.FC<{ parentTitle?: string, title: string }> = (props) => {
    const titleState = useContext(TitleStateContext);

    useEffect(() => {
        const title = props.parentTitle !== undefined ? `${props.parentTitle} > ${props.title}` : props.title
        document.title = title
        titleState.setState(title)
    }, [props.parentTitle, props.title])
    return <React.Fragment></React.Fragment>
}

const EmailStateContext = createStateContext<string>();
const PasswordStateContext = createStateContext<string>();

const LoginPageImpl: React.FC = () => {
    const formRef = useRef(null);
    const emailContext = useContext(EmailStateContext);
    const passwordContext = useContext(PasswordStateContext);

    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;

    const navigate = useNavigate();

    return <div className="App">
        <PageTitleSetter title="ログイン" />
        <MyAppBar />
        <div className="AppBarSpacer" />
        <div className="PageContainer">
            <div className="Page">
                <LoginErrorWidget />
                <ValidatorForm
                    ref={formRef}
                    onSubmit={async () => {
                        var email = emailContext.state;
                        var password = passwordContext.state;

                        const flag: boolean = await loginState
                            .tryLogIn({ email, password, shouldNotify: false });
                        if (flag == true) {
                            // Login Success

                            var redirectTo = Router.getRouteOfPage(HomePage)!;
                            if ('redirectTo' in Router.arguments) {
                                var args = Router.arguments as LoginArguments;
                                redirectTo = args.redirectTo;
                                if (redirectTo == Router.getRouteOfPage(LoginPage)! ||
                                    redirectTo == Router.getRouteOfPage(LogoutPage)!) {
                                    redirectTo = Router.getRouteOfPage(HomePage)!;
                                }
                            }

                            console.log(`Redirecting to ${redirectTo} (from login_page)`);

                            // Navigator.of(context).pushNamed(redirectTo);
                            Router.pushNamed(redirectTo, navigate);
                        } else {
                            loginContext.setState(new StateOf(loginState))
                            // Login Failure
                        }
                    }}
                >
                    <TextValidator
                        className="Required"
                        label="ID"
                        onChange={(e: any) => emailContext.setState(e.target.value)}
                        name="id"
                        value={emailContext.state}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icons.PermIdentity />
                                </InputAdornment>
                            ),
                        }}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />

                    <SizedBox height={10} />

                    <TextValidator
                        className="Required"
                        label="パスワード"
                        onChange={(e: any) => passwordContext.setState(e.target.value)}
                        name="password"
                        type="password"
                        value={passwordContext.state}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icons.VpnKey />
                                </InputAdornment>
                            ),
                        }}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />

                    <SizedBox height={10} />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" type="submit">ログイン</Button>
                    </div>
                </ValidatorForm >
            </div>
        </div>
    </div >
}

const LoginPage: React.FC = () => {
    return (
        <StateProvider context={EmailStateContext} defaultValue={""}>
            <StateProvider context={PasswordStateContext} defaultValue={""}>
                <LoginPageImpl></LoginPageImpl>
            </StateProvider>
        </StateProvider>
    )
}

export default LoginPage;