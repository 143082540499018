import { gql } from "@apollo/client";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect } from "react";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import Api from "../../Api";
import { CartContext } from "../../contexts";
import CartController from "../../controllers/CartController";
import IntUtils from "../../IntUtils";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateOf from "../../states/StateOf";
import StateProvider from "../../states/StateProvider";
import Center from "../Center";
import SizedBox from "../SizedBox";
import EquipmentDetailPage from "./EquipmentDetailPage";
import * as Icons from "@mui/icons-material";

interface EquipmentData {
    id: number;
    uuid: number;
    name: string;
}

const QRStateContext = createStateContext<string>()
const CameraEnabledStateContext = createStateContext<boolean>()
const ScannedListContext = createStateContext<EquipmentData[]>()
const ScannedListAppendedContext = createStateContext<boolean[]>()
const ManualInputContext = createStateContext<string>()

const ScannedIDList = () => {
    const scannedListContext = useContext(ScannedListContext)
    const scannedList: EquipmentData[] = scannedListContext.state;

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const scannedListAppendedContext = useContext(ScannedListAppendedContext)
    const scannedListAppended: boolean[] = scannedListAppendedContext.state;

    // console.log(scannedListAppended)

    const nav = useNavigate();

    useEffect(() => {
        if (scannedListAppended.length > 0) {
            for (let i = 0; i < scannedListAppended.length; i++) {
                const eq = scannedList[i];
                const state = cartController.includesId(eq.id)
                if (scannedListAppended[i] != state) {
                    scannedListAppended[i] = state;
                    scannedListAppendedContext.setState([...scannedListAppended])
                    break
                }
            }
        }
    }, [scannedList, scannedListAppended])

    if (scannedList.length == 0) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    return (
        <div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow key="header">
                            <TableCell>機材ID</TableCell>
                            <TableCell>機材名</TableCell>
                            <TableCell>-</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scannedList.map((eq: EquipmentData, index: number) => (
                            <TableRow key={index.toString()}>
                                <TableCell component="th" scope="row">#{eq.uuid}</TableCell>
                                <TableCell component="th" scope="row">
                                    <a style={{ color: "blue", textDecoration: "underline blue" }} onClick={(e) => {
                                        e.preventDefault();
                                        Router.pushNamed(
                                            Router.getRouteOfPage(EquipmentDetailPage).replace(":id", eq.id.toString()),
                                            nav)
                                    }}>{eq.name}</a>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <div onClick={(e) => {
                                        e.preventDefault();
                                        (async () => {
                                            await cartController.addToCartById(eq.id, nav)
                                            // console.log("cart ids", cartController.getIds())
                                            // console.log("has added: " + cartController.includesId(eq.id))
                                            scannedListAppended[index] = true;
                                            scannedListAppendedContext.setState([...scannedListAppended])
                                        })()
                                    }} style={{ display: "inline-block" }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Button variant="contained" color="warning" disabled={
                                                cartController.includesId(eq.id)
                                            }>
                                                <Icons.AddShoppingCart />
                                                {
                                                    false
                                                        ? <React.Fragment></React.Fragment>
                                                        : (
                                                            <React.Fragment>
                                                                <SizedBox width={5} />
                                                                <span>カートに追加</span>
                                                            </React.Fragment>
                                                        )
                                                }
                                            </Button>
                                        </Box>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}

const QRReaderImpl: React.FC<{ enabled: boolean }> = (props) => {
    const nav = useNavigate()
    const qrContext = useContext(QRStateContext)
    const cameraEnabledStateContext = useContext(CameraEnabledStateContext)
    const cameraEnabled = cameraEnabledStateContext.state

    const scannedListContext = useContext(ScannedListContext)
    const scannedListAppendedContext = useContext(ScannedListAppendedContext)

    const manualInputContext = useContext(ManualInputContext)

    const data = qrContext.state
    const num = IntUtils.tryParse(data)

    const enabled = props.enabled
    if (!enabled) {
        return (
            <React.Fragment>
                <Center>
                    <div onClick={() => {
                        scannedListContext.setState([])
                        cameraEnabledStateContext.setState(true)
                    }}>
                        <Button variant="contained">
                            QRリーダーを起動する
                        </Button>
                    </div>
                </Center>
                <SizedBox height={5} />
                <Center>
                    <div>※ QR読み取り後にカメラがオフにならない場合は、再読み込みしてください</div>
                </Center>
            </React.Fragment>
        )
    }

    if (/^\d*[1-9]\d*$/.test(data)) {
        requestAnimationFrame(async () => {
            const uuid = num
            const res = await Api.graphQLQuery({
                query: gql`
            query($uuid: Int){
                equipmentItems(uuid: $uuid) {
                  edges {
                    node {
                      id
                      _id
                      uuid
                      name
                      numbering
                      category {
                        id
                        name
                        iconName
                      }
                    }
                  }
                }
              }
          `,
                variables: { 'uuid': uuid },
                fetchPolicy: 'no-cache',
            });

            // console.log(res)
            // console.log(res.loading)

            if (res.errors) {
                qrContext.setState("")
                return false;
            }

            if (res.data == null) {
                return false;
            }

            if (res.data?.["equipmentItems"]?.["edges"] === undefined || res.data?.["equipmentItems"]?.["edges"].length < 1) {
                qrContext.setState("")
                // cameraEnabledStateContext.setState(false)

                // const stream = await navigator.mediaDevices.getUserMedia({ video: true })
                // stream.getTracks().forEach(function (track) {
                //     track.stop();
                // });
                return false;
            }

            const id_num: number = res.data?.equipmentItems?.edges?.[0]?.node?._id
            const name: string = res.data?.equipmentItems?.edges?.[0]?.node?.name

            const equipmentData: EquipmentData = {
                id: id_num,
                uuid: uuid!,
                name: name
            }

            qrContext.setState("")

            if (scannedListContext.state.find((eq: EquipmentData) => eq.id === equipmentData.id) === undefined) {
                scannedListContext.setState([...scannedListContext.state, equipmentData])
                scannedListAppendedContext.setState([...scannedListAppendedContext.state, false])
            }

            // console.log(id_num)

            // const stream = await navigator.mediaDevices.getUserMedia({ video: true })
            // console.log(stream)
            // stream.getTracks().forEach(function (track) {
            //     track.stop();
            // });


            // cameraEnabledStateContext.setState(false)

            // Router.pushNamed(
            //     Router.getRouteOfPage(EquipmentDetailPage).replace(":id", id_num!.toString()),
            //     nav)
        })
        return <React.Fragment />
    }

    return (
        <React.Fragment>
            <div className="QRReaderContainerWrap">
                <div className="QRReaderContainer">
                    <QrReader
                        delay={100}
                        style={{ maxWidth: "500px" }}
                        onError={(err) => { console.error(err) }}
                        onScan={(data) => {
                            if (data) {
                                qrContext.setState(data)
                            }
                        }}
                    />
                </div>
            </div>
            <SizedBox height={5} />
            <Center>
                <TextField label="ID手入力" variant="outlined" value={manualInputContext.state}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            const text = manualInputContext.state
                            const text2 = text.replace(/^0+/, '')
                            qrContext.setState(text2)
                            manualInputContext.setState("")
                        }
                    }}
                    onChange={(e) => {
                        manualInputContext.setState(e.target.value)
                    }}
                ></TextField>
                <div onClick={() => {
                    const text = manualInputContext.state
                    const text2 = text.replace(/^0+/, '')
                    qrContext.setState(text2)
                    manualInputContext.setState("")
                }}>
                    <Button variant="contained">反映</Button>
                </div>
            </Center>
            <SizedBox height={5} />
            <Center>
                <div onClick={() => {
                    qrContext.setState("")
                    cameraEnabledStateContext.setState(false)
                }}>
                    <Button variant="contained">キャンセル</Button>
                </div>
            </Center>

            <ScannedIDList />
        </React.Fragment>
    );
}

const QRScanPageImpl: React.FC = () => {
    const nav = useNavigate()
    const cameraEnabledStateContext = useContext(CameraEnabledStateContext)
    const cameraEnabled = cameraEnabledStateContext.state
    const key = cameraEnabled ? "QRReaderOpened" : "QRReaderNotOpen"

    return <QRReaderImpl key={key} enabled={cameraEnabled} />
}

const QRScanPage: React.FC = () => {
    return (
        <StateProvider context={QRStateContext} defaultValue={""}>
            <StateProvider context={CameraEnabledStateContext} defaultValue={false}>
                <StateProvider context={ScannedListContext} defaultValue={[]}>
                    <StateProvider context={ScannedListAppendedContext} defaultValue={[]}>
                        <StateProvider context={ManualInputContext} defaultValue={""}>
                            <QRScanPageImpl />
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )
}

export default QRScanPage;