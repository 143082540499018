import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItemsContext } from "../../contexts";
import Router from "../../Router";
import ScrollView from "../ScrollView";

const AdminSettingsPage: React.FC = () => {
    const nav = useNavigate()

    const menuItemsContext = useContext(MenuItemsContext);
    const menuItems = menuItemsContext.state.instance
    const adminSettingsList = menuItems.adminSettingslist

    return (
        <ScrollView>
            <List>
                {
                    adminSettingsList.map((e) =>
                        <div key={e.routePath} onClick={() => {
                            Router.pushNamed(e.routePath, nav);
                        }}>
                            <ListItemButton>

                                <ListItemIcon>
                                    {React.createElement(e.icon!)}
                                </ListItemIcon>
                                <ListItemText primary={e.title} />

                            </ListItemButton>
                        </div>
                    )
                }
            </List>
        </ScrollView>
    );
}

export default AdminSettingsPage;