import IconData from "./IconData";
import PageType from "./PageType";
import HomePage from "./widgets/pages/HomePage";
import LoginPage from "./widgets/pages/LoginPage";
import LogoutPage from "./widgets/pages/LogoutPage";
import EquipmentManagementPage from "./widgets/pages/EquipmentManagementPage";

import * as Icons from "@mui/icons-material";

import AdminSettingsPage from "./widgets/pages/AdminSettingsPage";
import SettingsPage from "./widgets/pages/SettingsPage";
import QRScanPage from "./widgets/pages/QRScanPage";
import AccountSettingsPage from "./widgets/pages/AccountSettingsPage";
import UserManagementPage from "./widgets/pages/UserManagementPage";
import RentalListPage from "./widgets/pages/RentalListPage";
import RentalAndReservationPage from "./widgets/pages/RentalAndReservationPage";
import CustomerManagementPage from "./widgets/pages/CustomerManagementPage";
import EquipmentAddPage from "./widgets/pages/EquipmentAddPage";
import EquipmentBatchEditPage from "./widgets/pages/EquipmentBatchEditPage";
import NotFoundPage from "./widgets/pages/NotFoundPage";
import EquipmentDetailPage from "./widgets/pages/EquipmentDetailPage";
import UserDetailPage from "./widgets/pages/UserDetailPage";
import CustomerGroupDetailPage from "./widgets/pages/CustomerGroupDetailPage";
import CustomerPersonDetailPage from "./widgets/pages/CustomerPersonDetailPage";
import UserAddPage from "./widgets/pages/UserAddPage";
import CustomerPersonAddPage from "./widgets/pages/CustomerPersonAddPage";
import CustomerGroupAddPage from "./widgets/pages/CustomerGroupAddPage";
import RentalDetailPage from "./widgets/pages/RentalDetailPage";
import EquipmentCategoryManagementPage from "./widgets/pages/EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./widgets/pages/EquipmentPlaceManagementPage";
import EquipmentCategoryAddPage from "./widgets/pages/EquipmentCategoryAddPage";
import EquipmentPlaceDetailPage from "./widgets/pages/EquipmentPlaceDetailPage";
import EquipmentPlaceAddPage from "./widgets/pages/EquipmentPlaceAddPage";
import EquipmentCategoryDetailPage from "./widgets/pages/EquipmentCategoryDetailPage";
import RentalReturnPage from "./widgets/pages/RentalReturnPage";
import CSVImportEquipmentsPage from "./widgets/pages/CSVImportEquipmentsPage";
import CSVImportUsersPage from "./widgets/pages/CSVImportUsersPage";
import CSVImportCustomerGroupPage from "./widgets/pages/CSVImportCustomerGroupPage";
import CSVImportCustomerPeoplePage from "./widgets/pages/CSVImportCustomerPeoplePage";
import CSVImportCategoriesPage from "./widgets/pages/CSVImportCategoriesPage";
import CSVImportPlacesPage from "./widgets/pages/CSVImportPlacesPage";
import FaIconsListPage from "./widgets/pages/FaIconsListPage";
import StaticsPage from "./widgets/pages/StaticsPage";

class PageInfo {
    constructor(props: {
        title: string,
        path: string,
        icon?: IconData,
        parentPageType?: PageType,
        isAdminPage?: boolean,
        isRentalRolePage?: boolean,
    }) {
        this.title = props.title;
        this.path = props.path;
        this.icon = props.icon;
        this.parentPageType = props.parentPageType;
        this.isAdminPage = props.isAdminPage ?? false;
        this.isRentalRolePage = props.isRentalRolePage ?? false;
    };

    icon?: IconData;
    title: string;
    path: string;
    parentPageType?: PageType;
    isAdminPage: boolean;
    isRentalRolePage: boolean

    static map: Map<PageType, PageInfo> = new Map<PageType, PageInfo>()

    static getFromType = (t: PageType): PageInfo | undefined => {
        return this.map.get(t);
    }

    // this should be on the last
    static staticConstructor = (() => {
        var map = PageInfo.map;
        const _ = (a: PageType, b: PageInfo): void => {
            if (map.has(a)) {
                throw `PageInfo duplication error!! ${a.name}`
            }
            map.set(a, b)
        };

        _(RentalListPage,
            new PageInfo({ path: '/rental_list', icon: Icons.ListAlt, title: '貸出一覧', isRentalRolePage: true }))
        _(RentalAndReservationPage, new PageInfo({
            path: '/rental_and_reservation',
            icon: Icons.ShoppingCart,
            title: '貸出 & 予約',
            isRentalRolePage: true,
        }))
        _(RentalDetailPage, new PageInfo({
            path: '/rental_detail/:id',
            icon: undefined,
            title: '貸出詳細',
            isRentalRolePage: true,
            parentPageType: RentalListPage
        }))
        _(RentalReturnPage, new PageInfo({
            path: '/rental_return/:id',
            icon: undefined,
            title: '返却',
            isRentalRolePage: true,
            parentPageType: RentalListPage
        }))
        _(UserManagementPage, new PageInfo({
            path: '/user_management',
            icon: Icons.Group,
            title: 'ユーザ管理',
            isAdminPage: true,
            parentPageType: AdminSettingsPage
        }))
        _(UserDetailPage, new PageInfo({
            path: '/user_detail/:id',
            icon: undefined,
            title: 'ユーザ詳細',
            isAdminPage: true,
            parentPageType: UserManagementPage
        }))
        _(UserAddPage, new PageInfo({
            path: '/user_add/',
            icon: undefined,
            title: 'ユーザ追加',
            isAdminPage: true,
            parentPageType: UserManagementPage
        }))
        _(CustomerManagementPage, new PageInfo({
            path: '/customer_management', icon: Icons.Group, title: '貸出先管理',
            isRentalRolePage: true,
        }))
        _(CustomerGroupDetailPage, new PageInfo({
            path: '/customer_groups/:id',
            icon: undefined,
            title: 'グループ詳細',
            isRentalRolePage: true,
            parentPageType: CustomerManagementPage
        }))
        _(CustomerPersonDetailPage, new PageInfo({
            path: '/customer_people/:id',
            icon: undefined,
            title: '担当者詳細',
            isRentalRolePage: true,
            parentPageType: CustomerManagementPage
        }))
        _(CustomerGroupAddPage, new PageInfo({
            path: '/customer_group_add/',
            icon: undefined,
            title: 'グループ追加',
            isRentalRolePage: true,
            parentPageType: CustomerManagementPage
        }))
        _(CustomerPersonAddPage, new PageInfo({
            path: '/customer_person_add/:id',
            icon: undefined,
            title: '担当者追加',
            isRentalRolePage: true,
            parentPageType: CustomerManagementPage
        }))
        _(EquipmentAddPage, new PageInfo({
            path: '/equipment_add',
            icon: Icons.Add,
            title: '機材追加',
            parentPageType: EquipmentManagementPage
        }))
        _(EquipmentDetailPage, new PageInfo({
            path: '/equipment/:id',
            icon: undefined,
            title: '機材詳細',
            parentPageType: EquipmentManagementPage
        }))
        _(EquipmentBatchEditPage, new PageInfo({
            path: '/equipment_batch_edit',
            icon: Icons.EditRoad,
            title: '機材情報を一括修正',
            parentPageType: EquipmentManagementPage
        }))
        _(NotFoundPage,
            new PageInfo({ path: '/not_found', icon: undefined, title: 'ページが見つかりませんでした' }))
        _(HomePage, new PageInfo({ path: '/', icon: Icons.Home, title: 'ホーム' }))
        _(LoginPage, new PageInfo({ path: '/login', icon: undefined, title: 'ログイン' }))
        _(LogoutPage, new PageInfo({ path: '/logout', icon: Icons.Login, title: 'ログアウト' }))
        _(EquipmentManagementPage, new PageInfo({
            path: '/equipment_management', icon: Icons.Inventory2, title: '機材一覧'
        }))
        _(EquipmentCategoryManagementPage, new PageInfo({
            path: '/equipment_category_management',
            icon: Icons.Category,
            title: 'カテゴリ管理',
            parentPageType: EquipmentManagementPage
        }))
        _(EquipmentCategoryDetailPage, new PageInfo({
            path: '/equipment_category_detail/:id',
            icon: undefined,
            title: 'カテゴリ詳細',
            parentPageType: EquipmentCategoryManagementPage
        }))
        _(EquipmentCategoryAddPage, new PageInfo({
            path: '/equipment_category_add',
            icon: undefined,
            title: 'カテゴリ追加',
            parentPageType: EquipmentCategoryManagementPage
        }))
        _(EquipmentPlaceManagementPage, new PageInfo({
            path: '/equipment_place_management',
            icon: Icons.Place,
            title: '保管場所管理',
            parentPageType: EquipmentManagementPage
        }))
        _(EquipmentPlaceDetailPage, new PageInfo({
            path: '/equipment_place_detail/:id',
            icon: undefined,
            title: '保管場所詳細',
            parentPageType: EquipmentPlaceManagementPage
        }))
        _(EquipmentPlaceAddPage, new PageInfo({
            path: '/equipment_place_add',
            icon: undefined,
            title: '保管場所追加',
            parentPageType: EquipmentPlaceManagementPage
        }))
        _(AdminSettingsPage, new PageInfo({
            path: '/admin_settings',
            icon: Icons.Settings,
            title: '管理者設定',
            isAdminPage: true
        }))
        _(SettingsPage, new PageInfo({
            path: '/settings',
            icon: Icons.AdminPanelSettings,
            title: '設定',
        }))
        _(StaticsPage, new PageInfo({
            path: '/statics',
            icon: Icons.Equalizer,
            title: '統計',
        }))
        _(AccountSettingsPage, new PageInfo({
            path: '/account_settings',
            icon: Icons.Person,
            title: 'アカウント設定',
            parentPageType: SettingsPage,
        }))
        _(CSVImportEquipmentsPage, new PageInfo({
            path: '/csv_import_equipments',
            icon: Icons.Toc,
            title: 'CSVから機材追加',
            parentPageType: SettingsPage,
        }))
        _(CSVImportUsersPage, new PageInfo({
            path: '/csv_import_users',
            icon: Icons.Toc,
            title: 'CSVからユーザ追加',
            parentPageType: SettingsPage,
            isAdminPage: true
        }))
        _(CSVImportCustomerGroupPage, new PageInfo({
            path: '/csv_import_customer_group',
            icon: Icons.Toc,
            title: 'CSVから貸出先グループ追加',
            parentPageType: SettingsPage,
            isRentalRolePage: true
        }))
        _(CSVImportCustomerPeoplePage, new PageInfo({
            path: '/csv_import_customer_people',
            icon: Icons.Toc,
            title: 'CSVから貸出先担当者追加',
            parentPageType: SettingsPage,
            isRentalRolePage: true
        }))
        _(CSVImportCategoriesPage, new PageInfo({
            path: '/csv_import_categories',
            icon: Icons.Toc,
            title: 'CSVからカテゴリ追加',
            parentPageType: SettingsPage,
        }))
        _(CSVImportPlacesPage, new PageInfo({
            path: '/csv_import_places',
            icon: Icons.Toc,
            title: 'CSVから保管場所追加',
            parentPageType: SettingsPage,
        }))
        _(FaIconsListPage, new PageInfo({
            path: '/icons_list',
            icon: Icons.List,
            title: 'アイコン一覧',
            parentPageType: SettingsPage,
        }))
        _(QRScanPage, new PageInfo({
            path: '/qr_scan', icon: Icons.QrCodeScanner, title: 'QR読み取り'
        }))
    })();
}

export default PageInfo;