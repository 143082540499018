import { NavigateFunction, useNavigate } from "react-router-dom";
import PageInfo from './PageInfo';
import PageType from "./PageType";

type PageInfoMap = {
    pageType: PageType,
    info: PageInfo
}

class _Router {
    route: string = ""
    arguments: any = {}

    private _info = (t: PageType): PageInfo | undefined => {
        return PageInfo.getFromType(t);
    }

    private _path = (t: PageType): string | undefined => {
        var info = this._info(t);
        return info !== undefined ? info.path : undefined;
    }

    getRouteOfPage = (t: PageType): string => {
        const route = this._path(t);
        if (route === undefined) {
            throw `Route of page ${t.name} was not found`
        }
        return route!
    }

    getPageInfoFromRoute = (): PageInfoMap | undefined => {
        var val: PageInfoMap | undefined;
        PageInfo.map.forEach((info, page) => {
            if (info.path == this.route) {
                val = {
                    pageType: page,
                    info: info
                }
                return;
            }
        });

        return val
    }

    getMaybeTitleFromRoute = (): string => {
        const map = this.getPageInfoFromRoute()
        if (map === undefined) {
            return ""
        } else {
            return map.info.title
        }
    }

    pushNamed = (route: string, navigate: NavigateFunction): void => {
        navigate(route)
    }

    popAndPushNamed = (route: string, navigate: NavigateFunction): void => {
        navigate(route, {
            replace: true
        })
    }

}

const Router = new _Router();

export default Router;