import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { LoginStateContext } from "../../contexts";
import Router from "../../Router";

const LogoutPage: React.FC = () => {
    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;

    useEffect(() => {
        loginState.setLoggedOut({ isExpired: false })
    }, [loginState])

    return <Navigate to={Router.getRouteOfPage(LogoutPage)} />
}

export default LogoutPage;