import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import IconFa from "../IconFa";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import IntUtils from "../../IntUtils";
import Utils from "../../Utils";
import BaseModel from "../../models/BaseModel";

type CheckResult = {
    loading: boolean,
    data?: {
        result: boolean,
        reason?: string
    }
}

const UpdatedStateContext = createStateContext<UpdateState>();

const LoadingStateContext = createStateContext<boolean>();
const UUIDCheckCt = createStateContext<CheckResult>();
const NumberingCheckCt = createStateContext<CheckResult>();

const AddCountCt = createStateContext<string>();
const PlaceStateContext = createStateContext<string>();
const CategoryStateContext = createStateContext<string>();

const NameStateContext = createStateContext<string>();
const UUIDStateContext = createStateContext<string>();
const NoteStateContext = createStateContext<string>();
const AccessoriesInfoStateContext = createStateContext<string>();
const BihinStickerStateContext = createStateContext<string>();
const NumberingStateContext = createStateContext<string>();

var max_uuid: int = 0;
var max_numbering: int = 0;

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;
type GraphQLFetchResult = FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

const _CategoryDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const categoryStateContext = useContext(CategoryStateContext)

    return (
        <SelectValidator
            className="Required"
            value={categoryStateContext.state}
            label={Const.equipmentCategoryDropDownMenuLabelText}
            name="category"
            onChange={(e: any) => { categoryStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem key={"_"} value={"_"}>{Const.equipmentCategoryNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <IconFa icon={e.iconName} />
                            <SizedBox inline width={5} />
                            <span>{e.name}</span>
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CategoryDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentCategories {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentCategories"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentCategories"]["edges"]
            .map((e: any) => e.node)

        return <_CategoryDropDownImpl items={items} />
    } else {
        return <_CategoryDropDownImpl items={[]} />
    }
}

const _PlaceDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const placeStateContext = useContext(PlaceStateContext)

    return (
        <SelectValidator
            className="Required"
            value={placeStateContext.state}
            label={Const.equipmentPlaceDropDownMenuLabelText}
            name="place"
            onChange={(e: any) => { placeStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem key={"_"} value={"_"}>{Const.equipmentPlaceNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <IconFa icon={e.iconName} />
                            <SizedBox inline width={5} />
                            {e.name}
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _PlaceDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentPlaces {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentPlaces"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentPlaces"]["edges"]
            .map((e: any) => e.node)

        return <_PlaceDropDownImpl items={items} />
    } else {
        return <_PlaceDropDownImpl items={[]} />
    }
}

const UUIDCheck = async (uuid: int | undefined, addCount: int, nav: NavigateFunction): Promise<CheckResult> => {
    if (uuid === undefined) {
        return {
            loading: false,
            data: {
                result: false,
                reason: "機材IDを指定してください"
            }
        }
    }

    const uuid_list: int[] = Utils.range(uuid, uuid + addCount)
    // const uuid_list: int[] = [parseInt(`${uuid}`)]

    // console.log(uuid_list)
    // const uuid_list = [1]

    var result: GraphQLFetchResult;
    try {
        result = await Api.graphQLQuery({
            query: gql`
              query($uuid_list: [Int!]!){
                equipmentItems(uuid_list: $uuid_list) {
                  edges {
                    node {
                      uuid
                    }
                  }
                }
              }
            `,
            variables: { 'uuid_list': uuid_list },
            fetchPolicy: 'no-cache'
        })
    } catch (e) {
        Api.handleGraphQLException(e, nav);
    }

    // console.log(result?.data)

    if (result?.data !== undefined &&
        result.data?.['equipmentItems']?.['edges'] !== undefined) {
        if (result.data?.['equipmentItems']['edges'].length > 0) {
            const uuids: int[] = result.data?.['equipmentItems']['edges']
                .map((e: any) => e['node']['uuid'] as int)
                .sort();
            const res: string = uuids.map((e) => `#${e}`).join(", ");

            // console.log(res)

            return {
                loading: false,
                data: {
                    result: false,
                    reason: `${res} は既に存在します`
                }
            }
        }
    }

    // console.log("OK")

    return {
        loading: false,
        data: {
            result: true,
        }
    }
}

const NumberingCheck = async (name: string, categoryId: string | undefined, numbering: int | undefined, addCount: int, nav: NavigateFunction): Promise<CheckResult> => {
    if (numbering === undefined) {
        return {
            loading: false,
            data: {
                result: false,
                reason: "通し番号を指定してください"
            }
        }
    }

    if (name === "") {
        return {
            loading: false,
            data: {
                result: false,
                reason: "機材名を指定してください"
            }
        }
    }

    const categoryIdNum: int | undefined = categoryId !== undefined ? BaseModel.getIdNumFromString(categoryId) : undefined

    const numbering_list: int[] = Utils.range(numbering, numbering + addCount)
    // const uuid_list: int[] = [parseInt(`${uuid}`)]

    // console.log(uuid_list)
    // const uuid_list = [1]

    var result: GraphQLFetchResult;
    try {
        result = await Api.graphQLQuery({
            query: gql`
              query($name: String!, $category_id: Int, $numbering_list: [Int!]!){
                equipmentItems(name: $name, category_id: $category_id, numbering_list: $numbering_list) {
                  edges {
                    node {
                      numbering
                    }
                  }
                }
              }
            `,
            variables: {
                'numbering_list': numbering_list,
                'name': name,
                'category_id': categoryIdNum,
            },
            fetchPolicy: 'no-cache'
        })
    } catch (e) {
        Api.handleGraphQLException(e, nav);
    }

    // console.log(result?.data)

    if (result?.data !== undefined &&
        result.data?.['equipmentItems']?.['edges'] !== undefined) {
        if (result.data?.['equipmentItems']['edges'].length > 0) {
            const uuids: int[] = result.data?.['equipmentItems']['edges']
                .map((e: any) => e['node']['numbering'] as int)
                .sort();
            const res: string = uuids.map((e) => `No.${e}`).join(", ");

            // console.log(res)

            return {
                loading: false,
                data: {
                    result: false,
                    reason: `${name} ${res} は既に存在します`
                }
            }
        }
    }

    // console.log("OK")

    return {
        loading: false,
        data: {
            result: true,
        }
    }
}

const UUIDCheckResultWidget: React.FC = (props) => {

    const nav = useNavigate()
    const addCountCt = useContext(AddCountCt);
    const addCount_base = IntUtils.tryParse(addCountCt.state) !== undefined ? parseInt(addCountCt.state) : 1;
    const addCount: int = (addCount_base < 1) ? 1 : addCount_base;

    const nameContext = useContext(NameStateContext);
    const uuidContext = useContext(UUIDStateContext);
    const uuid = IntUtils.tryParse(uuidContext.state) !== undefined ? parseInt(uuidContext.state) : undefined;

    const uuidCheckCt = useContext(UUIDCheckCt);

    useEffect(() => {
        (async () => {
            const res = await UUIDCheck(uuid, addCount, nav)
            uuidCheckCt.setState(res)
        })()
    }, [uuid, addCount])

    if (uuidCheckCt.state.loading) {
        return <div className="CheckResult Loading">確認中...</div>
    }

    if (uuidCheckCt.state.data?.result === false) {
        return <div className="CheckResult Error">{uuidCheckCt.state.data?.reason}</div>
    }

    return <div className="CheckResult OK">OK</div>
}

const NumberingCheckResultWidget: React.FC = (props) => {

    const nav = useNavigate()
    const addCountCt = useContext(AddCountCt);
    const addCount_base = IntUtils.tryParse(addCountCt.state) !== undefined ? parseInt(addCountCt.state) : 1;
    const addCount: int = (addCount_base < 1) ? 1 : addCount_base;

    const nameContext = useContext(NameStateContext);
    const categoryContext = useContext(CategoryStateContext);
    const numberingContext = useContext(NumberingStateContext);
    const numbering = IntUtils.tryParse(numberingContext.state) !== undefined ? parseInt(numberingContext.state) : undefined;
    const name = nameContext.state
    const category = (categoryContext.state !== "" && categoryContext.state !== "_") ? categoryContext.state : undefined

    const numberingCheckCt = useContext(NumberingCheckCt);

    useEffect(() => {
        (async () => {
            const res = await NumberingCheck(name, category, numbering, addCount, nav)
            numberingCheckCt.setState(res)
        })()
    }, [name, category, numbering, addCount])

    if (numberingCheckCt.state.loading) {
        return <div className="CheckResult Loading">確認中...</div>
    }

    if (numberingCheckCt.state.data?.result === false) {
        return <div className="CheckResult Error">{numberingCheckCt.state.data?.reason}</div>
    }

    return <div className="CheckResult OK">OK</div>
}

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const addCountCt = useContext(AddCountCt);
    const addCount_base = IntUtils.tryParse(addCountCt.state) !== undefined ? parseInt(addCountCt.state) : 1;
    const addCount: int = (addCount_base < 1) ? 1 : addCount_base;

    const nameContext = useContext(NameStateContext);
    const uuidContext = useContext(UUIDStateContext);
    const noteContext = useContext(NoteStateContext);
    const accessoriesInfoContext = useContext(AccessoriesInfoStateContext);
    const bihinStickerContext = useContext(BihinStickerStateContext);
    const numberingContext = useContext(NumberingStateContext);

    const updatedContext = useContext(UpdatedStateContext);
    const placeContext = useContext(PlaceStateContext);
    const categoryContext = useContext(CategoryStateContext);

    const uuidCheckCt = useContext(UUIDCheckCt)
    const numberingCheckCt = useContext(NumberingCheckCt)

    // console.log(parseInt(numberingContext.state) + addCount - 1)
    // console.log(parseInt(numberingContext.state))
    // console.log(addCount)


    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                if (uuidCheckCt.state?.data?.result !== true) {
                    return
                }

                if (numberingCheckCt.state?.data?.result !== true) {
                    return
                }

                const name = nameContext.state;
                const uuid: int = parseInt(uuidContext.state);
                const note = noteContext.state;
                const accessoriesInfo = accessoriesInfoContext.state;
                const bihinSticker = bihinStickerContext.state;
                const numbering: int = parseInt(numberingContext.state)
                const placeId = placeContext.state !== "_" ? placeContext.state : undefined;
                const categoryId = categoryContext.state !== "_" ? categoryContext.state : undefined;

                const queryStr = `
                  mutation($uuid: Int!, $numbering: Int!, $name: String!, $note: String, $accessoriesInfo: String, $bihinSticker: String, $category: String, $place: String, $aliveStatus: String! ) {
                    createEquipmentItem(input: {uuid: $uuid, numbering: $numbering, name: $name, note: $note, accessoriesInfo: $accessoriesInfo, bihinSticker: $bihinSticker, category: $category, place: $place, aliveStatus: $aliveStatus}) {
                      equipmentItem {
                        id
                        name
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
                // var vars = {}

                var baseVariables = {
                    // 'uuid': uuid !== "" ? parseInt(uuid) : undefined,
                    // 'numbering': numbering !== "" ? parseInt(numbering) : undefined,
                    'name': (name == "")
                        ? undefined
                        : name,
                    'note': (note == "")
                        ? undefined
                        : note,
                    'accessoriesInfo': (accessoriesInfo == "")
                        ? undefined
                        : accessoriesInfo,
                    'bihinSticker': (bihinSticker == "")
                        ? undefined
                        : bihinSticker,
                    'category': categoryId,
                    'place': placeId,
                    'aliveStatus': EquipmentAliveStatus.Alive.toString()
                }

                var allOK = true;

                for (var i = 0; i < addCount; ++i) {

                    try {
                        result = await Api.graphQLClient?.mutate({
                            mutation: query,
                            variables: {
                                ...baseVariables,
                                'uuid': uuid + i,
                                'numbering': numbering + i
                            }
                        })
                    } catch (e) {
                        Api.handleGraphQLException(e, nav)
                    }

                    // console.log(queryStr)
                    // console.log(JSON.stringify(vars))

                    if (result?.data != undefined &&
                        result?.data?.["createEquipmentItem"] != undefined &&
                        result?.data?.["createEquipmentItem"]?.["equipmentItem"] !=
                        undefined) {

                        // OK. go next

                    } else {
                        updatedContext.setState(UpdateState.Failed)
                        console.error("Update failed");
                        console.error(result);

                        allOK = false
                        break
                    }
                }

                if (allOK) {
                    updatedContext.setState(UpdateState.Succeeeded)

                    // await refetch();

                    nameContext.setState("");
                    uuidContext.setState("");
                    noteContext.setState("");
                    accessoriesInfoContext.setState("");
                    bihinStickerContext.setState("");
                    numberingContext.setState("");
                    placeContext.setState("_");
                    categoryContext.setState("_");
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(EquipmentManagementPage)!, nav)
                }
            }}
        >

            <TextValidator
                className="Required"
                label={Const.equipmentCountLabelText}
                onChange={(e: any) => addCountCt.setState(e.target.value)}
                name="add_count"
                value={addCountCt.state}
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">
                //             {"#"}
                //             {/* <Icons.Tag /> */}
                //         </InputAdornment>
                //     ),
                // }}
                validators={['required', 'isNumber', 'isPositive']}
                errorMessages={[ValidatorMessages.required, ValidatorMessages.positiveNumber, ValidatorMessages.positiveNumber]}
            />

            <SizedBox height={10} />

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={5}>
                    <TextValidator
                        className="Required"
                        label={Const.equipmentUuidLabelText}
                        onChange={(e: any) => uuidContext.setState(e.target.value)}
                        name="uuid"
                        value={uuidContext.state}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {"#"}
                                    {/* <Icons.Tag /> */}
                                </InputAdornment>
                            ),
                        }}
                        validators={['required', 'isNumber', 'isPositive']}
                        errorMessages={[ValidatorMessages.required, ValidatorMessages.positiveNumber, ValidatorMessages.positiveNumber]}
                    />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <span>〜</span>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        disabled variant="outlined"
                        value={
                            (IntUtils.tryParse(uuidContext.state) !== undefined)
                                ? (parseInt(uuidContext.state) + addCount - 1)
                                : ""
                        }
                    />
                </Grid>
            </Grid>

            <SizedBox height={3} />
            <UUIDCheckResultWidget />
            <SizedBox height={10} />

            <_CategoryDropDown />
            <SizedBox height={3} />
            <Link to={Router.getRouteOfPage(EquipmentCategoryManagementPage)!}>
                {"カテゴリの管理"}
            </Link>

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={Const.equipmentNameLabelText}
                onChange={(e: any) => nameContext.setState(e.target.value)}
                name="name"
                value={nameContext.state}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={5}>
                    <TextValidator
                        className="Required"
                        label={Const.equipmentNumberingLabelText}
                        onChange={(e: any) => numberingContext.setState(e.target.value)}
                        name="numbering"
                        value={numberingContext.state}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {"No."}
                                    {/* <Icons.Tag /> */}
                                </InputAdornment>
                            ),
                        }}
                        validators={['required', 'isNumber', 'isPositive']}
                        errorMessages={[ValidatorMessages.required, ValidatorMessages.positiveNumber, ValidatorMessages.positiveNumber]}
                    />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <span>〜</span>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        disabled variant="outlined"
                        value={
                            (IntUtils.tryParse(numberingContext.state) !== undefined)
                                ? (parseInt(numberingContext.state) + addCount - 1)
                                : ""
                        }
                    />
                </Grid>
            </Grid>

            <SizedBox height={3} />
            <NumberingCheckResultWidget />
            <SizedBox height={10} />

            <_PlaceDropDown />
            <SizedBox height={3} />
            <Link to={Router.getRouteOfPage(EquipmentPlaceManagementPage)!}>
                {"保管場所の管理"}
            </Link>

            <SizedBox height={10} />

            <TextValidator
                label={Const.equipmentNoteLabelText}
                onChange={(e: any) => noteContext.setState(e.target.value)}
                name="note"
                value={noteContext.state}
            />

            <SizedBox height={10} />

            <TextValidator
                label={Const.equipmentAccessoriesInfoLabelText}
                onChange={(e: any) => accessoriesInfoContext.setState(e.target.value)}
                name="accessoriesInfo"
                value={accessoriesInfoContext.state}
            />

            <SizedBox height={10} />

            <TextValidator
                label={Const.equipmentBihinStickerLabelText}
                onChange={(e: any) => bihinStickerContext.setState(e.target.value)}
                name="bihinSticker"
                value={bihinStickerContext.state}
            />

            {/* <SizedBox height={10} /> */}

            {/* <SizedBox height={10} />

            <TextValidator
                label={Const.telLabelText}
                onChange={(e: any) => telContext.setState(e.target.value)}
                name="tel"
                value={telContext.state}
            /> */}

            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.addNewButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const loadingContext = useContext(LoadingStateContext);
    const isLoading = loadingContext.state;

    useEffect(() => {
        (async () => {
            const res = await Api.getJson("max_equipment_uuid");
            max_uuid = res?.data?.max_uuid !== undefined ? parseInt(res?.data?.max_uuid) : 0;

            // const res = await Api.getJson("max_equipment_uuid");
            // max_uuid = res?.data?.max_uuid !== undefined ? parseInt(res?.data?.max_uuid) : 0;

            // console.log(res?.data?.max_uuid)
            loadingContext.setState(false)
        })()
    }, [])

    const name = "";
    const uuid = (max_uuid + 1).toString();
    const note = "";
    const accessoriesInfo = "";
    const bihinSticker = "";
    const numbering = "";
    const place = "_";
    const category = "_";

    if (isLoading) {
        return <Loading />
    }

    // console.log(max_uuid)

    return (
        <StateProvider context={NameStateContext} defaultValue={name}>
            <StateProvider context={UUIDStateContext} defaultValue={uuid}>
                <StateProvider context={NoteStateContext} defaultValue={note}>
                    <StateProvider context={AccessoriesInfoStateContext} defaultValue={accessoriesInfo}>
                        <StateProvider context={BihinStickerStateContext} defaultValue={bihinSticker}>
                            <StateProvider context={NumberingStateContext} defaultValue={numbering}>
                                <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                    <StateProvider context={PlaceStateContext} defaultValue={place}>
                                        <StateProvider context={CategoryStateContext} defaultValue={category}>
                                            <StateProvider context={AddCountCt} defaultValue={"1"}>
                                                <StateProvider context={UUIDCheckCt} defaultValue={{ loading: true }}>
                                                    <StateProvider context={NumberingCheckCt} defaultValue={{ loading: true }}>
                                                        <_FormImpl />
                                                    </StateProvider>
                                                </StateProvider>
                                            </StateProvider>
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )
}

const EquipmentAddPage: React.FC = (props) => {
    return (
        <StateProvider context={LoadingStateContext} defaultValue={true}>
            <_Form />
        </StateProvider>
    )
}

export default EquipmentAddPage;