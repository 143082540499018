import { ApolloQueryResult, FetchResult, gql, useQuery } from "@apollo/client";
import { Button, TextareaAutosize } from "@mui/material";
import { EEXIST } from "constants";
import Papa from "papaparse";
import { listenerCount } from "process";
import React, { useCallback, useContext, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import Api from "../../Api";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import int from "../../int";
import IntUtils from "../../IntUtils";
import BaseModel from "../../models/BaseModel";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateOf from "../../states/StateOf";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Utils from "../../Utils";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import CustomerManagementPage from "./CustomerManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";

type GraphQLFetchResult = FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
type CheckResult = {
    loading: boolean,
    data?: {
        result: boolean,
        reason?: string
    }
}

type CustomerPersonProtoProto = {
    name: string,
    groupName: string,
    firstName: string,
    familyName: string,
    email: string,
    tel: string
}
type CustomerPersonProto = {
    name: string,
    groupId: string | undefined,
    firstName: string,
    familyName: string,
    email: string,
    tel: string
}
type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const CSVCt = createStateContext<CustomerPersonProto[] | null>()
const CSVErrorCt = createStateContext<StateOf<Papa.ParseError[] | string | null>>()
const GroupsCt = createStateContext<Map<string, string> | null>()
const GroupsRevCt = createStateContext<Map<string, string> | null>()

const UUIDCheckCt = createStateContext<StateOf<Map<int, CheckResult>>>()
const NumberingCheckCt = createStateContext<StateOf<Map<int, CheckResult>>>()

const UpdatedStateContext = createStateContext<UpdateState>()

const GroupListLoader: React.FC = (props) => {
    const groupsCt = useContext(GroupsCt);
    const groupsRevCt = useContext(GroupsRevCt);
    const nav = useNavigate()
    const query = gql`
      query($order: [CustomerGroupFilter_order]) {
        customerGroups(order: $order) {
          edges {
            node {
              id
              _id
              name
              persons {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: { 'order': [] },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    useEffect(() => {
        if (result.loading) {
            return
        }

        if (result.data && result.data?.["customerGroups"]?.["edges"] !== undefined) {
            const items: Array<any> = result.data!["customerGroups"]["edges"]
                .map((e: any) => e.node)

            var map = new Map<string, string>()
            items.forEach((e) => {
                map.set(e.name, e.id)
            })
            groupsCt.setState(map)

            var mapRev = new Map<string, string>()
            items.forEach((e) => {
                mapRev.set(e.id, e.name)
            })
            groupsRevCt.setState(mapRev)
        } else {
            groupsCt.setState(new Map())
            groupsRevCt.setState(new Map())

        }
    }, [result.data])

    return <React.Fragment />
}

const parsedToEquipmentProtos = (parsed: string[][], groupMap: Map<string, string>): CustomerPersonProto[] => {
    var list: CustomerPersonProtoProto[] = []
    for (const e of parsed) {
        const p = {
            groupName: e[0],
            familyName: e[1],
            firstName: e[2],
            email: e[3],
            tel: e[4],
        } as CustomerPersonProtoProto
        list.push(p)
    }

    console.log(list)
    console.log(groupMap)

    const filtered = list.filter((e) =>
        groupMap.get(e.groupName) !== undefined || e.groupName === ""
    )

    return filtered.map((e) => (
        {
            groupId: e.groupName === "" ? undefined : groupMap.get(e.groupName),
            familyName: e.familyName,
            firstName: e.firstName,
            email: e.email,
            tel: e.tel,
        } as CustomerPersonProto
    ))

}

const _FormImpl: React.FC<{}> = (props) => {
    // const loginContext = useContext(LoginStateContext);
    // const loginState = loginContext.state.instance;
    // const user = loginState.user;

    const groupsCt = useContext(GroupsCt)
    const groupsRevCt = useContext(GroupsRevCt)
    const headerList = "グループ名,姓,名,メールアドレス,電話番号"
    const csvCt = useContext(CSVCt)
    const csvErrorCt = useContext(CSVErrorCt)
    const csvData: CustomerPersonProto[] | null = csvCt.state

    const uuidCheckCt = useContext(UUIDCheckCt);
    const numberingCheckCt = useContext(NumberingCheckCt);
    const uuidAlllOK = Array.from(uuidCheckCt.state.instance.keys()).filter((k) => {
        const e = uuidCheckCt.state.instance.get(k)!
        return e.data?.result !== true
    }).length === 0
    const numberingAllOK = Array.from(numberingCheckCt.state.instance.keys()).filter((k) => {
        const e = numberingCheckCt.state.instance.get(k)!
        return e.data?.result !== true
    }).length === 0

    const updatedContext = useContext(UpdatedStateContext)
    const nav = useNavigate()

    var temporaryCSV: string;

    return (
        <React.Fragment>
            <TextareaAutosize
                minRows={3}
                placeholder="ここにCSVファイルの中身を貼り付けてください"
                onChange={(e) => {
                    temporaryCSV = e.target.value
                }}
                style={{ width: 300, height: 200 }}
            />
            <div>
                ※ CSVの形式は、{headerList} です。
            </div>
            <SizedBox height={5} />
            <div onClick={() => {
                const parsed = Papa.parse<string[]>(temporaryCSV)
                if (parsed.errors.length > 0) {
                    csvCt.setState(null)
                    csvErrorCt.setState(new StateOf(parsed.errors))
                }

                const protos = parsedToEquipmentProtos(parsed.data, groupsCt.state!)

                csvErrorCt.setState(new StateOf(null))
                csvCt.setState(protos)

                uuidCheckCt.setState(new StateOf(new Map()))
                numberingCheckCt.setState(new StateOf(new Map()))

                // console.log(parsed)
            }}>
                <Button variant="contained">
                    確認
                </Button>
            </div>

            {
                (csvErrorCt.state.instance !== null)
                    ? (
                        <React.Fragment>
                            <div>
                                CSVにエラーがあります
                            </div>

                            <div>
                                {JSON.stringify(csvErrorCt.state.instance)}
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length > 0)
                    ? (
                        <React.Fragment>
                            <div>
                                <table>
                                    <tr>
                                        {
                                            headerList.split(",").map((e) =>
                                                <th>{e}</th>
                                            )
                                        }
                                        {/* <th>(確認)</th> */}
                                    </tr>
                                    {
                                        // categoryName: string,
                                        // placeName: string,
                                        // numbering: int | undefined,
                                        // note: string,
                                        // accessoriesInfo: string,
                                        // bihinSticker: string
                                        csvData.map((e) =>
                                            <tr>
                                                <td>{e.groupId !== undefined ? groupsRevCt.state?.get(e.groupId) : "未指定"}</td>
                                                <td>{e.familyName}</td>
                                                <td>{e.firstName}</td>
                                                <td>{e.email}</td>
                                                <td>{e.tel}</td>
                                                {/* <td> */}
                                                {/* <UUIDCheckResultWidget uuid={e.uuid} /> */}
                                                {/* <NumberingCheckResultWidget uuid={e.uuid} name={e.name} category={e.categoryId} numbering={e.numbering} /> */}
                                                {/* </td> */}
                                            </tr>
                                        )
                                    }
                                </table>
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length === 0)
                    ? <div>有効な行はありませんでした。グループが事前に登録してあるかを確認してください。</div>
                    : <React.Fragment />
            }
            <SizedBox height={5} />
            {
                (csvData !== null && csvData.length > 0 && uuidAlllOK && numberingAllOK)
                    ?
                    <div onClick={async () => {
                        // if (uuidCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        // if (numberingCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        const queryStr = `
                          mutation($familyName: String!, $firstName: String!, $email: String, $tel: String, $customerGroup: String) {
                            createCustomerPerson(input: {familyName: $familyName, firstName: $firstName, email: $email, tel: $tel, customerGroup: $customerGroup}) {
                              customerPerson {
                                id
                              }
                              clientMutationId
                            }
                          }
                        `

                        const query = gql(queryStr)

                        var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
                        var vars = {}

                        var allOK = true;

                        var n = 0;

                        for (const e of csvCt.state!) {
                            ++n;

                            try {
                                result = await Api.graphQLClient?.mutate({
                                    mutation: query,
                                    variables: vars = {
                                        'familyName': e.familyName,
                                        'firstName': e.firstName,
                                        'email': e.email == ""
                                            ? undefined
                                            : e.email,
                                        'tel': e.tel == ""
                                            ? undefined
                                            : e.tel,
                                        'customerGroup': e.groupId,
                                    }
                                })
                            } catch (e) {
                                Api.handleGraphQLException(e, nav)
                            }

                            // console.log(queryStr)
                            // console.log(JSON.stringify(vars))

                            if (result?.data != undefined &&
                                result?.data?.["createEquipmentItem"] != undefined &&
                                result?.data?.["createEquipmentItem"]?.["equipmentItem"] !=
                                undefined) {

                                // OK. go next

                            } else {
                                updatedContext.setState(UpdateState.Failed)
                                console.error(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                alert(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                console.error(result);

                                allOK = false
                                break
                            }
                        }

                        if (allOK) {
                            updatedContext.setState(UpdateState.Succeeeded)

                            csvCt.setState(null);
                            csvErrorCt.setState(new StateOf(null));
                            Api.graphQLResetCache()

                            Router.popAndPushNamed(Router.getRouteOfPage(CustomerManagementPage)!, nav)
                        }
                    }}>
                        <Button variant="contained">
                            インポートを実行
                        </Button>
                    </div>
                    : <Button disabled variant="contained">
                        インポートを実行
                    </Button>
            }
        </React.Fragment>
    );
}

const _FormWrap: React.FC = (props) => {
    const groupsCt = useContext(GroupsCt)
    return (
        <React.Fragment>
            <GroupListLoader />
            {
                (groupsCt.state !== null)
                    ? <_FormImpl />
                    : <React.Fragment />
            }
        </React.Fragment>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()


    if (true) {
        return (
            <StateProvider context={CSVCt} defaultValue={null}>
                <StateProvider context={CSVErrorCt} defaultValue={new StateOf(null)}>
                    <StateProvider context={GroupsCt} defaultValue={null}>
                        <StateProvider context={GroupsRevCt} defaultValue={null}>
                            <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                <StateProvider context={UUIDCheckCt} defaultValue={new StateOf(new Map())}>
                                    <StateProvider context={NumberingCheckCt} defaultValue={new StateOf(new Map())}>
                                        <_FormWrap />
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>エラーが発生しました</Center>
}

const CSVImportCustomerPeoplePage: React.FC = (props) => {
    return (
        <_Form />
    )
}

export default CSVImportCustomerPeoplePage;