import { Box, Button, Grid, InputAdornment } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { LoginStateContext } from "../../contexts";
import ValidatorMessages from "../../ValidatorMessages";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import Const from "../../Const";
import ErrorWidget from "../ErrorWidget";
import Center from "../Center";
import Api from "../../Api";
import UpdateState from "../../states/UpdateState";
import Router from "../../Router";
import SettingsPage from "./SettingsPage";
import { useNavigate } from "react-router";
import UpdatedLabel from "../UpdatedLabel";
import LoginPage from "./LoginPage";

var email: string = ""
const EmailStateContext = createStateContext<string>();
const PasswordStateContext = createStateContext<string>();
const FirstNameStateContext = createStateContext<string>();
const FamilyNameStateContext = createStateContext<string>();
const TelStateContext = createStateContext<string>();
const UpdatedStateContext = createStateContext<UpdateState>();

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const _FormImpl: React.FC<{ refetch: RefetchType }> = (props) => {
    const refetch = props.refetch;
    const nav = useNavigate();

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const emailContext = useContext(EmailStateContext);
    const passwordContext = useContext(PasswordStateContext);
    const firstNameContext = useContext(FirstNameStateContext);
    const familyNameContext = useContext(FamilyNameStateContext);
    const telContext = useContext(TelStateContext);
    const updatedContext = useContext(UpdatedStateContext);

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                var id = loginState.user!.id;
                var email = emailContext.state;
                var password = passwordContext.state;
                var firstName = firstNameContext.state;
                var familyName = familyNameContext.state;
                var tel = telContext.state;

                const queryStr = `
                mutation($id: ID!, $familyName: String!, $firstName: String!, $email: String!, $tel: String, <<PASSWORD_PARAM1>>) {
                  updateUser(input: {id: $id, familyName: $familyName, firstName: $firstName, email: $email, tel: $tel, <<PASSWORD_PARAM2>>}) {
                    user {
                      id
                    }
                    clientMutationId
                  }
                }
                `
                    .replaceAll(
                        "<<PASSWORD_PARAM1>>",
                        password != ""
                            ? "\$plainPassword: String"
                            : "",
                    )
                    .replaceAll(
                        "<<PASSWORD_PARAM2>>",
                        password != ""
                            ? "plainPassword: \$plainPassword"
                            : "",
                    );

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'id': id,
                            'familyName': familyName,
                            'firstName': firstName,
                            'email': email == ""
                                ? undefined
                                : email,
                            'tel': tel == ""
                                ? undefined
                                : tel,
                            'plainPassword': password == ""
                                ? undefined
                                : password
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["updateUser"] != undefined &&
                    result?.data?.["updateUser"]?.["user"] !=
                    undefined) {

                    await loginState.setLoggedOut({ isExpired: true })

                    // await loginState.updateUserInfo(email);
                    updatedContext.setState(UpdateState.Succeeeded)

                    await refetch();
                    Api.graphQLResetCache()

                    Router.pushNamed(Router.getRouteOfPage(LoginPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.familyNameLabelText}
                        onChange={(e: any) => familyNameContext.setState(e.target.value)}
                        name="familyName"
                        value={familyNameContext.state}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.firstNameLabelText}
                        onChange={(e: any) => firstNameContext.setState(e.target.value)}
                        name="firstName"
                        value={firstNameContext.state}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label="ID"
                onChange={(e: any) => emailContext.setState(e.target.value)}
                name="email"
                value={emailContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icons.PermIdentity />
                        </InputAdornment>
                    ),
                }}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <TextValidator
                // className="Required"
                label={Const.userNewPasswordLabelText}
                onChange={(e: any) => passwordContext.setState(e.target.value)}
                type="password"
                name="password"
                value={passwordContext.state}
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">
                //             <Icons.VpnKey />
                //         </InputAdornment>
                //     ),
                // }}
                validators={[]}
                errorMessages={[]}
            />
            <SizedBox height={10} />
            <TextValidator
                label={Const.telLabelText}
                onChange={(e: any) => telContext.setState(e.target.value)}
                name="tel"
                value={telContext.state}
            />
            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>
        </ValidatorForm>
    )
}

const _Form: React.FC = () => {
    const nav = useNavigate()

    const query = gql`
      query users($email: String!){
        users(email: $email){
          edges {
            node {
              id
              email
              firstName
              familyName
              tel
              roles
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: {
            'email': email
        }
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    const data = result.data;

    if (data != null &&
        data?.["users"]?.["edges"] != null &&
        data?.["users"]?.["edges"]?.[0]?.["node"] != null) {

        const node = data["users"]["edges"][0]["node"];
        const familyName = node.familyName;
        const firstName = node.firstName;
        const tel = node.tel;

        return (
            <StateProvider context={FamilyNameStateContext} defaultValue={familyName}>
                <StateProvider context={FirstNameStateContext} defaultValue={firstName}>
                    <StateProvider context={EmailStateContext} defaultValue={email}>
                        <StateProvider context={TelStateContext} defaultValue={tel}>
                            <StateProvider context={PasswordStateContext} defaultValue={""}>
                                <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                    <_FormImpl refetch={result.refetch} />
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider >
        )
    } else {
        return <ErrorWidget />
    }
}

const AccountSettingsPage: React.FC = () => {
    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance
    email = loginState.user!.email;

    return (
        <_Form />
    );
}

export default AccountSettingsPage;