import React from "react";

const NotFoundPage: React.FC = () => {
    return (
        <React.Fragment>
            お探しのページは見つかりませんでした
        </React.Fragment>
    );
}

export default NotFoundPage;