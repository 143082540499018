import { AlertColor } from "@mui/material";
import int from "../int";

class SnackBarState {
    isOpen: boolean = false
    message: string = ""
    severity: AlertColor = "success"
    autoHideDuration: int = 3000
}

export default SnackBarState;