import React, { useContext, useEffect } from 'react';
import Equipment from '../models/Equipment';
import RentalReturnLog from '../models/RentalReturnLog';
import * as Icons from "@mui/icons-material";
import { Button, List, ListItem } from '@mui/material';
import SizedBox from './SizedBox';
import StateOf from '../states/StateOf';
import StateContextProp from '../states/StateContextProp';
import { CartContext } from '../contexts';
import { Link } from 'react-router-dom';
import Router from '../Router';
import EquipmentDetailPage from './pages/EquipmentDetailPage';
import { Box } from '@mui/system';
import Const from '../Const';
import IconFa from './IconFa';
import RentalStatus from '../models/RentalStatus';

type StateOfArrayEquipment = StateOf<Array<Equipment>>
type ContextOfArrayEquipment = StateContextProp<StateOfArrayEquipment>

const _ItemsList: React.FC<{ equipmentItems: Equipment[], equipmentItemsContext: ContextOfArrayEquipment, rentalReturnLogs: RentalReturnLog[] }> = (props) => {

    const equipmentItems = props.equipmentItems
    const equipmentItemsContext = props.equipmentItemsContext
    const rentalReturnLogs = props.rentalReturnLogs

    return (
        <List className="CartItemsList">
            {
                equipmentItems.map((e) => {
                    // console.log(rentalReturnLogs)

                    const isReturned: boolean = e.isReturedInLogs(rentalReturnLogs);
                    const log: RentalReturnLog | undefined = e.getRentalReturnLog(rentalReturnLogs);

                    return (
                        <ListItem key={e.id}>
                            {
                                (isReturned) ?
                                    <div style={{ display: "flex", justifyContent: "center", width: "64px", paddingLeft: "8px", paddingRight: "8px" }}>
                                        <Icons.Check style={{ color: "#00730b" }} />
                                    </div>
                                    : (
                                        <div onClick={() => {
                                            const newItems = equipmentItems.filter((_e) => _e.id !== e.id)
                                            equipmentItemsContext.setState(new StateOf(newItems))
                                        }}>
                                            <Button>
                                                <Icons.Clear /> (削除)
                                            </Button>
                                        </div>
                                    )
                            }
                            <Link className="EquipmentViewReadableName" to={
                                Router.getRouteOfPage(EquipmentDetailPage)
                                    .replace(":id", Equipment.getIdNumFromString(e.id!)!.toString())
                            }
                            >
                                <IconFa icon={e?.category?.iconName} />
                                <SizedBox inline width={3} />
                                {e.getReadableName()}
                            </Link >
                            {
                                (isReturned)
                                    ? (
                                        <React.Fragment>
                                            <SizedBox inline width={5} />
                                            <Box className="EquipmentsViewReturned">
                                                <Icons.DoneOutline />
                                                <SizedBox inline width={3} />
                                                <span className="EquipmentsViewReturnedContent">{`${Const.rentalReturnedText} ( ${log?.getReturnedDateReadable()}, 受取人: ${log?.getUserName()} )`}</span>
                                                {
                                                    log && log.returnNote !== null && log.returnNote !== "" ?
                                                        <span>&nbsp;(メモ: { `${log.returnNote}` })</span>
                                                        : <React.Fragment />
                                                }
                                            </Box>
                                        </React.Fragment>
                                    )
                                    : <React.Fragment></React.Fragment>
                            }
                        </ListItem>
                    )
                })
            }
            {
                (equipmentItems.length === 0)
                    ? <ListItem key={"_"}>
                        なし
                    </ListItem>
                    : <React.Fragment></React.Fragment>
            }
        </List >
    )
}

const RentalDetailPageEquipmentsViewImpl: React.FC<{ equipmentItems: Equipment[], equipmentItemsContext: ContextOfArrayEquipment, rentalReturnLogs: RentalReturnLog[], rentalStatus: RentalStatus | undefined }> = (props) => {

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const items = props.equipmentItems
    const equipmentItemsContext = props.equipmentItemsContext
    const rentalStatus = props.rentalStatus

    return (
        <div className="EquipmentsView">
            <div className="EquipmentsViewHeader">
                <Icons.Inventory2Outlined />
                <SizedBox inline width={3} />
                <span>{`機材品目 (${items.length})`}</span>
                <div className="EquipmentsViewHeaderCartButton" onClick={() => {
                    const newItems: Equipment[] =
                        [...cartController.getEquipmentItems(), ...items];
                    equipmentItemsContext.setState(new StateOf(newItems))

                    // cartController.clear();
                    // cartContext.setState(new StateOf(cartController))
                }}>
                    <React.Fragment>
                        {
                            (() => {
                                if (rentalStatus !== RentalStatus.Completed) {
                                    return <Button variant="contained" disabled={cartController.getLength() === 0}>
                                        <Icons.ShoppingCartRounded />
                                        <SizedBox inline width={3} />
                                        {`カート内の機材 (${cartController.getLength()}) をここにコピー`}
                                    </Button>
                                }
                            })()
                        }
                    </React.Fragment>
                </div>
            </div>
            <div className="EquipmentsViewContent">
                <_ItemsList equipmentItems={items} equipmentItemsContext={equipmentItemsContext} rentalReturnLogs={props.rentalReturnLogs} />
            </div>
        </div>
    )
}

const RentalDetailPageEquipmentsView: React.FC<{ equipmentItemsContext: ContextOfArrayEquipment, rentalReturnLogs: RentalReturnLog[], rentalStatus: RentalStatus | undefined }> = (props) => {
    const equipmentItems: Equipment[] = props.equipmentItemsContext.state.instance;

    return (
        <RentalDetailPageEquipmentsViewImpl
            equipmentItems={equipmentItems}
            equipmentItemsContext={props.equipmentItemsContext}
            rentalReturnLogs={props.rentalReturnLogs}
            rentalStatus={props.rentalStatus}
        />
    );
}

export default RentalDetailPageEquipmentsView;