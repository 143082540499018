import { Box, Button, Grid, List, ListItemButton, Radio, RadioGroup } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import Api from "../../Api";
import Const from "../../Const";
import { EndDrawerControllerStateContext, LoginStateContext } from "../../contexts";
import int from "../../int";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import GroupTab from "../GroupTab";
import Loading from "../Loading";
import CustomerPersonDetailPage from "./CustomerPersonDetailPage";
import * as Icons from "@mui/icons-material";
import CustomerPersonAddPage from "./CustomerPersonAddPage";
import CustomerGroupAddPage from "./CustomerGroupAddPage";
import CustomerGroupDetailPage from "./CustomerGroupDetailPage";
import CustomerPerson from "../../models/CustomerPerson";
import CustomerManagementFilterController from "../../controllers/filterControllers/CustomerManagementFilterController";

const GroupStateContext = createStateContext<string | null>();

const CustomerPeopleListView: React.FC<{ items: Array<any> }> = (props) => {
    const nav = useNavigate()
    const groupContext = useContext(GroupStateContext);
    const goEdit = (item: any) => {
        return () => {
            Router.pushNamed(
                Router.getRouteOfPage(CustomerPersonDetailPage)!
                    .replace(":id", CustomerPerson.getIdNumFromString(item.id!)!.toString()), nav)
        }
    }

    return (
        <div className="RadioGroupListContainer">
            <div className="RadioGroupListBeforeAdd">
                <RadioGroup
                    aria-label="group"
                    name="group"
                    value={groupContext.state}
                    onChange={(e, value) => { groupContext.setState(value) }}
                >
                    <List>
                        {
                            props.items.map((item) =>
                                <Box className="ListItem" sx={{ display: 'flex' }} key={item.id} onClick={goEdit(item)}>
                                    <ListItemButton>
                                        {`${item.familyName} ${item.firstName}`}
                                    </ListItemButton>

                                    <Button variant="contained">{Const.editButtonText}</Button>
                                </Box>
                            )
                        }
                        {
                            props.items.length == 0
                                ? <Box sx={{ color: "gray" }}>( 該当なし )</Box>
                                : <React.Fragment />
                        }
                    </List>
                </RadioGroup>
            </div>
            <div className="RadioGroupAddContainer">
                <div className="RadioGroupAdd">
                    <div onClick={() => {
                        var s = Router.getRouteOfPage(CustomerPersonAddPage)!;
                        Router.pushNamed(s.replace(":id", (groupContext.state !== null ? (CustomerPerson.getIdNumFromString(groupContext.state) ?? "0").toString() : "0")), nav);
                    }}>
                        <Button variant="contained">
                            <Icons.Add />
                            <span>ユーザを追加</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const CustomerPeopleWhenPersonal: React.FC = (props) => {
    const nav = useNavigate()

    const query = gql`
            {
                customerPeople(customerGroup: null) {
                edges {
                node {
                id
              _id
            firstName
            familyName
            email
            rentals {
                totalCount
            }
            customerGroup {
                id
            }
            }
          }
        }
      }
            `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["customerPeople"]?.["edges"] !== undefined) {
        const users: Array<any> = result.data!["customerPeople"]["edges"];

        const items = users.filter((e) => {
            return e["node"]["customerGroup"] === undefined || e["node"]["customerGroup"]?.["id"] === undefined;
        })
            .map(
                (e) => e["node"]
            )

        // print(items);
        return <CustomerPeopleListView items={items} />;
    } else {
        return <CustomerPeopleListView items={[]} />;
    }
}

const CustomerPeopleWithGroup: React.FC = (props) => {
    const nav = useNavigate()

    const groupContext = useContext(GroupStateContext)
    const customerGroupId = groupContext.state;

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as CustomerManagementFilterController;
    const familyNameCt = useContext(filterController.familyNameCt);
    const firstNameCt = useContext(filterController.firstNameCt);
    const sortPeopleCt = useContext(filterController.sortGroupCt);
    // const sortPeopleCt = useContext(filterController.sortPeopleCt);

    const sortStr = sortPeopleCt.state;
    const orders: Array<any> | undefined = filterController.ordersMapPeople.get(sortStr);

    const query = gql`
      query (
        $groupId: String!
        $firstName: String
        $familyName: String
        $order: [CustomerPersonFilter_order]
      ) {
        customerPeople(
          customerGroup: $groupId
          firstName: $firstName
          familyName: $familyName
          order: $order
        ) {
          edges {
            node {
              id
              _id
              firstName
              familyName
              email
              rentals {
                totalCount
              }
              customerGroup {
                id
              }
            }
          }
        }
      }`

    const result = useQuery(query, {
        variables: {
            'groupId': customerGroupId,
            'firstName': firstNameCt.state !== "" ? firstNameCt.state : undefined,
            'familyName': familyNameCt.state !== "" ? familyNameCt.state : undefined,
            'order': orders
        },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const users: Array<any> = result.data!["customerPeople"]?.["edges"] !== undefined ? result.data!["customerPeople"]?.["edges"] : [];

        const items = users
            .map(
                (e) => e["node"]
            )

        // print(items);
        return <CustomerPeopleListView items={items} />;
    } else {
        return <CustomerPeopleListView items={[]} />;
    }
}

const CustomerGroupListView: React.FC<{ items: Array<any> }> = (props) => {
    const nav = useNavigate()
    const groupContext = useContext(GroupStateContext);

    return (
        <div className="RadioGroupListContainer">
            <div className="RadioGroupListBeforeAdd">
                <RadioGroup
                    aria-label="group"
                    name="group"
                    value={groupContext.state}
                    onChange={(e, value) => { groupContext.setState(value) }}
                >
                    <List>
                        {
                            props.items.map((item) => {
                                // console.log(item)
                                const id: string | null =
                                    (item !== null) ? item["id"] : null;
                                const id_num: int | null =
                                    (item !== null) ? item["_id"] : null;
                                const name: string = (item !== null)
                                    ? item["name"]
                                    : Const.customerPersonNullMenuItemText;
                                const count: int = (item !== null)
                                    ? (item["persons"]?.["totalCount"] ?? 0)
                                    : 0;

                                return <Box className="ListItem" sx={{ display: 'flex' }} key={id}>
                                    <div onClick={() => { groupContext.setState(id) }}>
                                        <ListItemButton >
                                            <Radio value={id} />
                                            {
                                                item !== null
                                                    ? `${name} (${count})`
                                                    : `${name}`
                                            }
                                        </ListItemButton>
                                    </div>
                                    {
                                        (id !== null)
                                            ? <div style={{ display: "flex", verticalAlign: "middle", alignItems: "center" }} onClick={() => { Router.pushNamed(Router.getRouteOfPage(CustomerGroupDetailPage)!.replace(":id", CustomerPerson.getIdNumFromString(id!)!.toString()), nav) }}>
                                                <Button variant="contained">{Const.editButtonText}</Button>
                                            </div>
                                            : <React.Fragment />
                                    }
                                </Box>
                            })
                        }
                    </List>
                </RadioGroup>
            </div>
            <div className="RadioGroupAddContainer">
                <div className="RadioGroupAdd">
                    <div onClick={() => {
                        var s = Router.getRouteOfPage(CustomerGroupAddPage)!;
                        Router.pushNamed(s, nav);
                    }}>
                        <Button variant="contained">
                            <Icons.Add />
                            <span>グループを追加</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    )
}

const CustomerGroup: React.FC = (props) => {
    const nav = useNavigate()

    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as CustomerManagementFilterController;
    const sortGroupCt = useContext(filterController.sortGroupCt);
    // const sortPeopleCt = useContext(filterController.sortPeopleCt);

    const sortStr = sortGroupCt.state;
    const orders: Array<any> | undefined = filterController.ordersMapGroup.get(sortStr);

    const query = gql`
      query($order: [CustomerGroupFilter_order]) {
        customerGroups(order: $order) {
          edges {
            node {
              id
              _id
              name
              persons {
                totalCount
              }
            }
          }
        }
      }
      `

    const result = useQuery(query, {
        variables: {
            'order': orders
        },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["customerGroups"]?.["edges"] !== undefined) {
        var items: Array<any> = result.data!["customerGroups"]["edges"]
            .map((e: any) => e["node"]);

        if (items[items.length - 1] != null) {
            items.push(null);
        }

        // console.log(items)

        return <CustomerGroupListView items={items} />;
    } else {
        return <CustomerGroupListView items={[]} />;
    }
}

const CustomerManagementPageImpl: React.FC = (props) => {
    const groupContext = useContext(GroupStateContext)
    const userType = groupContext.state;

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <GroupTab title={Const.customerGroupLabelText}>
                    <CustomerGroup />
                </GroupTab>
            </Grid>

            <Grid item xs={6}>
                <GroupTab title={Const.customerPeopleLabelText}>
                    {
                        (userType === null)
                            ? <CustomerPeopleWhenPersonal />
                            : <CustomerPeopleWithGroup />
                    }
                </GroupTab>
            </Grid>
        </Grid >
    )
}

const CustomerManagementPage: React.FC = (props) => {

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    return (
        <React.Fragment>
            {
                (() => {
                    if (!(loginState.user!.isAdmin() || loginState.user!.isRentalRoleUser())) {
                        return <React.Fragment>{Const.adminPageErrorText}</React.Fragment>
                    } else {
                        return (
                            <StateProvider context={GroupStateContext} defaultValue={null} >
                                <CustomerManagementPageImpl />
                            </StateProvider>
                        )
                    }
                })()
            }
        </React.Fragment >
    );
}

export default CustomerManagementPage;