import React, { useContext, useEffect } from 'react';
import Equipment from '../models/Equipment';
import RentalReturnLog from '../models/RentalReturnLog';
import * as Icons from "@mui/icons-material";
import { Button, List, ListItem } from '@mui/material';
import SizedBox from './SizedBox';
import StateOf from '../states/StateOf';
import StateContextProp from '../states/StateContextProp';
import { CartContext, YearRangeStateContext } from '../contexts';
import { Link } from 'react-router-dom';
import Router from '../Router';
import EquipmentDetailPage from './pages/EquipmentDetailPage';
import { Box } from '@mui/system';
import Const from '../Const';
import int from '../int';
import RentalStatus from '../models/RentalStatus';
import Rental from '../models/Rental';
import RentalDetailPage from './pages/RentalDetailPage';
import RentalReturnButton from './RentalReturnButton';
import BusinessYear from '../BusinessYear';
import createStateContext from '../states/createStateContext';
import { Spacer } from '../Spacer';
import VStack from '../VStack';
import HStack from '../HStack';
import StateProvider from '../states/StateProvider';

const IsFilterRentalContext = createStateContext<boolean>();
const IsShowinglessContext = createStateContext<boolean>();

const showingCountThreshold = 10;

const _ItemsList: React.FC<{ equipmentItem: Equipment, rentals: Rental[] }> = (props) => {

    const rentals = props.rentals
    const equipmentItem = props.equipmentItem
    const yearRangeState = useContext(YearRangeStateContext);

    const isFilterRentalContext = useContext(IsFilterRentalContext);
    const isFilterRental = isFilterRentalContext.state;

    const isShowinglessContext = useContext(IsShowinglessContext);
    const isShowingless = isShowinglessContext.state;

    const rentalsFiltered = rentals
        .filter((rental) => {
            if (isFilterRental) {
                // NOTE: 年度別に絞り込み
                // TODO: 本来はGraphQLで取得するタイミングで行い、通信量自体を減らすべき

                const y1 = rental.getStartDate() ? BusinessYear.getFromDate(rental.getStartDate()!) : undefined
                const y2 = rental.getEndDate() ? BusinessYear.getFromDate(rental.getEndDate()!) : undefined

                if (y1 !== undefined) {
                    if (yearRangeState.state.instance.isInRange(y1)) {
                        return true
                    }
                }

                if (y2 !== undefined) {
                    if (yearRangeState.state.instance.isInRange(y2)) {
                        return true
                    }
                }

                return false
            } else {
                return true
            }
        })

    return (
        <React.Fragment>
            <List className="CartItemsList">
                {
                    rentalsFiltered
                        .sort((a, b) => {
                            const d1 = (a.getEndDate() !== undefined) ? a.getEndDate()! : a.getStartDate()!
                            const d2 = (b.getEndDate() !== undefined) ? b.getEndDate()! : b.getStartDate()!
                            const diffTime = d2.getTime() - d1.getTime();
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                            return diffDays
                        }).slice(0, isShowingless ? showingCountThreshold : rentalsFiltered.length)
                        .map((e) => {
                            const isReserved: boolean = e.getStatus() == RentalStatus.Reserved;

                            const returnLog: RentalReturnLog | undefined =
                                equipmentItem.getRentalReturnLog(e.rentalReturnLogs ?? []);
                            const l = returnLog;

                            return (
                                <ListItem className="RentalLogViewItemContainer" key={e.id}>
                                    <div className="RentalLogViewItem">
                                        <div style={{ display: "flex", justifyContent: "center", width: "30px" }}>
                                            <Icons.ArrowRight />
                                        </div>
                                        <Link className={`RentalLogViewRentalType ${isReserved ? "Reserved" : "Rental"}`} to={Router.getRouteOfPage(RentalDetailPage).replace(':id', e?.getIdNum()?.toString()!)}>
                                            {isReserved ? "予約" : "貸出"}
                                        </Link>
                                        <span className="RentalLogViewRentalSpan">
                                            {`${e.getStartDateReadable()} ～ ${e.getEndDateReadable()}`}
                                        </span >
                                        <span className="RentalLogViewTransaction">
                                            {`${e.userInCharge?.getName()}さん → ${e.customerPerson?.getName()}さん (${e.customerPerson?.getGroupNameReadable()})`}
                                        </span>
                                    </div>
                                    {
                                        (l !== undefined)
                                            ? (
                                                <div className="RentalLogViewItemDetail">
                                                    <Box className="RentalLogViewReturned">
                                                        <Icons.CheckCircle />
                                                        <SizedBox inline width={3} />
                                                        <span className="RentalLogViewReturnedStatus Returned">返却</span>
                                                        <SizedBox inline width={3} />
                                                        <span className="RentalLogViewReturnedDate">{`${l.getReturnedDateReadable()}`}</span>
                                                        <SizedBox inline width={3} />
                                                        <span className="RentalLogViewReturnedTransaction">{`${l.userWhoReceived.getName()}さん が受取`}</span>
                                                        {
                                                            l.returnNote !== null && l.returnNote !== "" ?
                                                                <span>&nbsp;(メモ: { `${l.returnNote}` })</span>
                                                                : <React.Fragment />
                                                        }
                                                        {/* <span className="RentalLogViewReturnedContent">{`${Const.rentalReturnedText} ( ${log?.getReturnedDateReadable()}, 受取人: ${log?.getUserName()} )`}</span> */}
                                                    </Box>
                                                </div>
                                            )
                                            : <div className="RentalLogViewItemDetail">
                                                {
                                                    (!isReserved)
                                                        ? (e.getStatus() === RentalStatus.Completed)
                                                            ? (
                                                                <React.Fragment>
                                                                    <Box className="RentalLogViewReturned">
                                                                        <Icons.Check />
                                                                        <SizedBox inline width={3} />
                                                                        <span className="RentalLogViewReturnedStatus Returned">完了済</span>
                                                                    </Box>
                                                                </React.Fragment>
                                                            )
                                                            : (
                                                                <React.Fragment>
                                                                    <Box className="RentalLogViewNotReturned">
                                                                        <Icons.Warning />
                                                                        <SizedBox inline width={3} />
                                                                        <span className="RentalLogViewReturnedStatus NotReturned">未返却</span>
                                                                    </Box>

                                                                    <RentalReturnButton longVersion rentalIdNumber={e.getIdNum()!} />
                                                                </React.Fragment>
                                                            )
                                                        : (
                                                            <Box className="RentalLogViewNotTransferred">
                                                                <Icons.NoTransfer />
                                                                <SizedBox inline width={3} />
                                                                <span className="RentalLogViewReturnedStatus NotTransferred">未貸出</span>
                                                            </Box>
                                                        )
                                                }
                                            </div>
                                    }
                                </ListItem>
                            )
                        })
                }
                {
                    (rentals.length === 0)
                        ? <ListItem key={"_"}>
                            なし
                        </ListItem>
                        : <React.Fragment></React.Fragment>
                }
            </List>
            {
                (isFilterRental) ?
                    (
                        (rentals.length > rentalsFiltered.length) ?
                            <div>
                                <VStack>
                                    <HStack>
                                        <Spacer size={10} horizontal={true} />
                                        ※ 一部非表示の履歴があります: <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            isFilterRentalContext.setState(false);
                                        }}>表示する</a>
                                    </HStack>
                                    <Spacer size={10} />
                                </VStack>
                            </div>
                            : <div>
                                <VStack>
                                    <HStack>
                                        <Spacer size={10} horizontal={true} />
                                        ※ 年度での絞り込みが有効になっています: <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            isFilterRentalContext.setState(false);
                                        }}>フィルタをオフする</a>
                                    </HStack>
                                    <Spacer size={10} />
                                </VStack>
                            </div>
                    )
                    : (
                        <div>
                            <VStack>
                                <HStack>
                                    <Spacer size={10} horizontal={true} />
                                    ※ 年度に関わらず全件表示されています: <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        isFilterRentalContext.setState(true);
                                    }}>フィルタをオンする</a>
                                </HStack>
                                <Spacer size={10} />
                            </VStack>
                        </div>
                    )
            }
            {
                (isShowingless && rentalsFiltered.length > showingCountThreshold) ? (
                    <div>
                        <VStack>
                            <HStack>
                                <Spacer size={10} horizontal={true} />
                                ※ {rentalsFiltered.length}件中{showingCountThreshold}件のみ表示しています : <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    isShowinglessContext.setState(false);
                                }}>全件表示する</a>
                            </HStack>
                            <Spacer size={10} />
                        </VStack>
                    </div>
                ) : ((!isShowingless) ? (
                    <div>
                        <VStack>
                            <HStack>
                                <Spacer size={10} horizontal={true} />
                                ※ 全件表示されています: <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    isShowinglessContext.setState(true);
                                }}>{showingCountThreshold}件だけ表示する</a>
                            </HStack>
                            <Spacer size={10} />
                        </VStack>
                    </div>
                )
                    : <React.Fragment></React.Fragment>)
            }
        </React.Fragment>
    )
}

const EquipmentRentalLogViewImpl: React.FC<{ equipmentItem: Equipment, rentals: Rental[] }> = (props) => {

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const equipmentItem = props.equipmentItem;
    const rentals = props.rentals

    const rentalReservedCount: int =
        rentals.filter((e) => e.getStatus() == RentalStatus.Reserved).length;
    const rentalCount: int = rentals.length;
    // console.log(rentals)
    const returnCount: int = rentals
        .map((e): int =>
            equipmentItem.getRentalReturnLog(e.rentalReturnLogs ?? []) != null
                ? 1
                : 0)
        .reduce((prev: int, e: int): int => prev + e, 0);
    const logCount: int = rentalCount + returnCount;

    return (
        <div className="RentalLogView">
            <div className="RentalLogViewHeader">
                <Icons.Inventory2Outlined />
                <SizedBox inline width={3} />
                <span>{`貸出履歴 (予約 ${rentalReservedCount}回, 貸出 ${rentalCount - rentalReservedCount}回, 返却 ${returnCount}回)`}</span>
            </div>
            <div className="RentalLogViewContent">
                <_ItemsList equipmentItem={equipmentItem} rentals={props.rentals} />
            </div>
        </div>
    )
}

const EquipmentRentalLogView: React.FC<{ equipmentItem: Equipment, rentals: Rental[] }> = (props) => {
    return (
        <StateProvider context={IsFilterRentalContext} defaultValue={true}>
            <StateProvider context={IsShowinglessContext} defaultValue={true}>
                <EquipmentRentalLogViewImpl
                    equipmentItem={props.equipmentItem}
                    rentals={props.rentals}
                />
            </StateProvider>
        </StateProvider>
    );
}

export default EquipmentRentalLogView;