import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import FilterController from "../FilterController";

class CustomerManagementFilterController implements FilterController {
    constructor() {
        // sortCt.text = "name:asc";
    }

    firstNameCt = createStateContext<string>();
    familyNameCt = createStateContext<string>();
    kanaCt = createStateContext<string>();
    sortGroupCt = createStateContext<string>();
    sortPeopleCt = createStateContext<string>();

    StateInitializer: React.FC<{}> = (props) => {
        return (
            <StateProvider context={this.firstNameCt} defaultValue={""} >
                <StateProvider context={this.familyNameCt} defaultValue={""} >
                    <StateProvider context={this.kanaCt} defaultValue={""} >
                        <StateProvider context={this.sortGroupCt} defaultValue={"name:asc"} >
                            <StateProvider context={this.sortPeopleCt} defaultValue={"name:asc"} >
                                {props.children}
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    // Map<String, String>
    sortMapPeople: Map<string, string> = new Map(Object.entries({
        "ID (昇順 ↑ )": "id:asc",
        "ID (降順 ↓ )": "id:desc",
        "名前 (昇順 ↑ )": "name:asc",
        "名前 (降順 ↓ )": "name:desc",
    }));

    sortMapGroup: Map<string, string> = new Map(Object.entries({
        "ID (昇順)": "id:asc",
        "ID (降順)": "id:desc",
        "名前 (昇順)": "name:asc",
        "名前 (降順)": "name:desc",
    }));

    // Map<String, List<Map<String, dynamic>>>
    ordersMapPeople: Map<string, Array<any>> = new Map(Object.entries({
        "id:asc": [
            { "id": "asc" }
        ],
        "id:desc": [
            { "id": "desc" }
        ],
        "name:asc": [
            { "familyName": "asc" },
            { "firstName": "asc" }
        ],
        "name:desc": [
            { "familyName": "desc" },
            { "firstName": "desc" }
        ],
    }));

    ordersMapGroup: Map<string, Array<any>> = new Map(Object.entries({
        "id:asc": [
            { "id": "asc" }
        ],
        "id:desc": [
            { "id": "desc" }
        ],
        "name:asc": [
            { "name": "asc" },
        ],
        "name:desc": [
            { "name": "desc" },
        ],
    }));
}

export default CustomerManagementFilterController;