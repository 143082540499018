import { Container } from "@mui/material";
import React from "react";

const GroupTab: React.FC<{ title?: string }> = (props) => {
    return (
        <React.Fragment>
            <div className="GroupTabTitleContainer">
                <span className="GroupTabTitle">{props.title}</span>
            </div>
            <Container className="GroupTab">
                <div className="GroupTabContent">
                    {props.children}
                </div>
            </Container>
        </React.Fragment>
    );
}

export default GroupTab;