import int from "../int";
import IntUtils from "../IntUtils";
import BaseModel from "./BaseModel";
import EquipmentCategory from "./EquipmentCategory";
import RentalReturnLog from "./RentalReturnLog";

class Equipment extends BaseModel {
    constructor(props: {
        id: string,
        uuid: int
        name?: string,
        numbering?: string,
        category?: EquipmentCategory,
    }) {
        super({ id: props.id })
        this.uuid = props.uuid;
        this.name = props.name;
        this.numbering = props.numbering;
        this.category = props.category;
    }

    uuid: int
    name?: string
    numbering?: string
    category?: EquipmentCategory

    equals(e: Equipment): boolean {
        return this.id == e.id;
    }

    getReadableName(): string {
        return `#${this.uuid} ${this.name} No.${this.numbering}`;
    }

    isReturedInItems(returnedEquipmentItems: Equipment[]): boolean {
        for (const item of returnedEquipmentItems) {
            if (item.id === this.id) {
                return true;
            }
        }

        return false;
    }

    isReturedInLogs(rentalReturnLogs: RentalReturnLog[]): boolean {
        for (const log of rentalReturnLogs) {
            for (const item of log.equipmentItems) {
                if (item.id === this.id) {
                    return true;
                }
            }
        }

        return false;
    }

    getRentalReturnLog(rentalReturnLogs: RentalReturnLog[]): RentalReturnLog | undefined {
        // console.log(rentalReturnLogs)
        // console.log(this)

        for (const log of rentalReturnLogs) {
            for (const item of log.equipmentItems) {
                if (item.id === this.id) {
                    return log;
                }
            }
        }

        return undefined;
    }
}

export default Equipment;