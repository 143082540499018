import { TextFields } from "@mui/icons-material";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import Const from "../Const";
import { EndDrawerControllerStateContext } from "../contexts";
import Router from "../Router";
import StateContextProp from "../states/StateContextProp";
import IconFa from "../widgets/IconFa";
import Loading from "../widgets/Loading";
import SizedBox from "../widgets/SizedBox";
import * as Icons from "@mui/icons-material";
import CustomerManagementFilterController from "../controllers/filterControllers/CustomerManagementFilterController";

const _SortDropDown: React.FC<{ sortCt: StateContextProp<string>, sortMap: Map<string, string>, labelId: string, labelText: string }> = (props) => {
    return (
        <React.Fragment>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel className="InputLabel" id={props.labelId}>{props.labelText}</InputLabel>
                <Select
                    value={props.sortCt.state}
                    labelId={props.labelId}
                    onChange={(e: any) => {
                        console.log(e.target.value)
                        props.sortCt.setState(e.target.value)
                    }}
                >
                    <MenuItem key={""} value={""}>
                        <Icons.Sort />
                        <SizedBox inline width={5} />
                    </MenuItem>
                    {
                        Array.from(props.sortMap.keys())
                            .map((key) => {
                                const val = props.sortMap.get(key)!
                                return (
                                    <MenuItem className="MenuItemWithIcon" key={val} value={val}>
                                        <Icons.Sort />
                                        <SizedBox inline width={5} />
                                        <span>{key}</span>
                                    </MenuItem>
                                )
                            })
                    }
                </Select>
            </FormControl>
        </React.Fragment>
    )
}

const CustomerManagementPage_FilterBuilder: React.FC = () => {
    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as CustomerManagementFilterController;
    const familyNameCt = useContext(filterController.familyNameCt);
    const firstNameCt = useContext(filterController.firstNameCt);
    const sortGroupCt = useContext(filterController.sortGroupCt);
    const sortPeopleCt = useContext(filterController.sortPeopleCt);

    // const context = useContext(endDrawerController)

    return (
        <div className="FilterForm">
            <_SortDropDown sortCt={sortGroupCt} labelId={"sort-group-filter-label"} labelText={"並び順 (グループ)"} sortMap={filterController.sortMapGroup} />
            <SizedBox height={10} />
            <_SortDropDown sortCt={sortPeopleCt} labelId={"sort-people-filter-label"} labelText={"並び順 (担当者)"} sortMap={filterController.sortMapPeople} />
            <SizedBox height={10} />
            <TextField
                label={Const.familyNameLabelText}
                value={familyNameCt.state}
                onChange={(e) => {
                    familyNameCt.setState(e.target.value)
                }}
            />
            <SizedBox height={10} />
            <TextField
                label={Const.firstNameLabelText}
                value={firstNameCt.state}
                onChange={(e) => {
                    firstNameCt.setState(e.target.value)
                }}
            />
        </div>
    );
}

export default CustomerManagementPage_FilterBuilder;