import EquipmentAliveStatus from "../EquipmentAliveStatus";
import int from "../int";

class EquipmentAliveStatusExtension {
    static toShortString(status: EquipmentAliveStatus): string {
        return status.toString()
    }

    static toStringJp(status: EquipmentAliveStatus): string {
        switch (status) {
            case EquipmentAliveStatus.Alive:
                return "正常";
            case EquipmentAliveStatus.Notfound:
                return "紛失";
            case EquipmentAliveStatus.Removed:
                return "破棄";
            case EquipmentAliveStatus.Reparing:
                return "修理中";
        }
    }

    static getColor(status: EquipmentAliveStatus): string {
        // console.error("getColor() should be rewritten!")
        switch (status) {
            case EquipmentAliveStatus.Alive:
                return "#6bba32";
            case EquipmentAliveStatus.Reparing:
                return "#ba3232";
            case EquipmentAliveStatus.Notfound:
                return "#9132ba";
            case EquipmentAliveStatus.Removed:
                return "#ba9a32";
        }
    }

    static fromString(value: string): EquipmentAliveStatus {
        return value as EquipmentAliveStatus
    }
}

export default EquipmentAliveStatusExtension;