import { Box, Divider } from "@mui/material";
import React, { useContext } from "react";
import { LoginStateContext } from "../contexts";
import * as Icons from "@mui/icons-material";
import SizedBox from "./SizedBox";

const DrawerHeaderContent: React.FC = () => {
    const loginContext = useContext(LoginStateContext);
    const loginState = loginContext.state.instance;

    return (
        <div className="DrawerHeaderContent">
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span>TEMDEC機材管理</span>
            </Box>
            <div className="DrawerHeaderDivider" />
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Icons.Person />
                <SizedBox width={3} />
                {loginState.user?.getName()}
            </Box>
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "3px" }}>
                <span style={{ fontSize: "12px" }}>
                    {`( ${loginState.user?.email} )`}
                </span>
            </Box>
        </div>
    );
}

const DrawerHeader: React.FC = () => {
    return (
        <div className="DrawerHeader">
            <DrawerHeaderContent />
        </div>
    );
}

export default DrawerHeader;