import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import { constants } from "os";

const UpdatedStateContext = createStateContext<UpdateState>();
const GroupStateContext = createStateContext<string>();
const EmailStateContext = createStateContext<string>();
const PasswordStateContext = createStateContext<string>();
const FirstNameStateContext = createStateContext<string>();
const FamilyNameStateContext = createStateContext<string>();
const TelStateContext = createStateContext<string>();

type UserGroupMap = { "type": string, "name": string, "count": int };

const _UserGroupDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const groupStateContext = useContext(GroupStateContext)

    return (
        <SelectValidator
            className="Required"
            value={groupStateContext.state}
            label={Const.userGroupDropDownMenuLabelText}
            name="user-group"
            onChange={(e: any) => { groupStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.type} value={e.type}>{e.name}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _UserGroupDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        users {
          edges {
            node {
              id
              _id
              roles
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["users"]?.["edges"] !== undefined) {
        const users: Array<any> = result.data!["users"]["edges"];

        const adminUsersCount: int = users
            .filter((e) => e["node"]["roles"].includes("ROLE_ADMIN"))
            .length;
        const normalUsersCount: int = users
            .filter((e) => !e["node"]["roles"].includes("ROLE_ADMIN"))
            .filter((e) => e["node"]["roles"].includes("ROLE_RENTAL"))
            .length;
        const guestUsersCount: int = users
            .filter((e) => !e["node"]["roles"].includes("ROLE_ADMIN"))
            .filter((e) => !e["node"]["roles"].includes("ROLE_RENTAL"))
            .length;

        const items: Array<UserGroupMap> = [
            { "type": "ADMIN", "name": "管理者", "count": adminUsersCount },
            { "type": "USER", "name": "一般ユーザ", "count": normalUsersCount },
            { "type": "GUEST", "name": "ゲストユーザ", "count": guestUsersCount },
        ];

        return <_UserGroupDropDownImpl items={items} />
    } else {
        return <_UserGroupDropDownImpl items={[]} />
    }
}

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const emailContext = useContext(EmailStateContext);
    const passwordContext = useContext(PasswordStateContext);
    const firstNameContext = useContext(FirstNameStateContext);
    const familyNameContext = useContext(FamilyNameStateContext);
    const telContext = useContext(TelStateContext);
    const updatedContext = useContext(UpdatedStateContext);
    const groupContext = useContext(GroupStateContext)

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                var email = emailContext.state;
                var password = passwordContext.state;
                var firstName = firstNameContext.state;
                var familyName = familyNameContext.state;
                var userType = groupContext.state;
                var tel = telContext.state;

                const res = await Api.postJson("encode_password", {
                    "password": password
                })

                const encode_password = res?.data !== undefined ? res?.data?.encoded_password : undefined

                if (encode_password === undefined) {
                    console.error(res);
                    alert("エラーが発生しました");
                    return
                }

                const queryStr = `
                  mutation($familyName: String!, $firstName: String!, $email: String!, $tel: String, $roles: Iterable!, $password: String!) {
                    createUser(input: {familyName: $familyName, firstName: $firstName, email: $email, tel: $tel, roles: $roles, password: $password}) {
                      user {
                        id
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'familyName': familyName,
                            'firstName': firstName,
                            'email': email,
                            'tel': tel === ""
                                ? undefined
                                : tel,
                            'roles': userType === "ADMIN"
                                ? ["ROLE_ADMIN", "ROLE_USER", "ROLE_RENTAL"]
                                : userType === "USER"
                                    ? ["ROLE_USER", "ROLE_RENTAL"]
                                    : ["ROLE_USER"],
                            'password': encode_password
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["createUser"] != undefined &&
                    result?.data?.["createUser"]?.["user"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    emailContext.setState("");
                    passwordContext.setState("");
                    firstNameContext.setState("");
                    familyNameContext.setState("");
                    groupContext.setState("USER");
                    telContext.setState("");

                    Router.popAndPushNamed(Router.getRouteOfPage(UserManagementPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >

            <_UserGroupDropDown />

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.familyNameLabelText}
                        onChange={(e: any) => familyNameContext.setState(e.target.value)}
                        name="familyName"
                        value={familyNameContext.state}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextValidator
                        className="Required"
                        label={Const.firstNameLabelText}
                        onChange={(e: any) => firstNameContext.setState(e.target.value)}
                        name="firstName"
                        value={firstNameContext.state}
                        validators={['required']}
                        errorMessages={[ValidatorMessages.required]}
                    />
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label="ID"
                onChange={(e: any) => emailContext.setState(e.target.value)}
                name="email"
                value={emailContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icons.PermIdentity />
                        </InputAdornment>
                    ),
                }}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={"パスワード"}
                onChange={(e: any) => passwordContext.setState(e.target.value)}
                type="password"
                name="password"
                value={passwordContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icons.VpnKey />
                        </InputAdornment>
                    ),
                }}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />
            <SizedBox height={10} />
            <TextValidator
                label={Const.telLabelText}
                onChange={(e: any) => telContext.setState(e.target.value)}
                name="tel"
                value={telContext.state}
            />
            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.addNewButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    return (
        <StateProvider context={FamilyNameStateContext} defaultValue={""}>
            <StateProvider context={FirstNameStateContext} defaultValue={""}>
                <StateProvider context={EmailStateContext} defaultValue={""}>
                    <StateProvider context={TelStateContext} defaultValue={""}>
                        <StateProvider context={PasswordStateContext} defaultValue={""}>
                            <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                <StateProvider context={GroupStateContext} defaultValue={""}>
                                    <_FormImpl />
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )

}

const UserAddPage: React.FC = (props) => {
    return <_Form />
}

export default UserAddPage;