import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client"
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import CustomerManagementPage from "./CustomerManagementPage";

const UpdatedStateContext = createStateContext<UpdateState>();
const NameStateContext = createStateContext<string>();

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const nameContext = useContext(NameStateContext);
    const updatedContext = useContext(UpdatedStateContext);

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                var name = nameContext.state;

                const queryStr = `
                  mutation($name: String!) {
                    createCustomerGroup(input: {name: $name}) {
                      customerGroup {
                        id
                        name
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'name': name
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["createCustomerGroup"] != undefined &&
                    result?.data?.["createCustomerGroup"]?.["customerGroup"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    nameContext.setState("");

                    Router.popAndPushNamed(Router.getRouteOfPage(CustomerManagementPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >
            <TextValidator
                className="Required"
                label="名称"
                onChange={(e: any) => nameContext.setState(e.target.value)}
                name="name"
                value={nameContext.state}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />
            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    return (
        <StateProvider context={NameStateContext} defaultValue={""}>
            <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                <_FormImpl />
            </StateProvider>
        </StateProvider>
    )

}

const CustomerGroupAddPage: React.FC = (props) => {
    return <_Form />
}

export default CustomerGroupAddPage;