import { constants } from "os";
import DateFormatter from "../DateFormatter";
import DateFormatterReadable from "../DateFormatterReadable";
import DateTime from "../DateTime";
import int from "../int";
import BaseModel from "./BaseModel";
import CustomerPerson from "./CustomerPerson";
import RentalReturnLog from "./RentalReturnLog";
import RentalStatus from "./RentalStatus";
import RentalStatusExtension from "./RentalStatusExtension";
import User from "./User";

class Rental extends BaseModel {
    constructor(props: {
        id: string,
        status: string,
        title?: string,
        userInCharge?: User,
        customerPerson?: CustomerPerson,
        startDate?: string,
        endDate?: string,
        rentalReturnLogs?: RentalReturnLog[]
    }) {
        super({ id: props.id })
        this.status = props.status
        this.title = props.title
        this.userInCharge = props.userInCharge
        this.customerPerson = props.customerPerson
        this.startDate = props.startDate
        this.endDate = props.endDate
        this.rentalReturnLogs = props.rentalReturnLogs
    }

    status: string
    title?: string
    userInCharge?: User
    customerPerson?: CustomerPerson
    startDate?: string
    endDate?: string
    rentalReturnLogs?: RentalReturnLog[]

    static createListFromGraphQLResult(items: any): Rental[] {
        var rentals: Rental[] = [];

        if (items?.["edges"] !== undefined && items?.["edges"]?.length > 0) {
            var _rentals = items["edges"];
            for (const _rental of _rentals) {
                var _r = _rental["node"];

                if (_r["customerPerson"] !== undefined &&
                    _r["customerPerson"]?.["customerGroup"]?.["id"] !== undefined) {
                    _r["customerPerson"]["groupId"] =
                        _r["customerPerson"]?.["customerGroup"]?.["id"];
                }

                if (_r["customerPerson"] !== undefined &&
                    _r["customerPerson"]?.["customerGroup"]?.["name"] != undefined) {
                    _r["customerPerson"]["groupName"] =
                        _r["customerPerson"]?.["customerGroup"]?.["name"];
                }

                const r = new Rental({
                    id: _r["id"],
                    status: _r["status"],
                    startDate: _r["startDate"],
                    endDate: _r["endDate"],
                    title: _r["title"],
                    userInCharge: _r["userInCharge"] !== undefined
                        ? new User(_r["userInCharge"])
                        : undefined,
                    customerPerson: _r["customerPerson"] !== undefined
                        ? new CustomerPerson(_r["customerPerson"])
                        : undefined,
                    rentalReturnLogs: _r["rentalReturnLogs"] !== undefined
                        ? RentalReturnLog.createListFromGraphQLResult(
                            _r["rentalReturnLogs"])
                        : undefined
                });

                rentals.push(r);
            }
        }
        return rentals;
    }

    static _createListFromGraphQLResultCache: Map<int, Array<Rental>> = new Map();

    static createListFromGraphQLResultCached(
        uuid: int, item: any
    ): Rental[] {
        if (this._createListFromGraphQLResultCache.has(uuid)) {
            // print("used cache for uuid $uuid");
            return this._createListFromGraphQLResultCache.get(uuid)!;
        } else {
            return this.createListFromGraphQLResult(item);
        }
    }

    static createListFromGraphQLResultCacheClear(uuid: int) {
        this._createListFromGraphQLResultCache.delete(uuid);
    }

    equals(e: Rental): boolean {
        return this.id == e.id;
    }

    getStatus(): RentalStatus {
        return RentalStatusExtension.fromString(this.status);
    }

    getStartDate(): DateTime | undefined {
        return this.startDate !== undefined ? new Date(this.startDate!) : undefined;
    }

    getEndDate(): DateTime | undefined {
        return this.endDate !== undefined && this.endDate !== null ? new Date(this.endDate!) : undefined;
    }

    getStartDateReadable(): string {
        return this.startDate !== undefined
            ? DateFormatterReadable.format(new Date(this.startDate!))
            : "";
    }

    getEndDateReadable(): string {
        return this.endDate !== undefined && this.endDate !== null
            ? DateFormatterReadable.format(new Date(this.endDate!))
            : "";
    }
}

export default Rental;