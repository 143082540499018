import { TextFields } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { gql } from "@apollo/client";
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import Const from "../Const";
import { EndDrawerControllerStateContext } from "../contexts";
import Router from "../Router";
import StateContextProp from "../states/StateContextProp";
import IconFa from "../widgets/IconFa";
import Loading from "../widgets/Loading";
import SizedBox from "../widgets/SizedBox";
import * as Icons from "@mui/icons-material";
import EquipmentManagementFilterController from "../controllers/filterControllers/EquipmentManagementFilterController";


const _CategoryDropDownImpl: React.FC<{ items: Array<any>, categoryIdCt: StateContextProp<string> }> = (props) => {

    return (
        <FormControl variant="outlined" sx={{ width: "100%" }}>
            <InputLabel className="InputLabel" id="category-filter-label">{Const.equipmentCategoryDropDownMenuLabelText}</InputLabel>
            <Select
                value={props.categoryIdCt.state}
                labelId="category-filter-label"
                name="category"
                onChange={async (e: any) => {
                    // console.log(e.target.value)
                    props.categoryIdCt.setState(e.target.value)
                    Api.graphQLResetCache()
                }}
            >
                <MenuItem key={""} value={""}>-</MenuItem>
                <MenuItem key={"_"} value={"_"}>{Const.equipmentCategoryNullMenuItemText}</MenuItem>
                {
                    props.items
                        .map((e) =>
                            <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                                <IconFa icon={e.iconName} />
                                <SizedBox inline width={5} />
                                <span>{e.name}</span>
                            </MenuItem>
                        )
                }
            </Select>
        </FormControl>
    )
}

const _CategoryDropDown: React.FC<{ categoryIdCt: StateContextProp<string> }> = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentCategories {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentCategories"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentCategories"]["edges"]
            .map((e: any) => e.node)

        return <_CategoryDropDownImpl items={items} categoryIdCt={props.categoryIdCt} />
    } else {
        return <_CategoryDropDownImpl items={[]} categoryIdCt={props.categoryIdCt} />
    }
}

const _SortDropDown: React.FC<{ sortCt: StateContextProp<string>, sortMap: Map<string, string> }> = (props) => {
    return (
        <React.Fragment>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel className="InputLabel" id="sort-filter-label">{Const.sortMenuLabelText}</InputLabel>
                <Select
                    value={props.sortCt.state}
                    labelId="sort-filter-label"
                    name="sort"
                    onChange={async (e: any) => {
                        console.log(e.target.value)
                        props.sortCt.setState(e.target.value)
                        Api.graphQLResetCache()
                    }}
                >
                    <MenuItem key={""} value={""}>
                        <Icons.Sort />
                        <SizedBox inline width={5} />
                    </MenuItem>
                    {
                        Array.from(props.sortMap.keys())
                            .map((key) => {
                                const val = props.sortMap.get(key)!
                                return (
                                    <MenuItem className="MenuItemWithIcon" key={val} value={val}>
                                        <Icons.Sort />
                                        <SizedBox inline width={5} />
                                        <span>{key}</span>
                                    </MenuItem>
                                )
                            })
                    }
                </Select>
            </FormControl>
        </React.Fragment>
    )
}

const EquipmentManagementPage_FilterBuilder: React.FC = () => {
    const endDrawerControllerContext = useContext(EndDrawerControllerStateContext);
    const endDrawerController = endDrawerControllerContext.state.instance

    const filterController = endDrawerController.getControllerOf(Router.route)! as EquipmentManagementFilterController;
    const nameCt = useContext(filterController.nameCt);
    const categoryIdCt = useContext(filterController.categoryIdCt);
    const uuidCt = useContext(filterController.uuidCt);
    const numberingCt = useContext(filterController.numberingCt);
    const sortCt = useContext(filterController.sortCt);

    const showAliveCt = useContext(filterController.showAliveCt);
    const showNotfoundCt = useContext(filterController.showNotfoundCt);
    const showRemovedCt = useContext(filterController.showRemovedCt);
    const showReparingCt = useContext(filterController.showReparingCt);

    // const context = useContext(endDrawerController)

    return (
        <div className="FilterForm">
            <_SortDropDown sortCt={sortCt} sortMap={filterController.sortMap} />
            <SizedBox height={10} />
            <TextField
                label={Const.equipmentNameLabelText}
                value={nameCt.state}
                onChange={async (e) => {
                    nameCt.setState(e.target.value)
                    Api.graphQLResetCache()
                }}
            />
            <SizedBox height={10} />
            <_CategoryDropDown categoryIdCt={categoryIdCt} />
            <SizedBox height={10} />
            <TextField
                label={Const.equipmentUuidLabelText}
                value={uuidCt.state}
                onChange={async (e) => {
                    uuidCt.setState(e.target.value)
                    Api.graphQLResetCache()
                }}
            />
            <SizedBox height={10} />
            <TextField
                label={Const.equipmentNumberingLabelText}
                value={numberingCt.state}
                onChange={async (e) => {
                    numberingCt.setState(e.target.value)
                    Api.graphQLResetCache()
                }}
            />
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showRemovedCt.state} onChange={(e) => {
                    showRemovedCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="破棄済を表示" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showNotfoundCt.state} onChange={(e) => {
                    showNotfoundCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="紛失中を表示" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showReparingCt.state} onChange={(e) => {
                    showReparingCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="修理中を表示" />
            </FormControl>
            <FormControl>
                <FormControlLabel control={<Checkbox checked={showAliveCt.state} onChange={(e) => {
                    showAliveCt.setState(e.target.checked)
                    Api.graphQLResetCache()
                }} />} label="正常な機材を表示" />
            </FormControl>
        </div>
    );
}

export default EquipmentManagementPage_FilterBuilder;