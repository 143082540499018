import { gql, QueryOptions } from "@apollo/client";
import Api from "../Api";
import Jwt from "../Jwt";
import Token from "../models/Token";
import User from "../models/User";
import Result from "../Result";

class LoginState {

    jwtToken?: string
    user?: User
    hasJwtExpired: boolean = false;
    hasLoggedOutByUser: boolean = false;
    private _loginFailed?: boolean = false;
    get loginFailed() { return this._loginFailed; }

    private _expired = (): void => {
        const clazz = this
        console.log("Login session has expired");
        // SchedulerBinding.instance?.addPostFrameCallback((_) async {
        // requestAnimationFrame(function () {
        clazz.setLoggedOut({ isExpired: true });
        // });

        requestAnimationFrame(function () {
            window.location.reload();
        });
    }

    private _notify() {
        // console.error("_notify: Not Implemented")
        // does nothing
    }

    private _clearCachedUser() {
        var session = localStorage;
        if (JSON.parse(session.getItem("IsUserCached") ?? 'false') === true) {
            session.removeItem("id");
            session.removeItem("email");
            session.removeItem("familyName");
            session.removeItem("firstName");
            session.removeItem("roles");
            session.removeItem("IsUserCached");
            return true;
        }

        return true;
    }

    private _cacheUser(user: User) {
        // TODO: jwtTokenから読み取るようにする

        var session = localStorage;
        session.setItem("id", user.id ?? "");
        session.setItem("email", user.email);
        session.setItem("familyName", user.familyName);
        session.setItem("firstName", user.firstName);
        session.setItem("roles", JSON.stringify(user.roles) ?? JSON.stringify([]));
        session.setItem("IsUserCached", JSON.stringify(true));
    }

    setLoggedOut = ({ isExpired }: { isExpired?: boolean }): void => {

        this.user = undefined;
        this._clearCachedUser();
        this.jwtToken = undefined;
        Api.jwtToken = undefined;
        localStorage.removeItem("jwtToken");

        if (isExpired) {
            this.hasJwtExpired = true;
            this._notify();
        } else {
            this.hasLoggedOutByUser = true;
        }
    }

    async updateUserInfo(email: string): Promise<boolean> {
        // console.log("try update UserInfo");

        var res = await Api.graphQLQuery({
            query: gql`
            query users($email: String){
              users(email: $email){
                edges {
                  node {
                    id
                    email
                    firstName
                    familyName
                    roles
                  }
                }
              }
            }
          `,
            variables: { 'email': email },
            fetchPolicy: 'no-cache',
        });

        // console.log(res)
        // console.log(res.loading)

        if (res.errors) {
            console.error(res);
            return false;
        }

        if (res.data == null) {
            return false;
        }

        if (res.data!["users"]["edges"].length < 1) {
            console.error(res);
            return false;
        }

        var _user = new User(res.data!["users"]["edges"][0]["node"]);
        this.user = _user;

        // print(_user);

        this._cacheUser(_user);

        return true;
    }

    async setLoggedIn(
        { email, token }: { email: string, token: string }): Promise<boolean> {
        this.jwtToken = token;
        Api.jwtToken = token;
        // console.log(`token!`)

        await this.updateUserInfo(email);

        console.log("updated user info")

        this.hasJwtExpired = false;
        this.hasLoggedOutByUser = false;

        // print("Bearer $jwtToken");

        localStorage.setItem("jwtToken", token);
        return true
    }

    async tryLogIn({ email, password, shouldNotify = true }: {
        email: string, password: string,
        shouldNotify: boolean
    }): Promise<boolean> {
        const result: Result<Token> | undefined = await Api.tryLogIn({ email, password });
        // console.log(`Result: ${JSON.stringify(result)}`)
        if (result === undefined) {
            this._loginFailed = true;
            return false;
        } else if (result.flag === true) {
            await this.setLoggedIn({ email: email, token: result.result!.token! });
            if (shouldNotify) {
                this._notify();
            }
            return true;
        } else if (result.flag === false) {
            this._loginFailed = true;
            if (shouldNotify) {
                this._notify();
            }
            return false;
        } else {
            return false;
        }
    }

    isLoggedIn = (): boolean => {
        if (Api.sessionExpiredFlag === true) {
            Api.sessionExpiredFlag = false
            this._expired();
            return false
        }

        if (this.jwtToken != null && this.user != null) {
            this.hasJwtExpired = Jwt.isExpired(this.jwtToken!);
            if (this.hasJwtExpired) {
                // console.log("isExpired!!")
                this._expired();
            }
            // print(StackTrace.current);
            // print(user);
            return !this.hasJwtExpired;
        } else {
            this.jwtToken = localStorage.getItem("jwtToken") ?? undefined;
            Api.jwtToken = this.jwtToken;

            var _user = this._getUserFromCached(); // TODO: jwtTokenから読み取るようにする
            this.user = _user;

            if (this.jwtToken != null && this.user != null) {
                this.hasJwtExpired = Jwt.isExpired(this.jwtToken!);
                if (this.hasJwtExpired) {
                    this._expired();
                }
                // print(StackTrace.current);
            }
            return this.jwtToken != null && this.user != null && !this.hasJwtExpired;
        }
    }

    private _getUserFromCached(): User {
        const s = localStorage;
        var user = new User({
            id: s.getItem("id") ?? "",
            email: s.getItem("email") ?? "",
            familyName: s.getItem("familyName") ?? "",
            firstName: s.getItem("firstName") ?? "",
            roles: JSON.parse(s.getItem("roles") ?? '[]')
        });

        return user;
    }
}

export default LoginState;