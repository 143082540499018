import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import IconFa from "../IconFa";
import DeleteButton from "../DeleteButton";
import RentalStatus from "../../models/RentalStatus";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalStatusWidget from "../RentalStatusWidget";
import DateFormatter from "../../DateFormatter";
import Utils from "../../Utils";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import RentalListPage from "./RentalListPage";
import RentalReturnPageEquipmentsView from "../RentalReturnPageEquipmentsView";
import RentalReturnLog from "../../models/RentalReturnLog";
import Equipment from "../../models/Equipment";
import EquipmentCategory from "../../models/EquipmentCategory";
import StateOf from "../../states/StateOf";
import Rental from "../../models/Rental";

type StateOfArrayEquipment = StateOf<Array<Equipment>>

const UpdatedStateContext = createStateContext<UpdateState>();

const RentalStatusStateContext = createStateContext<string>();
const RentalEquipmentListStateContext = createStateContext<StateOfArrayEquipment>();

const ReturnDateStateContext = createStateContext<string>();
const ReturnNoteStateContext = createStateContext<string>();
const UserStateContext = createStateContext<string>();

var id_num: int | undefined;
var id: string | undefined;

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const _UserDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const userContext = useContext(UserStateContext)

    return (
        <SelectValidator
            className="Required"
            value={userContext.state === null ? "" : userContext.state}
            label={"受取人 (TEMDEC側)"}
            name="customer-group"
            onChange={(e: any) => { userContext.setState(e.target.value === "" ? null : e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem value={""}>{""}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem key={e.id} value={e.id}>{e.familyName} {e.firstName}</MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _UserDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        users {
          edges {
            node {
              id
              _id
              firstName
              familyName
              email
            }
          }
        }
      } 
    `
    const result = useQuery(query, {
        variables: {}
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data) {
        const items: Array<any> = result.data!["users"]["edges"]
            .map((e: any) => e["node"]);

        return <_UserDropDownImpl items={items} />
    } else {
        return <_UserDropDownImpl items={[]} />
    }
}

const _FormImpl: React.FC<{ refetch: RefetchType, title: string, equipmentItems: Equipment[], rentalReturnLogs: RentalReturnLog[], rentalStatusVal: RentalStatus | undefined }> = (props) => {
    const refetch = props.refetch;
    const nav = useNavigate()

    const formRef = useRef(null);

    const returnDateContext = useContext(ReturnDateStateContext);
    const userContext = useContext(UserStateContext);
    const returnNoteContext = useContext(ReturnNoteStateContext);

    const updatedContext = useContext(UpdatedStateContext);
    const rentalStatusContext = useContext(RentalStatusStateContext);

    const equipmentItems = props.equipmentItems;
    const rentalReturnLogs = props.rentalReturnLogs;
    const rentalStatusVal = props.rentalStatusVal;
    const title = props.title;

    const rentalEquipmentListStateContext = useContext(RentalEquipmentListStateContext);

    const rentalEquipmentItems = rentalEquipmentListStateContext.state.instance;

    const itemLeftCount: int = equipmentItems.length -
        RentalReturnLog.getReturnedCount(rentalReturnLogs) -
        rentalEquipmentItems.length;

    return (

        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                const returnDate = returnDateContext.state;
                const returnNote = returnNoteContext.state;
                const user = userContext.state;
                const rentalEquipmentItems = rentalEquipmentListStateContext.state.instance;

                const queryStr = `
                  mutation($rental: String!, $note: String, $userWhoReceived: String!, $returnedAt: String!, $equipmentItems: [String]) {
                    createRentalReturnLog(input: {rental: $rental, note: $note, userWhoReceived: $userWhoReceived, returnedAt: $returnedAt, equipmentItems: $equipmentItems}) {
                      rentalReturnLog {
                        id
                        returnedAt
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'rental': id,
                            // 'title': title !== "" ? title : undefined,
                            'returnedAt': returnDate !== "" ? returnDate : undefined,
                            'note': returnNote !== "" ? returnNote : undefined,
                            'userWhoReceived': user !== "" ? user : undefined,
                            'equipmentItems': rentalEquipmentItems
                                .map((e) => e.id),
                            // 'status': rentalStatus
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["createRentalReturnLog"] != undefined &&
                    result?.data?.["createRentalReturnLog"]?.["rentalReturnLog"] !=
                    undefined) {

                    var result2Executed: boolean = false;
                    var result2Succeeded: boolean = true;

                    const result1 = result;
                    var result2: FetchResult<any, Record<string, any>, Record<string, any>> | undefined = undefined;

                    if (itemLeftCount == 0) {
                        result2Executed = true;
                        result2Succeeded = false;
                        try {
                            result2 = await Api.graphQLClient?.mutate({
                                mutation: gql`
                          mutation($id: ID!, $status: String!) {
                            updateRental(input: {id: $id, status: $status}) {
                              rental {
                                id
                                title
                              }
                              clientMutationId
                            }
                          }
                        `,
                                variables: {
                                    'id': id,
                                    'status': RentalStatus.Completed.toString(),
                                },
                            });
                        } catch (e) {
                            Api.handleGraphQLException(e, nav);
                        }

                        result2Succeeded = (result2?.data !== undefined &&
                            result2.data?.["updateRental"] !== undefined &&
                            result2.data?.["updateRental"]?.["rental"] !== undefined);

                        // if (result2Succeeded == false) {
                        //     // handle error
                        // }
                    }

                    // print(opt.variables);
                    // print(result.exception);

                    if (result2Succeeded &&
                        result1.data !== undefined &&
                        result1.data?.["createRentalReturnLog"] !== undefined &&
                        result1.data?.["createRentalReturnLog"]?.["rentalReturnLog"] !== undefined
                    ) {
                        updatedContext.setState(UpdateState.Succeeeded)
                        console.log("Update succeeded");

                        for (var item of rentalEquipmentItems) {
                            RentalStatusExtension
                                .sumFromRentalsCacheClear(item.uuid);
                            Rental.createListFromGraphQLResultCacheClear(item.uuid);
                        }

                        refetch();
                        Api.graphQLResetCache()

                        Router.popAndPushNamed(Router.getRouteOfPage(RentalListPage)!, nav)

                        // isInitialized = false;

                        // successed = true;
                    } else {
                        // if (result2Executed && !result2Succeeded) {
                        //     console.log(result2);
                        // }

                        updatedContext.setState(UpdateState.Failed)
                        console.error("Update failed");
                        console.error(result);
                        return;
                    }

                    // updatedContext.setState(UpdateState.Succeeeded)

                    // await refetch();
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >
            <div>
                {`「${title}」の返却`}
            </div>

            <SizedBox height={10} />

            <RentalReturnPageEquipmentsView
                equipmentItems={equipmentItems}
                rentalEquipmentItemsContext={rentalEquipmentListStateContext}
                rentalReturnLogs={rentalReturnLogs}
            />

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={"返却機材数 (自動計算されます)"}
                name="title"
                value={rentalEquipmentItems.length > 0 ? rentalEquipmentItems.length : ""}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className="RequiredDatePickerContainer">
                        <DatePicker
                            className="Required"
                            label="返却日"
                            value={returnDateContext.state}
                            onChange={(v: any) => {
                                if (v !== null) {
                                    const s = Utils.getFormatDateFromString(v)
                                    returnDateContext.setState(s)
                                }
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </div>
                </Grid>
            </Grid>

            <SizedBox height={10} />

            <TextValidator
                label={"メモ"}
                onChange={(e: any) => returnNoteContext.setState(e.target.value)}
                name="note"
                value={returnNoteContext.state}
            />

            <SizedBox height={10} />

            <_UserDropDown />

            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{"返却"}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const query = gql`
      query($id: ID!){
        rental(id: $id) {
          id
          _id
          title
          startDate
          endDate
          customerPerson {
            id
            customerGroup {
              id
            }
          }
          userInCharge {
            id
          }
          rentalReturnLogs {
            edges {
              node {
                id
                returnedAt
                note
                userWhoReceived {
                  email
                  firstName
                  familyName
                }
                equipmentItems {
                  edges {
                    node {
                      id
                      uuid
                    }
                  }
                }
              }
            }
          }
          status
          equipmentItems {
            edges {
              node {
                id
                name
                uuid
                numbering
                category {
                  id
                  name
                  iconName
                }
              }
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: { "id": id },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.error) {
        console.log(result.error);
    }

    if (result.loading) {
        return <Loading />
    }

    // print(result);

    if (result.data !== undefined && result.data?.["rental"] != undefined) {
        // print(result.data?["user"]);
        // return Center(child: Flexible(child: Text("$result")));
        const data = result.data;
        const item = data["rental"]

        const title = item["title"];
        const startDate = item["startDate"] !== null ? Utils.getFormatDateFromString(item["startDate"]) : "";
        const endDate = item["endDate"] !== null ? Utils.getFormatDateFromString(item["endDate"]) : "";
        const customerGroup = item?.customerPerson?.customerGroup?.id ?? "";
        const customerPerson = item?.customerPerson?.id ?? "";
        const user = item?.userInCharge?.id;

        const rentalStatus = item["status"];
        const rentalStatusVal = rentalStatus !== undefined
            ? RentalStatusExtension.fromString(rentalStatus!) : undefined;

        const rental = item;

        var rentalReturnLogs: RentalReturnLog[] = [];
        var equipmentItems: Equipment[] = [];

        // console.log(rental?.["rentalReturnLogs"]?.["edges"])

        if (rental?.["rentalReturnLogs"]?.["edges"] !== undefined &&
            rental?.["rentalReturnLogs"]?.["edges"].length > 0) {

            rentalReturnLogs = RentalReturnLog.createListFromGraphQLResult(
                rental?.["rentalReturnLogs"]);
        }

        if (rental?.["equipmentItems"]?.["edges"] !== undefined &&
            rental?.["equipmentItems"]?.["edges"].length > 0) {
            const _equipments = rental?.["equipmentItems"]?.["edges"];
            // print(_equipments);

            equipmentItems = [];

            for (const item of _equipments) {
                const id: string = item["node"]["id"];
                const uuid: int = item["node"]["uuid"];
                const name: string = item["node"]["name"];
                const numbering: int = item["node"]["numbering"];

                var category_id: string | undefined;
                var category_name: string | undefined;
                var category_icon_name: string | undefined;

                if (item?.["node"]?.["category"] !== undefined) {
                    const category = item["node"]["category"]
                    category_id = category?.["id"];
                    category_name = category?.["name"];
                    category_icon_name = category?.["iconName"];
                }

                const equipment = new Equipment({
                    id: id,
                    name: name,
                    uuid: uuid,
                    numbering: numbering.toString(),
                    category: category_id !== undefined
                        ? new EquipmentCategory({
                            id: category_id,
                            name: category_name!,
                            iconName: category_icon_name!
                        })
                        : undefined
                });

                equipmentItems.push(equipment);
            }
        }

        return (
            <StateProvider context={ReturnDateStateContext} defaultValue={DateFormatter.format(new Date())}>
                <StateProvider context={ReturnNoteStateContext} defaultValue={""}>
                    <StateProvider context={UserStateContext} defaultValue={user}>
                        <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                            <StateProvider context={RentalStatusStateContext} defaultValue={rentalStatus}>
                                <StateProvider context={RentalEquipmentListStateContext} defaultValue={new StateOf([])}>
                                    <_FormImpl refetch={result.refetch} title={title} equipmentItems={equipmentItems} rentalReturnLogs={rentalReturnLogs} rentalStatusVal={rentalStatusVal} />
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>指定された貸出が見つかりませんでした</Center>
}

const RentalReturnPage: React.FC = (props) => {
    const params = useParams();
    id_num = parseInt(params.id!)
    id = `/rentals/${id_num}`;
    return <_Form />
}

export default RentalReturnPage;