
// ElevatedButton(
//     child: Row(
//       children: [
//         Icon(Icons.call_missed_outgoing),
//         SizedBox(
//           width: 3,
//         ),
//         Text(Const.rentalReturnButtonText),
//       ],
//     ),
//     onPressed: () {
//       var s = Router.getRouteOfPage(RentalReturnPage);
//       Router.pushNamed(
//           s.replaceAll(':id', widget.rentalIdNumber.toString()));
//     },
//     style: ElevatedButton.styleFrom(
//       primary: Colors.orange.shade600,
//     ),
//   ),

import { Button } from "@mui/material";
import React from "react";
import int from "../int";
import * as Icons from "@mui/icons-material";
import SizedBox from "./SizedBox";
import Const from "../Const";
import Router from "../Router";
import RentalReturnPage from "./pages/RentalReturnPage";
import { useNavigate } from "react-router";

const RentalReturnButton: React.FC<{ rentalIdNumber: int, longVersion?: boolean | undefined }> = (props) => {
    const nav = useNavigate()
    return (
        <div onClick={(e) => {
            var s = Router.getRouteOfPage(RentalReturnPage);
            Router.pushNamed(
                s.replace(':id', props.rentalIdNumber.toString()), nav);
            e.preventDefault()
            e.stopPropagation()
        }}>
            <Button variant="contained" color="warning">
                <Icons.CallMissedOutgoing />
                <SizedBox inline width={3} />
                {props.longVersion ? "返却する" : Const.rentalReturnButtonText}
            </Button>
        </div>
    );
}

export default RentalReturnButton;