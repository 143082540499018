import CartController from "./controllers/CartController";
import MenuItems from "./MenuItems";
import SnackBarState from "./states/SnackBarState";
import createStateContext from "./states/createStateContext";
import LoginState from "./states/LoginState";
import StateOf from "./states/StateOf";
import EndDrawerController from "./controllers/EndDrawerController";
import YearRangeState from "./states/YearRangeState";

export const DrawerStateContext = createStateContext<boolean>();
export const EndDrawerStateContext = createStateContext<boolean>();
// export const EndDrawerButtonStateContext = createStateContext<boolean>();
export const EndDrawerControllerStateContext = createStateContext<StateOf<EndDrawerController>>()
export const TitleStateContext = createStateContext<string>();
export const LoginStateContext = createStateContext<StateOf<LoginState>>();
export const MenuItemsContext = createStateContext<StateOf<MenuItems>>();
export const CartContext = createStateContext<StateOf<CartController>>();
export const SnackBarStateContext = createStateContext<StateOf<SnackBarState>>()
export const YearRangeStateContext = createStateContext<StateOf<YearRangeState>>();