import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { CartContext, LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import IconFa from "../IconFa";
import DeleteButton from "../DeleteButton";
import AddToCartButton from "../AddToCartButton";
import Equipment from "../../models/Equipment";
import EquipmentCategory from "../../models/EquipmentCategory";
import Rental from "../../models/Rental";
import RentalStatusExtension from "../../models/RentalStatusExtension";
import RentalStatus from "../../models/RentalStatus";
import EquipmentRentalLogView from "../EquipmentRentalLogView";
import RentalStatusWidget from "../RentalStatusWidget";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import EquipmentAliveStatusExtension from "../../models/EquipmentAliveStatusExtension";
import EquipmentAliveStatusWidget from "../EquipmentAliveStatusWidget";

const UpdatedStateContext = createStateContext<UpdateState>();

const PlaceStateContext = createStateContext<string>();
const CategoryStateContext = createStateContext<string>();

const AliveStatusCt = createStateContext<string>();
const NameStateContext = createStateContext<string>();
const UUIDStateContext = createStateContext<string>();
const NoteStateContext = createStateContext<string>();
const AccessoriesInfoStateContext = createStateContext<string>();
const BihinStickerStateContext = createStateContext<string>();
const NumberingStateContext = createStateContext<string>();

var id_num: int | undefined;
var id: string | undefined;

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;
type UserGroupMap = { "type": string, "name": string, "count": int };

const _CategoryDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const categoryStateContext = useContext(CategoryStateContext)

    return (
        <SelectValidator
            className="Required"
            value={categoryStateContext.state}
            label={Const.equipmentCategoryDropDownMenuLabelText}
            name="category"
            onChange={(e: any) => { categoryStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem key={"_"} value={"_"}>{Const.equipmentCategoryNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <IconFa icon={e.iconName} />
                            <SizedBox inline width={5} />
                            <span>{e.name}</span>
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CategoryDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentCategories {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentCategories"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentCategories"]["edges"]
            .map((e: any) => e.node)

        return <_CategoryDropDownImpl items={items} />
    } else {
        return <_CategoryDropDownImpl items={[]} />
    }
}

const _PlaceDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const placeStateContext = useContext(PlaceStateContext)

    return (
        <SelectValidator
            className="Required"
            value={placeStateContext.state}
            label={Const.equipmentPlaceDropDownMenuLabelText}
            name="place"
            onChange={(e: any) => { placeStateContext.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            <MenuItem key={"_"} value={"_"}>{Const.equipmentPlaceNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <IconFa icon={e.iconName} />
                            <SizedBox inline width={5} />
                            {e.name}
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _PlaceDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentPlaces {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentPlaces"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentPlaces"]["edges"]
            .map((e: any) => e.node)

        return <_PlaceDropDownImpl items={items} />
    } else {
        return <_PlaceDropDownImpl items={[]} />
    }
}

const _AliveStatusDropDown: React.FC<{ items: Array<any> }> = (props) => {

    const aliveStatusCt = useContext(AliveStatusCt)

    return (
        <SelectValidator
            className="Required"
            value={aliveStatusCt.state}
            label={"状態"}
            name="place"
            onChange={(e: any) => { aliveStatusCt.setState(e.target.value) }}
            validators={['required']}
            errorMessages={[ValidatorMessages.required]}
        >
            {/* <MenuItem key={"_"} value={"_"}>{Const.equipmentPlaceNullMenuItemText}</MenuItem> */}
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <EquipmentAliveStatusWidget aliveStatus={EquipmentAliveStatusExtension.fromString(e.id)} />
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _FormImpl: React.FC<{ refetch: RefetchType, item: any }> = (props) => {
    const refetch = props.refetch;
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const nameContext = useContext(NameStateContext);
    const uuidContext = useContext(UUIDStateContext);
    const noteContext = useContext(NoteStateContext);
    const accessoriesInfoContext = useContext(AccessoriesInfoStateContext);
    const bihinStickerContext = useContext(BihinStickerStateContext);
    const numberingContext = useContext(NumberingStateContext);

    const updatedContext = useContext(UpdatedStateContext);
    const placeContext = useContext(PlaceStateContext);
    const categoryContext = useContext(CategoryStateContext);

    const aliveStatusCt = useContext(AliveStatusCt);

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    const item = props.item;

    const rentals: Rental[] =
        Rental.createListFromGraphQLResultCached(
            item.uuid, item.rentals);

    const rentalStatusSum: RentalStatus =
        RentalStatusExtension.sumFromRentalsCached(
            item.uuid, rentals);

    var category_id: string | undefined;
    var category_name: string | undefined;
    var category_icon_name: string | undefined;

    if (item?.category !== undefined) {
        const category = item.category
        category_id = category?.id;
        category_name = category?.name;
        category_icon_name =
            category?.iconName;
    }

    const equipment = new Equipment({
        id: item.id,
        name: item.name,
        uuid: item.uuid,
        numbering: item.numbering,
        category: category_id !== undefined
            ? new EquipmentCategory({
                id: category_id,
                name: category_name!,
                iconName: category_icon_name!
            })
            : undefined
    });

    const isCartContainsMe =
        cartController.includes(equipment);

    const aliveStatusDropDownItems = [
        EquipmentAliveStatus.Alive,
        EquipmentAliveStatus.Notfound,
        EquipmentAliveStatus.Removed,
        EquipmentAliveStatus.Reparing
    ].map((e) => ({
        id: e.toString(),
        name: EquipmentAliveStatusExtension.toStringJp(e),
        color: EquipmentAliveStatusExtension.getColor(e),
    }))

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                const name = nameContext.state;
                const uuid = uuidContext.state;
                const note = noteContext.state;
                const accessoriesInfo = accessoriesInfoContext.state;
                const bihinSticker = bihinStickerContext.state;
                const numbering = numberingContext.state;
                const placeId = placeContext.state !== "_" ? placeContext.state : undefined;
                const categoryId = categoryContext.state !== "_" ? categoryContext.state : undefined;
                const aliveStatus = aliveStatusCt.state;

                const queryStr = `
                  mutation($id: ID!, $uuid: Int, $name: String, $note: String, $accessoriesInfo: String, $bihinSticker: String, $category: String, $place: String, $numbering: Int, $aliveStatus: String) {
                    updateEquipmentItem(input: {id: $id, uuid: $uuid, name: $name, note: $note, accessoriesInfo: $accessoriesInfo, bihinSticker: $bihinSticker, category: $category, place: $place, numbering: $numbering, aliveStatus: $aliveStatus}) {
                      equipmentItem {
                        id
                        name
                      }
                      clientMutationId
                    }
                  }
                `

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                // console.log(categoryId)
                try {
                    result = await Api.graphQLClient?.mutate({
                        mutation: query,
                        variables: {
                            'id': id,
                            'uuid': uuid !== "" ? parseInt(uuid) : undefined,
                            'numbering': numbering !== "" ? parseInt(numbering) : undefined,
                            'name': (name == "")
                                ? undefined
                                : name,
                            'note': (note == "")
                                ? undefined
                                : note,
                            'accessoriesInfo': (accessoriesInfo === "")
                                ? undefined
                                : accessoriesInfo,
                            'bihinSticker': (bihinSticker === "")
                                ? undefined
                                : bihinSticker,
                            'category': categoryId === undefined ? null : categoryId,
                            'place': placeId === undefined ? null : placeId,
                            'aliveStatus': aliveStatus
                        }
                    })
                } catch (e) {
                    Api.handleGraphQLException(e, nav)
                }

                if (result?.data != undefined &&
                    result?.data?.["updateEquipmentItem"] != undefined &&
                    result?.data?.["updateEquipmentItem"]?.["equipmentItem"] !=
                    undefined) {

                    updatedContext.setState(UpdateState.Succeeeded)

                    await refetch();
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(EquipmentManagementPage)!, nav)
                } else {
                    updatedContext.setState(UpdateState.Failed)
                    console.error("Update failed");
                    console.error(result);
                }
            }}
        >


            <div style={{ display: "flex", alignItems: "center" }}>
                <RentalStatusWidget status={rentalStatusSum} />

                {
                    (() => {
                        const enabled = (!isCartContainsMe && rentalStatusSum !== RentalStatus.Ongoing)
                        return (
                            <React.Fragment>
                                <SizedBox width={10} />
                                <AddToCartButton disabled={!enabled} equipmentItem={equipment} />
                                <SizedBox height={10} />
                            </React.Fragment>
                        )
                    })()
                }
            </div>

            <SizedBox height={10} />
            {
                (loginState.user !== undefined && loginState.user.isRentalRoleUser())
                    ? <React.Fragment>
                        <EquipmentRentalLogView equipmentItem={equipment} rentals={rentals} />
                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <TextValidator
                className="Required"
                label={Const.equipmentUuidLabelText}
                onChange={(e: any) => uuidContext.setState(e.target.value)}
                name="uuid"
                value={uuidContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {"#"}
                            {/* <Icons.Tag /> */}
                        </InputAdornment>
                    ),
                }}
                validators={['required', 'isNumber', 'isPositive']}
                errorMessages={[ValidatorMessages.required, ValidatorMessages.positiveNumber, ValidatorMessages.positiveNumber]}
            />

            <SizedBox height={10} />

            <_AliveStatusDropDown items={aliveStatusDropDownItems} />
            <SizedBox height={10} />

            <_CategoryDropDown />
            <SizedBox height={3} />
            <Link to={Router.getRouteOfPage(EquipmentCategoryManagementPage)!}>
                {"カテゴリの管理"}
            </Link>

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={Const.equipmentNameLabelText}
                onChange={(e: any) => nameContext.setState(e.target.value)}
                name="name"
                value={nameContext.state}
                validators={['required']}
                errorMessages={[ValidatorMessages.required]}
            />

            <SizedBox height={10} />

            <TextValidator
                className="Required"
                label={Const.equipmentNumberingLabelText}
                onChange={(e: any) => numberingContext.setState(e.target.value)}
                name="numbering"
                value={numberingContext.state}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {"No."}
                            {/* <Icons.Tag /> */}
                        </InputAdornment>
                    ),
                }}
                validators={['required', 'isNumber', 'isPositive']}
                errorMessages={[ValidatorMessages.required, ValidatorMessages.positiveNumber, ValidatorMessages.positiveNumber]}
            />

            <SizedBox height={10} />

            <_PlaceDropDown />
            <SizedBox height={3} />
            <Link to={Router.getRouteOfPage(EquipmentPlaceManagementPage)!}>
                {"保管場所の管理"}
            </Link>

            <SizedBox height={10} />

            <TextValidator
                label={Const.equipmentNoteLabelText}
                onChange={(e: any) => noteContext.setState(e.target.value)}
                name="note"
                value={noteContext.state}
            />

            <SizedBox height={10} />

            <TextValidator
                label={Const.equipmentAccessoriesInfoLabelText}
                onChange={(e: any) => accessoriesInfoContext.setState(e.target.value)}
                name="accessoriesInfo"
                value={accessoriesInfoContext.state}
            />

            <SizedBox height={10} />

            <TextValidator
                label={Const.equipmentBihinStickerLabelText}
                onChange={(e: any) => bihinStickerContext.setState(e.target.value)}
                name="bihinSticker"
                value={bihinStickerContext.state}
            />

            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
                <SizedBox inline width={5} />
                <DeleteButton deletionCallback={async () => {
                    const queryStr = `
                      mutation($id: ID!) {
                        deleteEquipmentItem(input: {id: $id}) {
                          equipmentItem {
                            id
                          }
                          clientMutationId
                        }
                      }
                    `

                    const query = gql(queryStr)

                    var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

                    try {
                        result = await Api.graphQLClient?.mutate({
                            mutation: query,
                            variables: {
                                'id': id
                            }
                        })
                    } catch (e) {
                        Api.handleGraphQLException(e, nav)
                    }

                    if (result?.data != undefined &&
                        result?.data?.["deleteEquipmentItem"] != undefined &&
                        result?.data?.["deleteEquipmentItem"]?.["equipmentItem"] !=
                        undefined) {

                        updatedContext.setState(UpdateState.Succeeeded)

                        await refetch();
                        Api.graphQLResetCache()

                        Router.popAndPushNamed(Router.getRouteOfPage(EquipmentManagementPage)!, nav)
                    } else {
                        updatedContext.setState(UpdateState.Failed)
                        console.error("Update failed");
                        console.error(result);
                    }
                }} />
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const query = gql`
      query($id: ID!){
        equipmentItem(id: $id) {
          id
          name
          uuid
          note
          accessoriesInfo
          bihinSticker
          numbering
          aliveStatus
          category {
            id
            name
            iconName
          }
          place {
            id
          }
          rentals {
            edges {
              node {
                id
                title
                status
                startDate
                endDate
                customerPerson {
                  id
                  firstName
                  familyName
                  customerGroup {
                    id
                    name
                  }
                }
                userInCharge {
                  id
                  firstName
                  familyName
                  email
                }
                rentalReturnLogs {
                  edges {
                    node {
                      id
                      returnedAt
                      note
                      userWhoReceived {
                        email
                        firstName
                        familyName
                      }
                      equipmentItems {
                        edges {
                          node {
                            id
                            uuid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    const result = useQuery(query, {
        variables: { "id": id },
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav);

    if (result.error) {
        console.log(result.error);
    }

    if (result.loading) {
        return <Loading />
    }

    // print(result);

    if (result.data !== undefined && result.data?.["equipmentItem"] != undefined) {
        // print(result.data?["user"]);
        // return Center(child: Flexible(child: Text("$result")));
        const data = result.data;
        const item = data["equipmentItem"]

        const name = item["name"];
        const uuid = item["uuid"];
        const note = item["note"];
        const accessoriesInfo = item["accessoriesInfo"];
        const bihinSticker = item["bihinSticker"];
        const numbering = item["numbering"];
        const place = item["place"]?.["id"] ?? "_";
        const category = item["category"]?.["id"] ?? "_";
        const aliveStatus = item["aliveStatus"];

        return (
            <StateProvider context={NameStateContext} defaultValue={name}>
                <StateProvider context={UUIDStateContext} defaultValue={uuid}>
                    <StateProvider context={NoteStateContext} defaultValue={note}>
                        <StateProvider context={AccessoriesInfoStateContext} defaultValue={accessoriesInfo}>
                            <StateProvider context={BihinStickerStateContext} defaultValue={bihinSticker}>
                                <StateProvider context={NumberingStateContext} defaultValue={numbering}>
                                    <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                        <StateProvider context={PlaceStateContext} defaultValue={place}>
                                            <StateProvider context={CategoryStateContext} defaultValue={category}>
                                                <StateProvider context={AliveStatusCt} defaultValue={aliveStatus}>
                                                    <_FormImpl refetch={result.refetch} item={item} />
                                                </StateProvider>
                                            </StateProvider>
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>指定された機材が見つかりませんでした</Center>
}

const EquipmentDetailPage: React.FC = (props) => {
    const params = useParams();
    id_num = parseInt(params.id!)
    id = `/equipment_items/${id_num}`;
    return <_Form />
}

export default EquipmentDetailPage;