import React from "react";
import CustomerManagementPage_FilterBuilder from "../filterBuilers/CustomerManagementPage_FilterBuilder";
import EquipmentManagementPage_FilterBuilder from "../filterBuilers/EquipmentManagementPage_FilterBuilder";
import RentalListPage_FilterBuilder from "../filterBuilers/RentalListPage_FilterBuilder";
import PageType from "../PageType";
import Router from "../Router";
import createStateContext from "../states/createStateContext";
import StateProvider from "../states/StateProvider";
import CustomerManagementPage from "../widgets/pages/CustomerManagementPage";
import EquipmentManagementPage from "../widgets/pages/EquipmentManagementPage";
import RentalListPage from "../widgets/pages/RentalListPage";
import FilterController from "./FilterController";
import CustomerManagementFilterController from "./filterControllers/CustomerManagementFilterController";
import EquipmentManagementFilterController from "./filterControllers/EquipmentManagementFilterController";
import RentalListFilterController from "./filterControllers/RentalListFilterController";

class EndDrawerController {
    constructor() {
        const _ = Router.getRouteOfPage;
        this.setBuilder(_(EquipmentManagementPage),
            EquipmentManagementPage_FilterBuilder);
        this.setBuilder(_(CustomerManagementPage),
            CustomerManagementPage_FilterBuilder);
        this.setBuilder(_(RentalListPage),
            RentalListPage_FilterBuilder);

        this.setController(_(EquipmentManagementPage),
            new EquipmentManagementFilterController())
        this.setController(_(CustomerManagementPage),
            new CustomerManagementFilterController())
        this.setController(_(RentalListPage),
            new RentalListFilterController())
    }

    private _builderMap: Map<string, React.FC<any>> = new Map();
    private _controllerMap: Map<string, FilterController> = new Map();

    StateInilializer: React.FC = (props) => {
        var child = props.children;
        for (const key of Array.from(this._controllerMap.keys())) {
            const controller = this._controllerMap.get(key)!

            var e = React.createElement(controller.StateInitializer, { key: key }, [child])
            child = e
        }

        return <React.Fragment>{child}</React.Fragment>
    }

    setController(route: string, controller: FilterController) {
        this._controllerMap.set(route, controller)
    }

    getControllerOf(route: string): FilterController | undefined {
        return this._controllerMap.get(route);
    }

    setBuilder(route: string, builder: React.FC<any>) {
        this._builderMap.set(route, builder);
    }

    getBuilderOf(route: string): React.FC<any> | undefined {
        return this._builderMap.get(route);
    }

    hasBuilderOf(route: string): boolean {
        return this._builderMap.has(route);
    }
}

export default EndDrawerController;