import MenuItem from "./MenuItem";
import PageInfo from "./PageInfo";
import PageType from "./PageType";
import AccountSettingsPage from "./widgets/pages/AccountSettingsPage";
import AdminSettingsPage from "./widgets/pages/AdminSettingsPage";
import CSVImportCategoriesPage from "./widgets/pages/CSVImportCategoriesPage";
import CSVImportCustomerGroupPage from "./widgets/pages/CSVImportCustomerGroupPage";
import CSVImportCustomerPeoplePage from "./widgets/pages/CSVImportCustomerPeoplePage";
import CSVImportEquipmentsPage from "./widgets/pages/CSVImportEquipmentsPage";
import CSVImportPlacesPage from "./widgets/pages/CSVImportPlacesPage";
import CSVImportUsersPage from "./widgets/pages/CSVImportUsersPage";
import CustomerManagementPage from "./widgets/pages/CustomerManagementPage";
import EquipmentAddPage from "./widgets/pages/EquipmentAddPage";
import EquipmentBotchEditPage from "./widgets/pages/EquipmentBatchEditPage";
import EquipmentCategoryManagementPage from "./widgets/pages/EquipmentCategoryManagementPage";
import EquipmentManagementPage from "./widgets/pages/EquipmentManagementPage";
import EquipmentPlaceManagementPage from "./widgets/pages/EquipmentPlaceManagementPage";
import HomePage from "./widgets/pages/HomePage";
import LogoutPage from "./widgets/pages/LogoutPage";
import QRScanPage from "./widgets/pages/QRScanPage";
import RentalAndReservationPage from "./widgets/pages/RentalAndReservationPage";
import RentalListPage from "./widgets/pages/RentalListPage";
import SettingsPage from "./widgets/pages/SettingsPage";
import StaticsPage from "./widgets/pages/StaticsPage";
import UserManagementPage from "./widgets/pages/UserManagementPage";

type PageInfoMap = {
    pageType: PageType,
    info: PageInfo
}

class MenuItems {
    list: Array<MenuItem | undefined>

    // static adminSettingslist: Array<MenuItem> = [
    //     MenuItem._info(UserManagementPage),
    // ].map((e) => MenuItem._infoToMenuItem(e));

    adminSettingslist: Array<MenuItem>

    // static settingslist: Array<MenuItem> = [
    //     MenuItem._info(AccountSettingsPage),
    // ].map((e) => MenuItem._infoToMenuItem(e));
    settingslist: Array<MenuItem | undefined>

    private _info(t: PageType, PageInfoLike?: any): PageInfoMap {
        const pi = PageInfoLike ?? PageInfo
        if (PageInfo.getFromType(t) === undefined) {
            throw `PageInfo of ${t.name} was undefind`
        }
        return { pageType: t, info: PageInfo.getFromType(t)! } as PageInfoMap;
    }

    private _infoToMenuItem(e: PageInfoMap | undefined): MenuItem {
        if (e === undefined || e.info === undefined || e.pageType === undefined) {
            throw `undefined Map was given`
        } else {
            return new MenuItem({
                icon: e.info.icon,
                title: e.info.title,
                routePath: e.info.path,
                pageType: e.pageType,
                isAdminMenu: e.info.isAdminPage,
                isRentalRoleMenu: e.info.isRentalRolePage
            });
        }
    }

    constructor(PageInfoLike?: any) {
        // console.log(PageInfo)

        this.list = [
            HomePage,
            QRScanPage,
            RentalListPage,
            RentalAndReservationPage,
            EquipmentManagementPage,
            CustomerManagementPage,
            EquipmentAddPage,
            undefined,
            SettingsPage,
            AdminSettingsPage,
            StaticsPage,
            undefined,
            LogoutPage,
        ].map((e) => {
            const v = e !== undefined ? this._info(e, PageInfoLike) : undefined;
            return v !== undefined ? this._infoToMenuItem(v) : undefined;
        });

        this.settingslist = [
            this._info(AccountSettingsPage, PageInfoLike),
            undefined,
            this._info(EquipmentCategoryManagementPage, PageInfoLike),
            this._info(EquipmentPlaceManagementPage, PageInfoLike),
            undefined,
            this._info(EquipmentBotchEditPage, PageInfoLike),
            this._info(CSVImportEquipmentsPage, PageInfoLike),
            this._info(CSVImportCategoriesPage, PageInfoLike),
            this._info(CSVImportPlacesPage, PageInfoLike),
            this._info(CSVImportCustomerGroupPage, PageInfoLike),
            this._info(CSVImportCustomerPeoplePage, PageInfoLike),
        ].map((e) => e !== undefined ? this._infoToMenuItem(e) : undefined);

        this.adminSettingslist = [
            this._info(UserManagementPage, PageInfoLike),
            this._info(CSVImportUsersPage, PageInfoLike),
        ].map((e) => this._infoToMenuItem(e));

    }
}

// const MenuItems = new _MenuItems()

export default MenuItems;