import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import React, { useContext, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Api from "../../Api";
import Const from "../../Const";
import { LoginStateContext } from "../../contexts";
import int from "../../int";
import createStateContext from "../../states/createStateContext";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import * as Icons from "@mui/icons-material";
import ValidatorMessages from "../../ValidatorMessages";
import UpdatedLabel from "../UpdatedLabel";
import Router from "../../Router";
import UserManagementPage from "./UserManagementPage";
import EquipmentManagementPage from "./EquipmentManagementPage";
import EquipmentCategoryManagementPage from "./EquipmentCategoryManagementPage";
import EquipmentPlaceManagementPage from "./EquipmentPlaceManagementPage";
import IconFa from "../IconFa";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import IntUtils from "../../IntUtils";
import Utils from "../../Utils";
import BaseModel from "../../models/BaseModel";
import BatchEditPageEquipmentsView from "../BatchEditPageEquipmentsView";
import StateOf from "../../states/StateOf";
import Equipment from "../../models/Equipment";

type CheckResult = {
    loading: boolean,
    data?: {
        result: boolean,
        reason?: string
    }
}

const UpdatedStateContext = createStateContext<UpdateState>();

const EquipmentItemsCt = createStateContext<StateOf<Equipment[]>>();

const LoadingStateContext = createStateContext<boolean>();

const PlaceStateContext = createStateContext<string>();
const CategoryStateContext = createStateContext<string>();
const NameStateContext = createStateContext<string>();
const NoteStateContext = createStateContext<string>();
const AccessoriesInfoStateContext = createStateContext<string>();
const BihinStickerStateContext = createStateContext<string>();

const ChangePlaceStateContext = createStateContext<boolean>();
const ChangeCategoryStateContext = createStateContext<boolean>();
const ChangeNameStateContext = createStateContext<boolean>();
const ChangeNoteStateContext = createStateContext<boolean>();
const ChangeAccessoriesInfoStateContext = createStateContext<boolean>();
const ChangeBihinStickerStateContext = createStateContext<boolean>();

var max_uuid: int = 0;
var max_numbering: int = 0;

type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;
type GraphQLFetchResult = FetchResult<any, Record<string, any>, Record<string, any>> | undefined;

const _CategoryDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const categoryStateContext = useContext(CategoryStateContext)

    return (
        <SelectValidator
            value={categoryStateContext.state}
            label={Const.equipmentCategoryDropDownMenuLabelText}
            name="category"
            onChange={(e: any) => { categoryStateContext.setState(e.target.value) }}
        >
            <MenuItem key={"_"} value={"_"}>{Const.equipmentCategoryNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <IconFa icon={e.iconName} />
                            <SizedBox inline width={5} />
                            <span>{e.name}</span>
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _CategoryDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentCategories {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentCategories"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentCategories"]["edges"]
            .map((e: any) => e.node)

        return <_CategoryDropDownImpl items={items} />
    } else {
        return <_CategoryDropDownImpl items={[]} />
    }
}

const _PlaceDropDownImpl: React.FC<{ items: Array<any> }> = (props) => {

    const placeStateContext = useContext(PlaceStateContext)

    return (
        <SelectValidator
            value={placeStateContext.state}
            label={Const.equipmentPlaceDropDownMenuLabelText}
            name="place"
            onChange={(e: any) => { placeStateContext.setState(e.target.value) }}
        >
            <MenuItem key={"_"} value={"_"}>{Const.equipmentPlaceNullMenuItemText}</MenuItem>
            {
                props.items
                    .map((e) =>
                        <MenuItem className="MenuItemWithIcon" key={e.id} value={e.id}>
                            <IconFa icon={e.iconName} />
                            <SizedBox inline width={5} />
                            {e.name}
                        </MenuItem>
                    )
            }
        </SelectValidator>
    )
}

const _PlaceDropDown: React.FC = (props) => {
    const nav = useNavigate()
    const query = gql`
      {
        equipmentPlaces {
          edges {
            node {
              id
              _id
              name
              iconName
              equipmentItems {
                totalCount
              }
            }
          }
        }
      }
    `
    const result = useQuery(query, {
        variables: {},
        fetchPolicy: "no-cache"
    })

    Api.handleGraphQLException(result.error, nav)

    if (result.loading) {
        return <Loading />
    }

    if (result.data && result.data?.["equipmentPlaces"]?.["edges"] !== undefined) {
        const items: Array<any> = result.data!["equipmentPlaces"]["edges"]
            .map((e: any) => e.node)

        return <_PlaceDropDownImpl items={items} />
    } else {
        return <_PlaceDropDownImpl items={[]} />
    }
}

const UUIDCheck = async (uuid: int | undefined, addCount: int, nav: NavigateFunction): Promise<CheckResult> => {
    if (uuid === undefined) {
        return {
            loading: false,
            data: {
                result: false,
                reason: "機材IDを指定してください"
            }
        }
    }

    const uuid_list: int[] = Utils.range(uuid, uuid + addCount)
    // const uuid_list: int[] = [parseInt(`${uuid}`)]

    // console.log(uuid_list)
    // const uuid_list = [1]

    var result: GraphQLFetchResult;
    try {
        result = await Api.graphQLQuery({
            query: gql`
              query($uuid_list: [Int!]!){
                equipmentItems(uuid_list: $uuid_list) {
                  edges {
                    node {
                      uuid
                    }
                  }
                }
              }
            `,
            variables: { 'uuid_list': uuid_list },
            fetchPolicy: 'no-cache'
        })
    } catch (e) {
        Api.handleGraphQLException(e, nav);
    }

    // console.log(result?.data)

    if (result?.data !== undefined &&
        result.data?.['equipmentItems']?.['edges'] !== undefined) {
        if (result.data?.['equipmentItems']['edges'].length > 0) {
            const uuids: int[] = result.data?.['equipmentItems']['edges']
                .map((e: any) => e['node']['uuid'] as int)
                .sort();
            const res: string = uuids.map((e) => `#${e}`).join(", ");

            // console.log(res)

            return {
                loading: false,
                data: {
                    result: false,
                    reason: `${res} は既に存在します`
                }
            }
        }
    }

    // console.log("OK")

    return {
        loading: false,
        data: {
            result: true,
        }
    }
}

const NumberingCheck = async (name: string, categoryId: string | undefined, numbering: int | undefined, addCount: int, nav: NavigateFunction): Promise<CheckResult> => {
    if (numbering === undefined) {
        return {
            loading: false,
            data: {
                result: false,
                reason: "通し番号を指定してください"
            }
        }
    }

    if (name === "") {
        return {
            loading: false,
            data: {
                result: false,
                reason: "機材名を指定してください"
            }
        }
    }

    const categoryIdNum: int | undefined = categoryId !== undefined ? BaseModel.getIdNumFromString(categoryId) : undefined

    const numbering_list: int[] = Utils.range(numbering, numbering + addCount)
    // const uuid_list: int[] = [parseInt(`${uuid}`)]

    // console.log(uuid_list)
    // const uuid_list = [1]

    var result: GraphQLFetchResult;
    try {
        result = await Api.graphQLQuery({
            query: gql`
              query($name: String!, $category_id: Int, $numbering_list: [Int!]!){
                equipmentItems(name: $name, category_id: $category_id, numbering_list: $numbering_list) {
                  edges {
                    node {
                      numbering
                    }
                  }
                }
              }
            `,
            variables: {
                'numbering_list': numbering_list,
                'name': name,
                'category_id': categoryIdNum,
            },
            fetchPolicy: 'no-cache'
        })
    } catch (e) {
        Api.handleGraphQLException(e, nav);
    }

    // console.log(result?.data)

    if (result?.data !== undefined &&
        result.data?.['equipmentItems']?.['edges'] !== undefined) {
        if (result.data?.['equipmentItems']['edges'].length > 0) {
            const uuids: int[] = result.data?.['equipmentItems']['edges']
                .map((e: any) => e['node']['numbering'] as int)
                .sort();
            const res: string = uuids.map((e) => `No.${e}`).join(", ");

            // console.log(res)

            return {
                loading: false,
                data: {
                    result: false,
                    reason: `${name} ${res} は既に存在します`
                }
            }
        }
    }

    // console.log("OK")

    return {
        loading: false,
        data: {
            result: true,
        }
    }
}

const _FormImpl: React.FC = (props) => {
    const nav = useNavigate()

    const formRef = useRef(null);

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance

    const equipmentItemsCt = useContext(EquipmentItemsCt)

    const nameContext = useContext(NameStateContext);
    const noteContext = useContext(NoteStateContext);
    const accessoriesInfoContext = useContext(AccessoriesInfoStateContext);
    const bihinStickerContext = useContext(BihinStickerStateContext);
    const placeContext = useContext(PlaceStateContext);
    const categoryContext = useContext(CategoryStateContext);

    const changeNameContext = useContext(ChangeNameStateContext);
    const changeNoteContext = useContext(ChangeNoteStateContext);
    const changeAccessoriesInfoContext = useContext(ChangeAccessoriesInfoStateContext);
    const changeBihinStickerContext = useContext(ChangeBihinStickerStateContext);
    const changePlaceContext = useContext(ChangePlaceStateContext);
    const changeCategoryContext = useContext(ChangeCategoryStateContext);

    const updatedContext = useContext(UpdatedStateContext);

    // console.log(parseInt(numberingContext.state) + addCount - 1)
    // console.log(parseInt(numberingContext.state))
    // console.log(addCount)


    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={async () => {
                if (equipmentItemsCt.state.instance.length == 0) {
                    alert("機材品目に機材を追加してください")
                    return
                }

                const name = nameContext.state;
                const note = noteContext.state;
                const accessoriesInfo = accessoriesInfoContext.state;
                const bihinSticker = bihinStickerContext.state;
                const placeId = placeContext.state !== "_" ? placeContext.state : null;
                const categoryId = categoryContext.state !== "_" ? categoryContext.state : null;

                const changeName = changeNameContext.state
                const changeNote = changeNoteContext.state
                const changeAccessoriesInfo = changeAccessoriesInfoContext.state
                const changeBihinSticker = changeBihinStickerContext.state
                const changePlaceId = changePlaceContext.state
                const changeCategoryId = changeCategoryContext.state


                const queryStr = ("" +
                    "mutation(\$id: ID!, " +
                    ([
                        // if (uuidCt.text != "")
                        //   "\$uuid: Int",
                        (changeName === true) ?
                            "\$name: String" : "",
                        (changeNote === true) ?
                            "\$note: String" : "",
                        (changeAccessoriesInfo === true) ?
                            "\$accessoriesInfo: String" : "",
                        (changeBihinSticker === true) ?
                            "\$bihinSticker: String" : "",
                        (changeCategoryId === true) ?
                            "\$category: String" : "",
                        (changePlaceId === true) ?
                            "\$place: String" : "",
                    ]).join(", ") +
                    ") {" +
                    "  updateEquipmentItem(input: {id: \$id, " +
                    ([
                        // if (uuidCt.text != "")
                        //   "uuid: \$uuid",
                        (changeName === true) ?
                            "name: \$name" : "",
                        (changeNote === true) ?
                            "note: \$note" : "",
                        (changeAccessoriesInfo === true) ?
                            "accessoriesInfo: \$accessoriesInfo" : "",
                        (changeBihinSticker === true) ?
                            "bihinSticker: \$bihinSticker" : "",
                        (changeCategoryId === true) ?
                            "category: \$category" : "",
                        (changePlaceId === true) ?
                            "place: \$place" : "",
                    ]).join(", ") +
                    "}) {" +
                    "    equipmentItem {" +
                    "      id" +
                    "      name" +
                    "    }" +
                    "    clientMutationId" +
                    "  }" +
                    "}")

                const query = gql(queryStr)

                var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
                // var vars = {}

                var baseVariables = {
                    // 'id': item.id,
                    // if (uuidCt.text != "")
                    //   'uuid': int.parse(uuidCt.text),

                    'name':
                        (changeName === true) ? name : undefined,
                    'note':
                        (changeNote === true) ? note : undefined,
                    'accessoriesInfo':
                        (changeAccessoriesInfo === true) ?
                            accessoriesInfo : undefined,
                    'bihinSticker':
                        (changeBihinSticker === true) ? bihinSticker : undefined,
                    'category':
                        (changeCategoryId === true) ? categoryId : undefined,
                    'place':
                        (changePlaceId === true) ? placeId : undefined
                }

                var allOK = true;

                for (const item of equipmentItemsCt.state.instance) {

                    try {
                        result = await Api.graphQLClient?.mutate({
                            mutation: query,
                            variables: {
                                ...baseVariables,
                                'id': item.id
                            }
                        })
                    } catch (e) {
                        Api.handleGraphQLException(e, nav)
                    }

                    // console.log(queryStr)
                    // console.log(JSON.stringify(vars))

                    if (result?.data != undefined &&
                        result?.data?.["updateEquipmentItem"] != undefined &&
                        result?.data?.["updateEquipmentItem"]?.["equipmentItem"] !=
                        undefined) {

                        // OK. go next

                    } else {
                        updatedContext.setState(UpdateState.Failed)
                        console.error("Update failed");
                        console.error(result);

                        allOK = false
                        break
                    }
                }

                if (allOK) {
                    updatedContext.setState(UpdateState.Succeeeded)

                    // await refetch();

                    nameContext.setState("");
                    noteContext.setState("");
                    accessoriesInfoContext.setState("");
                    bihinStickerContext.setState("");
                    placeContext.setState("_");
                    categoryContext.setState("_");
                    Api.graphQLResetCache()

                    Router.popAndPushNamed(Router.getRouteOfPage(EquipmentManagementPage)!, nav)
                }
            }}
        >

            <BatchEditPageEquipmentsView
                equipmentItemsContext={equipmentItemsCt}
            />

            <SizedBox height={10} />

            <FormControl>
                <FormControlLabel control={<Checkbox checked={changeCategoryContext.state} onChange={(e) => {
                    changeCategoryContext.setState(e.target.checked)
                }} />} label="カテゴリを変更する" />
            </FormControl>

            {
                (changeCategoryContext.state)
                    ? <React.Fragment>
                        <_CategoryDropDown />
                        <SizedBox height={3} />
                        <Link to={Router.getRouteOfPage(EquipmentCategoryManagementPage)!}>
                            {"カテゴリの管理"}
                        </Link>
                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <FormControl>
                <FormControlLabel control={<Checkbox checked={changeNameContext.state} onChange={(e) => {
                    changeNameContext.setState(e.target.checked)
                }} />} label="機材名を変更する" />
            </FormControl>

            {
                (changeNameContext.state)
                    ? <React.Fragment>
                        <TextValidator
                            label={Const.equipmentNameLabelText}
                            onChange={(e: any) => nameContext.setState(e.target.value)}
                            name="name"
                            value={nameContext.state}
                        />

                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <FormControl>
                <FormControlLabel control={<Checkbox checked={changePlaceContext.state} onChange={(e) => {
                    changePlaceContext.setState(e.target.checked)
                }} />} label="保管場所を変更する" />
            </FormControl>

            {
                (changePlaceContext.state)
                    ? <React.Fragment>
                        <_PlaceDropDown />
                        <SizedBox height={3} />
                        <Link to={Router.getRouteOfPage(EquipmentPlaceManagementPage)!}>
                            {"保管場所の管理"}
                        </Link>

                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <FormControl>
                <FormControlLabel control={<Checkbox checked={changeNoteContext.state} onChange={(e) => {
                    changeNoteContext.setState(e.target.checked)
                }} />} label="メモを変更する" />
            </FormControl>

            {
                (changeNoteContext.state)
                    ? <React.Fragment>
                        <TextValidator
                            label={Const.equipmentNoteLabelText}
                            onChange={(e: any) => noteContext.setState(e.target.value)}
                            name="note"
                            value={noteContext.state}
                        />

                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <FormControl>
                <FormControlLabel control={<Checkbox checked={changeAccessoriesInfoContext.state} onChange={(e) => {
                    changeAccessoriesInfoContext.setState(e.target.checked)
                }} />} label="付属品を変更する" />
            </FormControl>

            {
                (changeAccessoriesInfoContext.state)
                    ? <React.Fragment>
                        <TextValidator
                            label={Const.equipmentAccessoriesInfoLabelText}
                            onChange={(e: any) => accessoriesInfoContext.setState(e.target.value)}
                            name="accessoriesInfo"
                            value={accessoriesInfoContext.state}
                        />

                        <SizedBox height={10} />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            <FormControl>
                <FormControlLabel control={<Checkbox checked={changeBihinStickerContext.state} onChange={(e) => {
                    changeBihinStickerContext.setState(e.target.checked)
                }} />} label="備品シールを変更する" />
            </FormControl>

            {
                (changeBihinStickerContext.state)
                    ? <React.Fragment>
                        <TextValidator
                            label={Const.equipmentBihinStickerLabelText}
                            onChange={(e: any) => bihinStickerContext.setState(e.target.value)}
                            name="bihinSticker"
                            value={bihinStickerContext.state}
                        />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }

            {/* <SizedBox height={10} /> */}

            {/* <SizedBox height={10} />

            <TextValidator
                label={Const.telLabelText}
                onChange={(e: any) => telContext.setState(e.target.value)}
                name="tel"
                value={telContext.state}
            /> */}

            <SizedBox height={10} />
            <Center>
                <Button variant="contained" type="submit">{Const.updateAndSaveButtonText}</Button>
            </Center>
            <Center>
                <UpdatedLabel state={updatedContext.state} />
            </Center>

        </ValidatorForm >
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()

    const loadingContext = useContext(LoadingStateContext);
    const isLoading = loadingContext.state;

    useEffect(() => {
        (async () => {
            const res = await Api.getJson("max_equipment_uuid");
            max_uuid = res?.data?.max_uuid !== undefined ? parseInt(res?.data?.max_uuid) : 0;

            // const res = await Api.getJson("max_equipment_uuid");
            // max_uuid = res?.data?.max_uuid !== undefined ? parseInt(res?.data?.max_uuid) : 0;

            // console.log(res?.data?.max_uuid)
            loadingContext.setState(false)
        })()
    }, [])

    const name = "";
    const uuid = (max_uuid + 1).toString();
    const note = "";
    const accessoriesInfo = "";
    const bihinSticker = "";
    const numbering = "";
    const place = "_";
    const category = "_";

    if (isLoading) {
        return <Loading />
    }

    // console.log(max_uuid)

    return (
        <StateProvider context={EquipmentItemsCt} defaultValue={new StateOf([])}>
            <StateProvider context={NameStateContext} defaultValue={name}>
                <StateProvider context={NoteStateContext} defaultValue={note}>
                    <StateProvider context={AccessoriesInfoStateContext} defaultValue={accessoriesInfo}>
                        <StateProvider context={BihinStickerStateContext} defaultValue={bihinSticker}>
                            <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                                <StateProvider context={PlaceStateContext} defaultValue={place}>
                                    <StateProvider context={CategoryStateContext} defaultValue={category}>
                                        <StateProvider context={ChangeNameStateContext} defaultValue={false}>
                                            <StateProvider context={ChangeNoteStateContext} defaultValue={false}>
                                                <StateProvider context={ChangeAccessoriesInfoStateContext} defaultValue={false}>
                                                    <StateProvider context={ChangeBihinStickerStateContext} defaultValue={false}>
                                                        <StateProvider context={ChangePlaceStateContext} defaultValue={false}>
                                                            <StateProvider context={ChangeCategoryStateContext} defaultValue={false}>
                                                                <_FormImpl />
                                                            </StateProvider>
                                                        </StateProvider>
                                                    </StateProvider>
                                                </StateProvider>
                                            </StateProvider>
                                        </StateProvider>
                                    </StateProvider>
                                </StateProvider>
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        </StateProvider>
    )
}

const EquipmentBotchEditPage: React.FC = (props) => {
    return (
        <StateProvider context={LoadingStateContext} defaultValue={true}>
            <_Form />
        </StateProvider>
    )
}

export default EquipmentBotchEditPage;