import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DrawerStateContext, LoginStateContext, MenuItemsContext, YearRangeStateContext } from "../contexts";
import int from "../int";
import MenuItem from "../MenuItem";
import MenuItems from "../MenuItems";
import Router from "../Router";
import DrawerHeader from "./DrawerHeader";
import ScrollView from "./ScrollView";
import SideBarCartWidget from "./SideBarCartWidget";
import { Select } from "@mui/material";
import StateOf from "../states/StateOf";

import { MenuItem as Option } from "@mui/material"
import StateContextProp from "../states/StateContextProp";
import YearRangeState from "../states/YearRangeState";

const ListItem: React.FC<{ item: MenuItem, key: string }> = (props) => {
    const item = props.item;

    const loginContext = useContext(LoginStateContext)
    const loginState = loginContext.state.instance
    const drawerContext = useContext(DrawerStateContext);
    const navigate = useNavigate();

    return (
        <React.Fragment>
            {
                (() => {
                    if (item.isAdminMenu && (loginState.user === undefined || !loginState.user!.isAdmin())) {
                        return <React.Fragment />
                    } else if (item.isRentalRoleMenu && (loginState.user === undefined || !loginState.user!.isRentalRoleUser())) {
                        return <React.Fragment />
                    } else {
                        return <div onClick={() => {
                            Router.pushNamed(item.routePath, navigate);
                            drawerContext.setState(false);
                        }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    {item.icon !== undefined ? React.createElement(item.icon!) : <React.Fragment />}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </div>
                    }
                })()
            }
        </React.Fragment>

    )
}

const YearSelector: React.FC<{
    yearRangeState: StateContextProp<StateOf<YearRangeState>>,
    isStart: boolean
}> = (props) => {

    const isStart = props.isStart
    const state = props.yearRangeState
    const yearRange = state.state.instance
    const value = isStart ? yearRange.startYear : yearRange.endYear

    return (
        <Select value={value} onChange={(e) => {
            const y = parseInt(e.target.value as string)
            const startYear = isStart ? y : yearRange.startYear
            const endYear = isStart ? yearRange.endYear : y

            if (startYear > endYear) {
                if (isStart) {
                    state.setState(new StateOf(new YearRangeState(startYear, startYear)))
                } else {
                    state.setState(new StateOf(new YearRangeState(endYear, endYear)))
                }
            } else {
                state.setState(new StateOf(new YearRangeState(startYear, endYear)))
            }
        }} >
            {
                // from 2021 to currentYear + 1
            }
            {
                (() => {
                    const currentYear = new Date().getFullYear()
                    const years = []
                    for (let i = 2021; i <= currentYear + 1; i++) {
                        years.push(i)
                    }
                    return years.map((y) => {
                        return <Option key={y} value={y}>{y}</Option>
                    })
                })()
            }
        </Select>
    )
}

const ListView: React.FC<{ items: Array<MenuItem | undefined> }> = (props) => {
    var dividerCount: int = 0;

    const yearRangeState = useContext(YearRangeStateContext);

    return <List>
        {
            <div>
                <YearSelector yearRangeState={yearRangeState} isStart={true} /> 年度 〜
                <YearSelector yearRangeState={yearRangeState} isStart={false} /> 年度
            </div>
        }
        <div style={{ height: 10 }}></div>
        <Divider key="year_range_divider" />

        {
            props.items.map((e) => {
                if (e !== undefined) {
                    return <ListItem item={e} key={e.routePath} />
                } else {
                    dividerCount += 1
                    return <Divider key={`divider${dividerCount}`} />
                }
            })
        }
    </List >
}

const DrawerHeaderContainer: React.FC = (props) => {
    return <div className="DrawerHeaderContainer">{props.children}</div>
}

const DrawerCartContainer: React.FC = (props) => {
    return <div className="DrawerCartContainer">{props.children}</div>
}

const DrawerContentContainer: React.FC = (props) => {
    return <div className="DrawerContentContainer">{props.children}</div>
}

const SideBar: React.FC = () => {
    const menuItemsContext = useContext(MenuItemsContext);
    const menuItems = menuItemsContext.state.instance
    return (
        <div className="SideBar">
            <DrawerHeaderContainer>
                <DrawerHeader />
            </DrawerHeaderContainer>
            <div className="DrawerHeaderSpacer"></div>
            <ScrollView>
                <DrawerContentContainer>
                    <ListView items={menuItems.list} />
                </DrawerContentContainer>
            </ScrollView>

            <DrawerCartContainer>
                <SideBarCartWidget />
            </DrawerCartContainer>
        </div>
    )
}

export default SideBar;