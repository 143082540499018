import { Button, List, ListItem, ListItemButton } from "@mui/material";
import * as Icons from "@mui/icons-material";
import React from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../contexts";
import Equipment from "../models/Equipment";
import Router from "../Router";
import EquipmentDetailPage from "./pages/EquipmentDetailPage";
import SizedBox from "./SizedBox";
import StateOf from "../states/StateOf";

const _CartItemsList: React.FC<{ cartItems: Equipment[] }> = (props) => {
    // const nav = useNavigate()
    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance

    return (
        <List className="CartItemsList">
            {
                props.cartItems.map((e) =>
                    <ListItem key={e.id}>
                        <div onClick={() => {
                            cartController.removeFromCart(e)
                            cartContext.setState(new StateOf(cartController))
                        }}>
                            <Button>
                                <Icons.Clear />
                            </Button>
                        </div>
                        <Link to={
                            Router.getRouteOfPage(EquipmentDetailPage)
                                .replace(":id", Equipment.getIdNumFromString(e.id!)!.toString())
                        }
                        >
                            {e.getReadableName()}
                        </Link >
                    </ListItem>
                )
            }
            {
                (props.cartItems.length === 0)
                    ? <ListItem key={"_"}>
                        なし
                    </ListItem>
                    : <React.Fragment></React.Fragment>
            }
        </List >
    )
}

const SideBarCartWidget: React.FC = (props) => {

    const cartContext = useContext(CartContext);
    const cartController = cartContext.state.instance
    const cartItems = cartController.getEquipmentItems()

    return (
        <div className="SideBarCartWidget">
            <div className="SideBarCartWidgetHeader">
                <Icons.ShoppingCart />
                <SizedBox inline width={3} />
                <span>{`カート (${cartItems.length})`}</span>
            </div>
            <_CartItemsList cartItems={cartItems} />
        </div >
    )
}

export default SideBarCartWidget;