import jwt_decode from 'jwt-decode';

class Jwt {
    static isExpired = (token: string): boolean => {
        var decodedToken: any = jwt_decode(token);
        var t = (new Date()).getTime();
        var st = t / 1000;
        // console.log(`jwt.exp = ${decodedToken.exp}, current = ${st}`);
        // console.log();
        return (decodedToken.exp < st)
    }
}

export default Jwt;