import { ApolloQueryResult, FetchResult, gql, useQuery } from "@apollo/client";
import { Button, TextareaAutosize } from "@mui/material";
import { EEXIST } from "constants";
import Papa from "papaparse";
import { listenerCount } from "process";
import React, { useCallback, useContext, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import Api from "../../Api";
import EquipmentAliveStatus from "../../EquipmentAliveStatus";
import int from "../../int";
import IntUtils from "../../IntUtils";
import BaseModel from "../../models/BaseModel";
import Router from "../../Router";
import createStateContext from "../../states/createStateContext";
import StateOf from "../../states/StateOf";
import StateProvider from "../../states/StateProvider";
import UpdateState from "../../states/UpdateState";
import Utils from "../../Utils";
import Center from "../Center";
import Loading from "../Loading";
import SizedBox from "../SizedBox";
import EquipmentManagementPage from "./EquipmentManagementPage";
import UserManagementPage from "./UserManagementPage";

type GraphQLFetchResult = FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
type CheckResult = {
    loading: boolean,
    data?: {
        result: boolean,
        reason?: string
    }
}

type UserProto = {
    email: string,
    firstName: string,
    familyName: string,
    password: string,
    tel: string,
    userType: string
}
type RefetchType = (variables?: any) => Promise<ApolloQueryResult<any>>;

const CSVCt = createStateContext<UserProto[] | null>()
const CSVErrorCt = createStateContext<StateOf<Papa.ParseError[] | string | null>>()
// const PlacesCt = createStateContext<Map<string, string> | null>()
// const CategoriesCt = createStateContext<Map<string, string> | null>()

const UUIDCheckCt = createStateContext<StateOf<Map<int, CheckResult>>>()
const NumberingCheckCt = createStateContext<StateOf<Map<int, CheckResult>>>()

const UpdatedStateContext = createStateContext<UpdateState>()

const parsedToEquipmentProtos = (parsed: string[][]): UserProto[] => {
    var list: UserProto[] = []
    for (const e of parsed) {
        const p = {
            familyName: e[0],
            firstName: e[1],
            email: e[2],
            password: e[3],
            tel: e[4],
            userType: e[5],
        } as UserProto
        list.push(p)
    }

    return list.filter((e) => {
        return ['ADMIN', 'USER', 'GUEST'].includes(e.userType)
    });

}

const _FormImpl: React.FC<{}> = (props) => {
    // const loginContext = useContext(LoginStateContext);
    // const loginState = loginContext.state.instance;
    // const user = loginState.user;

    // const placesCt = useContext(PlacesCt)
    // const categoriesCt = useContext(CategoriesCt)
    const headerList = "姓,名,ID,パスワード,電話番号,ユーザ種別"
    const csvCt = useContext(CSVCt)
    const csvErrorCt = useContext(CSVErrorCt)
    const csvData: UserProto[] | null = csvCt.state

    const uuidCheckCt = useContext(UUIDCheckCt);
    const numberingCheckCt = useContext(NumberingCheckCt);
    const uuidAlllOK = Array.from(uuidCheckCt.state.instance.keys()).filter((k) => {
        const e = uuidCheckCt.state.instance.get(k)!
        return e.data?.result !== true
    }).length === 0
    const numberingAllOK = Array.from(numberingCheckCt.state.instance.keys()).filter((k) => {
        const e = numberingCheckCt.state.instance.get(k)!
        return e.data?.result !== true
    }).length === 0

    const updatedContext = useContext(UpdatedStateContext)
    const nav = useNavigate()

    var temporaryCSV: string;

    return (
        <React.Fragment>
            <TextareaAutosize
                minRows={3}
                placeholder="ここにCSVファイルの中身を貼り付けてください"
                onChange={(e) => {
                    temporaryCSV = e.target.value
                }}
                style={{ width: 300, height: 200 }}
            />
            <div>
                ※ CSVの形式は、{headerList} です。
            </div>
            <div>
                ※ ユーザ種別は、ADMIN, USER, GUEST のいずれかです。
            </div>
            <SizedBox height={5} />
            <div onClick={() => {
                const parsed = Papa.parse<string[]>(temporaryCSV)
                if (parsed.errors.length > 0) {
                    csvCt.setState(null)
                    csvErrorCt.setState(new StateOf(parsed.errors))
                }

                const protos = parsedToEquipmentProtos(parsed.data)

                csvErrorCt.setState(new StateOf(null))
                csvCt.setState(protos)

                uuidCheckCt.setState(new StateOf(new Map()))
                numberingCheckCt.setState(new StateOf(new Map()))

                // console.log(parsed)
            }}>
                <Button variant="contained">
                    確認
                </Button>
            </div>

            {
                (csvErrorCt.state.instance !== null)
                    ? (
                        <React.Fragment>
                            <div>
                                CSVにエラーがあります
                            </div>

                            <div>
                                {JSON.stringify(csvErrorCt.state.instance)}
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length > 0)
                    ? (
                        <React.Fragment>
                            <div>
                                <table>
                                    <tr>
                                        {
                                            headerList.split(",").map((e) =>
                                                <th>{e}</th>
                                            )
                                        }
                                        {/* <th>(確認)</th> */}
                                    </tr>
                                    {
                                        // categoryName: string,
                                        // placeName: string,
                                        // numbering: int | undefined,
                                        // note: string,
                                        // accessoriesInfo: string,
                                        // bihinSticker: string
                                        csvData.map((e) =>
                                            <tr>
                                                <td>{e.familyName}</td>
                                                <td>{e.firstName}</td>
                                                <td>{e.email}</td>
                                                <td>{e.password}</td>
                                                <td>{e.tel}</td>
                                                <td>{e.userType}</td>
                                                {/* <td> */}
                                                {/* <UUIDCheckResultWidget uuid={e.uuid} /> */}
                                                {/* <NumberingCheckResultWidget uuid={e.uuid} name={e.name} category={e.categoryId} numbering={e.numbering} /> */}
                                                {/* </td> */}
                                            </tr>
                                        )
                                    }
                                </table>
                            </div>
                        </React.Fragment>
                    )
                    : <React.Fragment />
            }
            {
                (csvData !== null && csvData.length === 0)
                    ? <div>有効な行はありませんでした。ユーザー種別が正しいかを確認してください。</div>
                    : <React.Fragment />
            }
            <SizedBox height={5} />
            {
                (csvData !== null && csvData.length > 0)
                    ?
                    <div onClick={async () => {
                        // if (uuidCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        // if (numberingCheckCt.state?.data?.result !== true) {
                        //     return
                        // }

                        const queryStr = `
                          mutation($familyName: String!, $firstName: String!, $email: String!, $tel: String, $roles: Iterable!, $password: String!) {
                            createUser(input: {familyName: $familyName, firstName: $firstName, email: $email, tel: $tel, roles: $roles, password: $password}) {
                              user {
                                id
                              }
                              clientMutationId
                            }
                          }
                        `

                        const query = gql(queryStr)

                        var result: FetchResult<any, Record<string, any>, Record<string, any>> | undefined;
                        var vars = {}

                        var allOK = true;

                        var n = 0;

                        for (const e of csvCt.state!) {
                            ++n;

                            const res = await Api.postJson("encode_password", {
                                "password": e.password
                            })

                            const encode_password = res?.data !== undefined ? res?.data?.encoded_password : undefined

                            if (encode_password === undefined) {
                                console.error(res);
                                alert(`${n}行目でエラーが発生しました (通信エラー)`);
                                return
                            }

                            try {
                                result = await Api.graphQLClient?.mutate({
                                    mutation: query,
                                    variables: vars = {
                                        'familyName': e.familyName,
                                        'firstName': e.firstName,
                                        'email': e.email,
                                        'tel': e.tel === ""
                                            ? undefined
                                            : e.tel,
                                        'roles': e.userType === "ADMIN"
                                            ? ["ROLE_ADMIN", "ROLE_USER", "ROLE_RENTAL"]
                                            : e.userType === "USER"
                                                ? ["ROLE_USER", "ROLE_RENTAL"]
                                                : ["ROLE_USER"],
                                        'password': encode_password
                                        // 'uuid': e.uuid,
                                        // 'numbering': e.numbering,
                                        // 'name': e.name,
                                        // 'note': (e.note === "")
                                        //     ? undefined
                                        //     : e.note,
                                        // 'accessoriesInfo': (e.accessoriesInfo === "")
                                        //     ? undefined
                                        //     : e.accessoriesInfo,
                                        // 'bihinSticker': (e.bihinSticker === "")
                                        //     ? undefined
                                        //     : e.bihinSticker,
                                        // 'category': (e.categoryId === "")
                                        //     ? undefined
                                        //     : e.categoryId,
                                        // 'place': (e.placeId === "")
                                        //     ? undefined
                                        //     : e.placeId,
                                        // 'aliveStatus': EquipmentAliveStatus.Alive.toString()
                                    }
                                })
                            } catch (e) {
                                Api.handleGraphQLException(e, nav)
                            }

                            console.log(queryStr)
                            console.log(JSON.stringify(vars))

                            if (result?.data != undefined &&
                                result?.data?.["createUser"] != undefined &&
                                result?.data?.["createUser"]?.["user"] !=
                                undefined) {

                                // OK. go next

                            } else {
                                updatedContext.setState(UpdateState.Failed)
                                console.error(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                alert(`Update failed: ${n}個目のデータ (${JSON.stringify(e)}) でエラー。中止します。`);
                                console.error(result);

                                allOK = false
                                break
                            }
                        }

                        if (allOK) {
                            updatedContext.setState(UpdateState.Succeeeded)

                            csvCt.setState(null);
                            csvErrorCt.setState(new StateOf(null));
                            Api.graphQLResetCache()

                            Router.popAndPushNamed(Router.getRouteOfPage(UserManagementPage)!, nav)
                        }
                    }}>
                        <Button variant="contained">
                            インポートを実行
                        </Button>
                    </div>
                    : <Button disabled variant="contained">
                        インポートを実行
                    </Button>
            }
        </React.Fragment>
    );
}

const _FormWrap: React.FC = (props) => {
    return (
        <React.Fragment>
            <_FormImpl />
        </React.Fragment>
    )
}

const _Form: React.FC = (props) => {
    const nav = useNavigate()


    if (true) {
        return (
            <StateProvider context={CSVCt} defaultValue={null}>
                <StateProvider context={CSVErrorCt} defaultValue={new StateOf(null)}>
                    <StateProvider context={UpdatedStateContext} defaultValue={UpdateState.Unoperated}>
                        <StateProvider context={UUIDCheckCt} defaultValue={new StateOf(new Map())}>
                            <StateProvider context={NumberingCheckCt} defaultValue={new StateOf(new Map())}>
                                <_FormWrap />
                            </StateProvider>
                        </StateProvider>
                    </StateProvider>
                </StateProvider>
            </StateProvider>
        )
    }

    return <Center>エラーが発生しました</Center>
}

const CSVImportUsersPage: React.FC = (props) => {
    return (
        <_Form />
    )
}

export default CSVImportUsersPage;