import DateFormatter from "../DateFormatter";
import DateFormatterReadable from "../DateFormatterReadable";
import DateTime from "../DateTime";
import int from "../int";
import Utils from "../Utils";
import BaseModel from "./BaseModel";
import Equipment from "./Equipment";
import User from "./User";

class RentalReturnLog extends BaseModel {
    constructor(props: {
        id: string,
        userWhoReceived: User,
        returnedAt: string,
        equipmentItems: Equipment[],
        returnNote: string | null
    }) {
        super({ id: props.id })
        this.userWhoReceived = props.userWhoReceived
        this.returnedAt = props.returnedAt
        this.equipmentItems = props.equipmentItems
        this.returnNote = props.returnNote
    }

    userWhoReceived: User
    returnedAt: string
    returnNote: string | null
    equipmentItems: Equipment[]

    static getReturnedCount(rentalReturnLogs: RentalReturnLog[]): int {
        return rentalReturnLogs.reduce(
            (prev, v) => prev + v.equipmentItems.length, 0);
    }

    static createListFromGraphQLResult(item: any): RentalReturnLog[] {
        var rentalReturnLogs: RentalReturnLog[] = [];

        if (item["edges"] !== undefined && item["edges"].length > 0) {
            var _rentalReturnLogs = item["edges"];
            for (const _rental of _rentalReturnLogs) {
                var _r = _rental["node"];
                // print(_r["equipmentItems"]);
                rentalReturnLogs.push(new RentalReturnLog({
                    id: _r["id"],
                    returnedAt: _r["returnedAt"],
                    returnNote: _r["note"],
                    userWhoReceived: new User(_r["userWhoReceived"]),
                    equipmentItems:
                        Utils.getEquipmentItemsFromRentalReturnLog(_r["equipmentItems"]),
                }));
            }
        }
        return rentalReturnLogs;
    }

    equals(e: RentalReturnLog): boolean {
        return this.id == e.id;
    }

    getReturnedDate(): DateTime {
        return new Date(this.returnedAt);
    }

    getReturnedDateString(): string {
        const date = this.getReturnedDate();
        return DateFormatter.format(date);
    }

    getReturnedDateReadable(): string {
        const date = this.getReturnedDate();
        return DateFormatterReadable.format(date);
    }

    getUserName(): string {
        return this.userWhoReceived.getName();
    }
}

export default RentalReturnLog;