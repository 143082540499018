import React from "react";
import IconFaList from "../../IconFaList";
import IconFa from "../IconFa";

const FaIconsListPage: React.FC = () => {
    return (
        <React.Fragment>
            {
                IconFaList.map((e) => {
                    return <div>
                        <IconFa icon={e} /> {e}
                    </div>
                })
            }
        </React.Fragment>
    )
}

export default FaIconsListPage;