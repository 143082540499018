import dayjs from "dayjs";
import DateTime from "./DateTime";

class DateFormatter {
    static format(date: DateTime): string {
        return dayjs(date)
            .locale('ja')
            .format('YYYY-MM-DD');
    }
}

export default DateFormatter;